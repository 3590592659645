import React from "react";
import { LandingPageGuinnessLeasideLock } from "@focusdigisuite/components";
import { Box, Hidden } from "@material-ui/core";
import Theme from "../theme/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { tagVisible, tagHidden } from "../store/actions";
//import { store } from "../App"
//import * as actionCreators from "../store/actions/index";
//import { homePageTagVisible } from "../App"

//store.subscribe (() => {
  //  console.log('state updated')
  //  console.log(store.getState());
  //})
  
function Homepage(props) {
  
  //console.log(props.homePageTagVisible[0])
  //console.log(props.homePageTagVisible[0].payload)
  //console.log(props.store)
  //const makeTagShow = useSelector(state => state.homepageTag); //not doing anything?
  const makeTagShow = useSelector(state => props.homePageTagVisible);
  //const makeTagShow = useSelector(state => this.props.state.homepageTag);
  //const makeTagShow = useSelector(state => this.props.homepageTag);
  //const makeTagShow = useSelector(state => this.props.makeTagShow);
  //const { makeTagShow } = this.props
  //const makeTagShow = true
  const dispatch = useDispatch();
  //dispatch(tagVisible);
  ////console.log(makeTagShow)
 
 
 
  //dispatch({ type: "TAG_HIDDEN" })
 
 

  //dispatch(tagHidden);
  ////console.log(makeTagShow)
  return (
    <>
      <LandingPageGuinnessLeasideLock
        theme={Theme}
        title={props.config?.title || ""}
        subTitle={props.config?.subtitle || ""}
        //tagShow={props.tagShow}
  
        backgroundImage={"https://firebasestorage.googleapis.com/v0/b/focus-digisuite.appspot.com/o/" + ("digisuites/digisuite-guinness-leasidelock/staticPages/homepage/backgroundImage/backgroundHomepage.png" || "").toString().replace(/\&/g, '%26').replace(/\//g, '%2F') + "?alt=media"}
      />
      {/* <div style={ makeTagShow === true ? {display: "block"}: {display: "none"} }>
        <img id="imageTag" src={require('../assets/img/Tag.PNG')} style={{width: '80px', position: 'fixed', bottom: 0, left:'50%', marginLeft: "-40px", zIndex: 100 }}></img>
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    makeTagShow: state.homepageTag
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //tagVisible: () => dispatch(actionCreators.tagVisible()),
    //tagHidden: () => dispatch(actionCreators.tagHidden()),
    //homePageTagVisible: () => dispatch(homePageTagVisible())
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
export default connect(mapStateToProps)(Homepage);