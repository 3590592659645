import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import { Check as CheckIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import StorageImg from "components/StorageImg";
import { values } from "lodash";
import _ from "lodash";
import CompareProperty from "../components/Property/CompareProperty/CompareProperty";
import Aux from "../hoc/Auxiliary/Auxiliary";
import { Row } from "reactstrap";
import { PlotFilters } from "@focusdigisuite/components";
import { propNames } from "ui-box";



function Favourites(props) {
  
  const [properties, setProperties] = useState([]);
  const [heartSelected, setHeartSelected] = useState([]);
  const [selected, setSelected] = useState([]);
  
  //let properties = [],
  let propertyCompare = props.propertyCompare || []
  let showCompare = false
  let maxCompareSelected = false

  useEffect(() => {
    //console.log("useeffected");
    fetch('https://us-central1-focus-digisuite.cloudfunctions.net/api/digisuites/digisuite-guinness-leasidelock/dev/properties')
    .then(res => res.json())
    .then(propertiesCollected => {
        setProperties(propertiesCollected)
        setHeartSelected(JSON.parse(localStorage.getItem('sessionHearts')))
    })
    
  },[]);

  const styles = {
      //title: "title"
  }

  const getData = () => {
    // get data from localstorage
    const data = localStorage.getItem('sessionHearts')
    JSON.parse(data)
    return data
  }

  const logger = (conlog) => {
    // console.log from within JSX return
    console.log(conlog);
  }

  const handleHeartClick = (id) => {
    // Select / Deselet favourites with heart icons
    const data = localStorage.getItem('sessionHearts')
    JSON.parse(data)
    if (data.indexOf(id) == -1) {
      setHeartSelected([...heartSelected, id])
      localStorage.setItem('sessionHearts', JSON.stringify([...heartSelected, id]))
    } else {
      setHeartSelected(heartSelected.filter(v => v != id))
      localStorage.setItem('sessionHearts', JSON.stringify(heartSelected.filter(v => v != id)))
    }
  }
  
  const onSelect = (id) => {
    if (selected.indexOf(id) == -1) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter(v => v != id))
    }
  }
        
  const toggleCompareProperties = () => {
    //console.log(this.state.showCompare);
    const prevCompare = !showCompare;
    showCompare = prevCompare;
  };
  
  const compareCheckedHandler = (event, index, propId) => {
    if (event.target.checked === true) {
      handleCompareChecked(props, index, propId);
    }
    
    if (event.target.checked === false) {
      handleCompareUnchecked(props, index, propId);
    }
  };

  const handleCompareChecked = (props, propIndex, propId) => {
    // if (this.scrollRef.current !== null) {
      //   const scr = this.scrollRef.current;
      //   scr.scrollLeft = this.state.scrollPosition;
      // }

      let compareArray = [...props.propertyCompare];
      let allPropertis = {...props.properties};
      
      if (!props.maxCompareSelected) {
      
      compareArray.push(allPropertis[propId]);

      allPropertis[propId] = {
        ...allPropertis[propId],
        selected: true,
      };
      
      propertyCompare = compareArray;
      properties = allPropertis;

      if (compareArray.length >= 2) {
        maxCompareSelected = true;
      }
    } else {
      // properties = allPropertis,     need to fix this and uncomment
      propertyCompare = compareArray;
    }
  };

  const handleCompareUnchecked = (props, propIndex, propId) => {
    // if (this.scrollRef.current !== null) {
    //   const scr = this.scrollRef.current;
    //   scr.scrollLeft = this.state.scrollPosition;
    // }


    let compareArray = [...props.propertyCompare];
    let allPropertis = {...props.properties};

    _.remove(compareArray, function (e) {
      return e.id === propId;
    });

    allPropertis[propId] = {
      ...allPropertis[propId],
      selected: false,
    };

    propertyCompare = compareArray;
    properties = allPropertis;
    maxCompareSelected = false;
  };

  // componentDidMount() {
  //   fetch('https://us-central1-focus-digisuite.cloudfunctions.net/api/digisuites/digisuite-guinness-leasidelock/dev/properties')
  //     .then(res => res.json())
  //     .then(properties => {
  //       properties
  //     })
  // }

  

    // let checkBoxDiv = (_index) => (
    //   <div style={{ marginLeft: "15px", display: "inline-block", }}>
    //     <span className="bigcheck-register cancel-drag">
    //       <label
    //         className="bigcheck-register"
    //         style={{ fontSize: "16px", color: "rgb(72, 29, 63)", fontFamily: "HelveticaNeue, Arial, sans-serif", }}
    //       >
    //         {" "}
    //         Compare home
    //         <input
    //           type="checkbox"
    //           //checked={this.state.selected}
    //           onChange={(event) => this.compareCheckedHandler(event, _index)}
    //           className="bigcheck-register"
    //           id={`prop-check-${this.props.id}`}
    //           name="Agreed"
    //           value="Agreed"
    //           style={{ display: "none"}}
    //           //disabled={this.state.maxCompareSelected}
    //         />
    //         <span className="bigcheck-target-register"></span>
    //       </label>
    //     </span>
    //   </div>
    // );

    if (showCompare) {
      //this.listingSection = null;
      return (
        <>
          <Aux>
            <div style={{ background: "#FEF9F2"}}>
              <div style={{
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
                <Grid item xs={12} style={{padding: '20px', backrgroundColor: '#FEF9F2', display: 'flex', flexDirection: 'row'}}>
                  <Link onClick={toggleCompareProperties} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'rgb(57, 31, 57)'}}>
                    <div style={{
                      clipPath: "polygon(0 50%, 100% 0, 100% 100%)",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "black",
                      marginRight: '10px',
                      marginLeft: '30px'
                      
                    }} />
                    
                    <Typography variant="body1" style={{fontWeight: 'bold', fontSize: '25px'}}> Back</Typography>
                  </Link>
                </Grid>
              </div>
              <span
                className="bold-GalanoGrotesque"
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  zIndex: "1",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "30px",
                  marginLeft: '472px'
                }}
              >
              
              </span>
            </div>
            <Row style={{ margin: "0", background: '#FEF9F2' }}>
              <div className="compare-home-wrapper" >
                <div className="compare-home-inner" style={{overflow: "auto"}}>
                  <div className="compare-home-inner-left">
                    <CompareProperty propData={props.propertyCompare[0]} />
                  </div>
                </div>
                <div className="compare-home-inner" style={{overflow: "auto"}}>
                  <div className="compare-home-inner-right">
                    <CompareProperty propData={propNames.propertyCompare[1]} />
                  </div>
                </div>
              </div>
            </Row>
          </Aux>
        </>
      );
    } else {
      //console.log("return")
      //co/nsole.log(heartSelected)
      return (
        <>
          <Box style={{marginTop: "40px", padding: '30px', backgroundColor: props.backgroundColor, minHeight: '100%'}}>
            <Container style={{width: '100%', minHeight: '100px', color: "#FEF9F2"}} maxWidth="xl">
              <Grid container spacing={4} style={{paddingBottom: '0px'}}>
                {Object.entries(properties || {}).filter(([k, v]) => v.flags.visible).filter(([k, v]) => heartSelected.indexOf(v.id) !== -1).sort(([ka], [kb]) => {
                  if (parseInt(ka.match(/\d+/)[0]) <= parseInt(kb.match(/\d+/)[0]))
                    return -1;
                  if (parseInt(ka.match(/\d+/)[0]) > parseInt(kb.match(/\d+/)[0]))
                    return 1;
                  return 0;
                }).map(([k, v], i) => (
                //   logger(v),
                  <Grid item xl={4} md={4} sm={6} xs={12} key={i}>
                    <Grid container >
                    <Grid item style={{width: '100%', position: 'relative', paddingBottom: '60%'}}>
                        <StorageImg path={v.mainImage} src={require('../assets/img/Room.PNG')} style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', top: 0, left: 0}} />
                        <Grid item style={{position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'row', margin: "15px", padding: '1px 6px', backgroundColor: "white", color: '#388189', borderRadius: "3px"}}>
                          <Typography variant="h6" style={{textTransform: 'capitalize'}}>{v.status}</Typography>
                        </Grid>
                        <Grid item style={{position: 'absolute', top: '0%', left: '84%', display: 'flex', flexDirection: 'row', margin: "15px", height: "14.5%", cursor: 'pointer'}}>                          
                          <img src={(heartSelected.indexOf(v.id) === -1) ? require(`../assets/img/icons/heartDeselected.svg`) : require(`../assets/img/icons/heartSelected.svg`)} onClick={() => handleHeartClick(v.id)}></img>
                        </Grid>
                      </Grid>
                      <Grid item style={{postion: "relative", width: "100%", display: 'flex', flexDirection: 'row', border: "3px solid #A65E45", borderRadius: "2px", alignItems: "center", justifyContent: "center"}}>
                        <Box style={{alignItems: "center", justify: "center"}}>
                          <Typography variant="body1" style={{color: "#A65E44", paddingTop: "2%", paddingBottom: "2%"}}>
                            {v.flags.sharedOwnership === true ? "Shared Ownership" : "Private Sale"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item style={{display: 'flex', flexDirection: 'column', padding: '10px 0px 5px'}}>
                        <Typography variant="h5" style={{color: "#1D1D1B"}}>{v.name}</Typography>
                        <Typography variant="body1" style={{color: "#1D1D1B"}}>
                          From £{(v.price.toString().length > 3) ? (v.price.toString().slice(0, -3)) + (",") + (v.price.toString().slice(-3, v.price.toString().length)) : v.price}
                        </Typography>
                      </Grid>
                      <Grid item style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', padding: '10px 0px 10px', textAlign: 'center'}}>
                        <div>
                          <Typography variant="body1" style={{color: "#1D1D1B"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.221" height="13.39" viewBox="0 0 17.221 13.39" style={{marginRight: '7px', marginBottom: '4px'}}>
                              <g id="Group_150" data-name="Group 150" transform="translate(0 0)">
                                <path id="Path_90" data-name="Path 90" d="M364.8,397.932a1.512,1.512,0,0,0-.583-1.76v-3.658A1.515,1.515,0,0,0,362.7,391H350.52a1.515,1.515,0,0,0-1.514,1.513v3.658a1.512,1.512,0,0,0-.583,1.76H348v6.458h1.009v-1.544h15.2v1.544h1.009v-6.458Zm-14.278-5.923H362.7a.505.505,0,0,1,.5.5v3.4H362.2v-.5a1.515,1.515,0,0,0-1.514-1.514h-3.065a1.51,1.51,0,0,1-2.018,0h-3.065a1.515,1.515,0,0,0-1.514,1.514v.5h-1.007v-3.4a.505.505,0,0,1,.5-.5Zm5.586,3.9h-4.074v-.5a.505.505,0,0,1,.5-.5H355.6a.505.505,0,0,1,.5.5Zm1.009-.5a.505.505,0,0,1,.5-.5h3.065a.505.505,0,0,1,.5.5v.5h-4.074Zm-7.77,2.018a.505.505,0,0,1,.5-.5h13.521a.5.5,0,1,1,0,1.009H349.85a.505.505,0,0,1-.5-.5Zm-.336,1.514h15.2v2.9h-15.2Zm0,0" transform="translate(-348 -391)" fill="#2b2b2c"/>
                              </g>
                            </svg>
                            {v.bedrooms < 2 ? "1 Bedroom" : `${v.bedrooms} Bedrooms`}
                          </Typography>
                        </div>
                        <Typography variant="body1" style={{color: "#1D1D1B"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18.75" height="17.12" viewBox="0 0 18.75 17.12" style={{marginRight: '7px', marginBottom: '4px'}}>
                            <g id="Group_151" data-name="Group 151" transform="translate(0 0)">
                              <path id="Path_91" data-name="Path 91" d="M18.343,28.783h-.41V23.891a1.63,1.63,0,1,0-3.261,0v.052a1.631,1.631,0,0,0-1.218,1.575v.41a.408.408,0,0,0,.408.408H16.3a.408.408,0,0,0,.408-.408v-.41a1.631,1.631,0,0,0-1.223-1.577v-.051a.815.815,0,1,1,1.63,0v4.891H.408A.408.408,0,0,0,0,29.19v3.251a5.316,5.316,0,0,0,4.9,5.294v1.238a.408.408,0,0,0,.408.408H6.94a.408.408,0,0,0,.408-.408V37.75h4.881v1.223a.408.408,0,0,0,.408.408h1.63a.408.408,0,0,0,.408-.408V37.605a5.318,5.318,0,0,0,4.076-5.164V29.19A.408.408,0,0,0,18.343,28.783Zm-2.448-3.263v0H14.269v0a.813.813,0,1,1,1.625,0ZM6.532,38.565H5.717V37.75h.815Zm7.327-.815v.815h-.815V37.75h.815Zm-.418-.815H5.309A4.5,4.5,0,0,1,.815,32.441V31.228h1.2a.408.408,0,0,0,0-.815H.815V29.6h17.12v.815H4.463a.408.408,0,0,0,0,.815H17.935v1.213A4.5,4.5,0,0,1,13.441,36.935Z" transform="translate(0 -22.261)" fill="#2b2b2c"/>
                              <path id="Path_92" data-name="Path 92" d="M379.25,145.919V145.1a.408.408,0,0,0-.815,0v.815a.408.408,0,1,0,.815,0Z" transform="translate(-364.576 -140.212)" fill="#2b2b2c"/>
                              <path id="Path_93" data-name="Path 93" d="M423.772,145.919V145.1a.408.408,0,1,0-.815,0v.815a.408.408,0,1,0,.815,0Z" transform="translate(-407.468 -140.212)" fill="#2b2b2c"/>
                              <path id="Path_94" data-name="Path 94" d="M77.765,244.87a.408.408,0,1,0,.288.119A.411.411,0,0,0,77.765,244.87Z" transform="translate(-74.524 -236.718)" fill="#2b2b2c"/>
                            </g>
                          </svg>
                          {v.bathrooms < 2 ? "1 Bathroom" : `${v.bathrooms} Bathrooms`}
                        </Typography>
                        <Typography variant="body1" style={{color: "#1D1D1B"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="17.026" viewBox="0 0 20.826 17.026" style={{marginRight: '5px', marginBottom: '4px'}}>
                            <g id="Ladder-up" transform="translate(0.252 -5.73)">
                              <path id="Path_58541" data-name="Path 58541" d="M20.006,6.016H16.181a.318.318,0,0,0-.318.318V10.32H12.233a.318.318,0,0,0-.318.318v3.284H8.207a.318.318,0,0,0-.318.318v3.8H4.337a.318.318,0,0,0-.318.318v3.537H.316a.318.318,0,1,0,0,.636H4.337a.318.318,0,0,0,.318-.318V18.675H8.207a.318.318,0,0,0,.318-.318v-3.8h3.708a.318.318,0,0,0,.318-.318V10.957h3.629a.318.318,0,0,0,.318-.318V6.652h3.507a.318.318,0,1,0,0-.636Z" transform="translate(0 -0.025)" fill="#2b2b2c" stroke="#2b2b2c" stroke-width="0.5"/>
                              <path id="Path_58542" data-name="Path 58542" d="M7.342,11.7a.305.305,0,0,0,.217-.09l4.535-4.535v3.048a.329.329,0,1,0,.659,0V6.31a.329.329,0,0,0-.329-.33H8.612a.329.329,0,0,0,0,.659H11.66L7.125,11.174a.306.306,0,0,0,.217.523Z" transform="translate(-4.802 0)" fill="#2b2b2c" stroke="#2b2b2c" stroke-width="0.5"/>
                            </g>
                          </svg>
                          Floor {`${["ground", "first", "second", "third", "fourth", "fifth","sixth","seventh", "eighth", "ninth", "tenth", "eleventh", "twelveth", "thirteenth", "fourteenth", "fifteenth"].indexOf((v.path || "").split('/')[1])}`}
                        </Typography>
                      </Grid>
                      {/* <Grid item style={{display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0px 20px'}}>
                        <Typography variant="body1" style={{color: "#1D1D1B"}}>{v.description}</Typography>
                      </Grid> */}
                      <Grid item xs={12} sm={12} style={{position: 'relative', flexDirection: 'row', paddingTop: '0px', paddingBottom: '5px', justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                        <Link to={`/favourites/${v.id}`}>
                          <Grid item style={{display: 'flex', cursor: 'pointer', padding: '5px 0px', marginRight: "0px", borderRadius: "3px", backgroundColor: '#DA8185', color: 'white', width: "100%", alignItems: 'center', justifyContent: "center"}} >
                            <Typography variant="body1">View plot details</Typography>
                          </Grid>
                        </Link>
                        {/* <div style={{display: "inline-block"}}>
                          <span className="bigcheck-register cancel-drag">
                            <label
                              className="bigcheck-register"
                              style={{fontSize: "16px", color: "black", fontFamily: "HelveticaNeue, Arial, sans-serif", paddingBottom: "-5px"}}
                            >
                              Compare home
                              <input
                                style={{color: "black", alignItems: 'center', justifyContent: "center"}}
                                
                                type="checkbox"
                                checked={v.selected === undefined ? false : v.selected}
                                onChange={(event) => compareCheckedHandler(event, i, v.id)}
                                // onChange={(event) => props.compareChecked(event, i, v.id)}
                                className="bigcheck-register"
                                id={`prop-check-${v.id}`}
                                name="Agreed"
                                value="Agreed"

                                
                              />
                              <span className="bigcheck-target-register"></span>
                            </label>
                          </span>
                        </div> */}
                        {/* {props.checkboxDivProp(v.id)} */}
                      </Grid>
                      {/* <Grid item xs={12} sm={6} style={{display: 'flex', flexDirection: 'row', padding: '5px', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer'}} onClick={() => onSelect(k)}>
                          <Grid item style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', color: 'white', width: '30px', height: '30px', marginRight: '10px'}}>
                            {selected.indexOf(k) != -1 && <CheckIcon style={{color: '481D3F'}} />}
                          </Grid>
                          <Typography variant="body1" style={{marginTop: '4px'}}>Compare Home</Typography>
                        </div>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  
                ))}
              </Grid>
            </Container>
            {/*<Row style={{marginTop: "60px"}}>
                <div
                    style={{
                    position: "fixed",
                    bottom: "0px",
                    left: "30px",
                    width: "400px",
                    }}
                >
                    <PlotFilters {...styles}
                    />
                </div>
            </Row>*/}
            {/* <Row>
                <div
                    style={{
                    position: "fixed",
                    bottom: "0px",
                    right: "30px",
                    width: "400px",
                  }}
                  >
                    <button
                        onClick={() => props.toggleCompare()}
                        style={{ width: "350px", position: 'absolute', margin: '0', color: 'white', backgroundColor: "#DA8185", height: 'auto', padding: '15px 0px', bottom: '0px', right: '0px', fontWeight: '800', fontSize: '20px', textAlign: 'center' }}
                        disabled={!maxCompareSelected}
                        class="maxCompare"
                    >
                      <span className="bold-GalanoGrotesque">COMPARE HOMES</span>
                    </button>
                </div>
            </Row> */}
          </Box>
        </>
      );
    }
  
}

export default Favourites;