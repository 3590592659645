import React from "react";
import LocationMap from '../components/Maps/LocationMap';

function LocationPage(props) {
  // document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("landing-page");
  //   return function cleanup() {
  //     document.body.classList.remove("landing-page");
  //   };
  // });
  return (
    <LocationMap {...props} />
  );
}

export default LocationPage;
