import React from "react";

export default class StorageImg extends React.Component {

  render() {
    const path = (this.props.path || "").toString().replace(/\&/g, '%26').replace(/\//g, '%2F');
    const src = this.props.path ? "https://firebasestorage.googleapis.com/v0/b/focus-digisuite.appspot.com/o/" + path + "?alt=media" : this.props.src
    return (
      <img {...this.props} src={src} />
    )
  }
}