import * as actionTypes from '../actions/actionTypes';

const initialState = {
    sitePages: {
      project_logo : null,
      focus_logo : null,
      show_background_video : false,
      background_video : null,
      carouselItems: []
    },
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_SITE_PAGES:
            return {
                ...state,
                sitePages : action.sitePages,
                error: false
            };
        case actionTypes.FETCH_SITE_PAGES_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;