import React from "react";
import { Box, Grid, Typography, Container, Divider, Hidden } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SwipeableViews from 'react-swipeable-views';

const data = [
  //{
    //  title: "Electrical and Heating",
    //  content: [
      //    "Matt white switches and sockets to all rooms",
      //    "LED downlighters to the main bathroom, kitchen and en-suite if applicable",
      //    "Pendant lighting to bedrooms, hall, landing and living/dining areas",
      //    "Television points to the lounge and master bedroom",
      //    "Mains operated ceiling mounted smoke detector",
      //   "Communal heating system with radiators",
      //    "Video door entry system"
      //  ],
      //  img: require('../assets/img/gallery/Electrics.jpg')
      //},
      {
        title: "Kitchen/Dining/Living Areas",
        content: [
          
          "Stainless Steel Bosch built-in oven with induction hob",
          "Bosch slimline cooker hood in silver",
          "Integrated Bosch microwave in black",
          "Integrated Bosch fridge/freezer*",
          "Integrated Bosch dishwasher",
          "Gooseneck tap in brushed stainless steel",
          "Natural stone quartz countertop in ash grey",
          "Matt cabinets in graphite grey",
          "Glass splashbacks in graphite grey",
          "Recessed downlights throughout"
        ],
        img: require('../assets/img/gallery/LoungeDining.jpeg')
      },
      {
        title: "Bathrooms",
        content: [
          "Back-to-wall WC in white with chromium plated flush plate",
          "Semi-countertop washbasin in white",
          "Double robe hook and heated towel rail in chrome",
          "Chrome-plated toilet roll holder",
          "Steel bath in white with glass screen to main bathroom",
          "Flat top shower tray in acrylic/atone resin to en suite",
          "Semi-framed pivot door shower screen in silver to en suite",
          "Mirrored cabinet (including shaver socket) to the main bathroom"
          
        ],
        img: require('../assets/img/gallery/Bathroom2.jpg')
      },
      {
        title: "Finishes",
        content: [
          "Off-white to living and bedroom walls and doors",
          "Brilliant white to bathroom walls",
          "Brilliant white to ceilings",
          "Matt finish porcelain tiles to bathroom walls in grey",
          "Ribbed dark tile to bathroom feature wall in black",
          "Karndean vinyl flooring to living areas in urban oak",
          "Cormar carpets to bedrooms in grey**",
          "Natural tile flooring to bathroom in black peak",
          "Underfloor heating (except bathrooms)"
        ],
        img: require('../assets/img/gallery/Terrace.jpeg')
      }
    ];
    
    class ViewSpecificationPrivateSale extends React.Component {
      constructor(props) {
        super(props);
    
        this.state = {
          index: 0
        }
        this._map = React.createRef();
        this.load = this.load.bind(this);
        this.goForward = this.goForward.bind(this);
        this.goBack = this.goBack.bind(this);
        this.changeIndex = this.changeIndex.bind(this);
      }
    
      componentDidUpdate(prevProps, prevState) {
        this.load();
      }
    
      load() {
        this._map.current && this._map.current.initCanvas();
      }
    
      changeIndex(index) {
        this.setState({index});
      }
    
      goForward() {
        let index = this.state.index + 1;
        if (index >= 0 && index < data.length)
          this.setState({index});
      }
    
      goBack() {
        let index = this.state.index - 1;
        if (index >= 0 && index < data.length)
          this.setState({index});
      }
    
      render() {  
        return (
          <Box style={{padding: '0px', backgroundColor: '#FEF9F2', width: "100%"}}>
            <Container style={{color: "black", backgroundColor: "#FEF9F2", padding: "0px", margin: "0px", minWidth: "100%"}} >
              <Grid container item  style={{padding: "0px", margin: "0px", minWidth: "100%"}}>
                <Grid item xs={12} style={{marginTop: "60px"}}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "AktivRegular", textAlign: 'center'}}
                  >
                    We aim to create new home that are energy-efficient,
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: '50px'}}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "AktivRegular", textAlign: 'center'}}
                  >
                    feature contemporary designs and excellent build quality
                  </Typography>
    
                </Grid>
                <Grid item xs={1} sm={4} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: "25%"}}>
                  <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 0 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                    <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 0 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                      <img src={require('../assets/img/Kitchen.png')} style={{width: '70px', cursor: "pointer"}} onClick={() => {this.setState({index: 0})}}/>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} sm={4} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 1 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                    <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 2 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                      <img src={require('../assets/img/Bathroom.png')} style={{width: '70px', cursor: "pointer"}} onClick={() => {this.setState({index: 1})}}/>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} sm={4} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: "25%"}}>
                <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 2 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                    <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 3 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                      <img src={require('../assets/img/Room2.png')} style={{width: '70px', cursor: "pointer"}} onClick={() => {this.setState({index: 2})}}/>
                    </div>
                  </div>
                </Grid>
    
                <Grid item xs={1} sm={4} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: "25%"}}>
                  <Typography
                      variant="h6"
                      style={{ fontFamily: "AktivRegular", textAlign: 'center', marginTop: "20px"}}
                    >
                    Kitchen 
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={4} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Typography
                      variant="h6"
                      style={{ fontFamily: "AktivRegular", fontWeight: "500", textAlign: 'center', marginTop: "20px"}}
                    >
                      Bathrooms
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={4} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: "25%"}}>
                  <Typography
                    variant="h6"
                    style={{ fontFamily: "AktivRegular", textAlign: 'center', marginTop: "20px"}}
                  >
                    Finishes
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '0px', padding: "0px", margin: "0px", Width: "100%"}}>
                    <SwipeableViews
                    index={this.state.index}
                    onChangeIndex={this.changeIndex}
                    enableMouseEvents
                    style={{
                      padding: "0px",
                      margin: "0px",
                      Width: "100%",
                    }}
                    containerStyle={{
                      padding: "0px",
                      margin: "0px",
                      Width: "100%",
                      display: 'flex'
                    }}
                  >
                    {data.map((d, i) => 
                      <Grid key={i} container>
                        <Grid item xs={1} sm={1} md={1} lg={1} style={{height: "auto", width: "100%", backgroundColor: "red"}}>
                          <Box style={{backgroundColor: '#388189', height: "100%", width: "100%"}}>
                            <Box p={2} style={{border: '0px', borderColor: "black", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", position: 'relative', top: "50%", right: "-20%"}} borderRadius='50%' width='50px' height='50px' justifyContent='center' alignItems='center'>
                              <ChevronLeftIcon fontSize='large' style={{color: "white", marginRight: '1px'}}  onClick={this.goBack}/>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <Box style={{backgroundColor: '#388189', padding: '20px', color: 'white', height: "100%", alignContent: "left"}}>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ fontFamily: "le-monde-livre-std, serif", paddingTop: "5px", textAlign: "left"}}
                            >
                              {d.title}
                            </Typography>
                            <Divider style={{backgroundColor: '#DA8185', marginBottom: '55px', height: "5px", borderRadius: 30}} />
                            {
                              d.content.map(v => (
                                <Typography
                                  style={{fontSize: "18px", fontFamily: "ActivRegular", textAlign: "left"}}
                                  >
                                  {`• ${v}`}
                                </Typography>
                              ))
                            }
                          </Box>
                          <Box p={2} style={{border: '0px', borderColor: "black", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", position: 'relative', top: "-50%", right: "-208%", zIndex: "10000"}} borderRadius='50%' width='50px' height='50px' justifyContent='center' alignItems='center'>
                              <ChevronRightIcon fontSize='large' style={{color: "white", marginRight: '1px'}}  onClick={this.goForward}/>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{zIndex: 100, overflowX: "hidden", height: "100%"}}>
                                
                              <Box style={{width: "100%", padding: "0px", margin: "0px", backgroundColor: "black", height: "100%", position: "relative"}}>
                                <img src={d.img}  loading="lazy"  style={{zIndex: 50, width: "100%", padding: "0px", margin: "0px", height: "100%"}}/>
                              </Box>                        
                          </Grid>
                      </Grid>
                    )}
                  </SwipeableViews>
                  </Grid>
              </Grid>  
          <Grid>             
            <Box style={{backgroundColor: '#FEF9F2', color: "black"}}>
            <Typography style={{fontSize: "14px", fontFamily: "ActivRegular", padding: "20px"}}>
              The specification of the properties is correct at the date of print but may change as building works progress. Any images are indicative of
              the anticipated quality and style of the specification and may not represent the actual furnishings and fittings of the properties. Unless specifically
              incorporated in writing into the sales contract the specification is not intended to form part of any offer, contract, warranty or representation
              and should not be relied upon as a statement or representation of fact. *Undercounter Bosch fridge with freezer section to studio apartments.
              **Karndean vinyl flooring to bedroom in urban oak to studio apartments.
            </Typography>
            </Box>
          </Grid>
        </Container>
      </Box>
    );
  }
}

export default ViewSpecificationPrivateSale;