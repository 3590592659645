import * as actionTypes from '../actions/actionTypes';

const initialState = {
    propertyDropdpwnOptions:[],
    priceDropdpwnOptions: [],
    priceMinDropdpwnOptions: [],
    areaDropdpwnOptions: [],
    bedroomsDropdpwnOptions: [],
    availabilityDropdpwnOptions: [],
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_FILTER_SETTINGS:
            return {
                ...state,
                propertyDropdpwnOptions : action.propertyDropdpwnOptions,
                priceDropdpwnOptions: action.priceDropdpwnOptions,
                priceMinDropdpwnOptions: action.priceMinDropdpwnOptions,
                areaDropdpwnOptions: action.areaDropdpwnOptions,
                bedroomsDropdpwnOptions: action.bedroomsDropdpwnOptions,
                availabilityDropdpwnOptions: action.availabilityDropdpwnOptions,
                error: false
            };
        case actionTypes.FETCH_FILTER_SETTINGS_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;



