import React from "react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import StorageImg from "components/StorageImg";
import "./ReactCarouselSmallCompareStyle.css";

class ReactCarouselSmallCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onchange = this.onchange.bind(this);
  }

  onchange(value) {
    this.setState({ value });
  }

  componentDidMount() {}

  componentWillMount() {}

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "row",
          alignContent: "center",
          textAlign: "center",
          height: '330px'
        }}
      >
        <Carousel
          slidesPerPage={1}
          value={this.state.value}
          onChange={this.onchange}
          infinite
          keepDirectionWhenDragging
          style={{ height: '330px' }}
        >
          {this.props.slides.map((item) => {
            return (
              <div key={item.id} style={{ width: "100%"}}>
                <StorageImg path={item} style={{width: "100%",
                    padding: '0px',
                    height: "330px",
                    borderRadius: "0px",
                    objectFit: "cover"}} />
                {/* <img
                  style={{
                    width: "100%",
                    padding: '0px',
                    height: "330px",
                    borderRadius: "0px",
                    objectFit: "cover",
                  }}
                  src={item}
                /> */}
              </div>
            );
          })}
        </Carousel>
        <div
          style={{
            position: "absolute",
            width: "300px",
            marginTop: "290px"
          }}
        >
          <Dots
            value={this.state.value} 
            onChange={this.onchange}
            number={this.props.slides.length}
          />
        </div>
      </div>
    );
  }
}
export default ReactCarouselSmallCompare;
