import React from "react";
import { InfoCarousel_V2 } from "@focusdigisuite/components";
import Theme from "../theme/index";
import { Route } from "react-router";
import LiveNavBar from "components/Navbars/LiveNavBar";
import { storage } from "firebase";
import { ref, getDownloadURL  } from "firebase/storage";


class HandleInfoPages extends React.Component {


  async getImage(path) {

    const pathReference = ref(storage, path);
    return await getDownloadURL("gs://focus-digisuite.appspot.com/" +pathReference)
  }
  render() {
    return Object.keys(this.props.infoPages || {}).map((k, i) => {
      console.log(this.props.infoPages[k].list)
      return (
        <Route
          path={this.props.infoPages[k].uri}
          exact
          key={i}
          render={() => (
            <React.Fragment>
              <LiveNavBar items={this.props.navBarItems} title={this.props.infoPages[k].name} />
              <div style={{height: 'calc(100vh - 128px)', backgroundColor: "#FEF9F2"}}>
                <InfoCarousel_V2
                  slides={Object.values(this.props.infoPages[k].list || {})}
                  theme={Theme}
                  getImageURL={this.getImage}
                  navIconBackGroundColor={"green"}
                  navIconColor={"red"}
                  fontReg={'AktivRegular'}
                  fontBold={'AktivBold'}
                  fontItalic={'LeMondeBoldItalic'}
                />
              </div>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default HandleInfoPages;
