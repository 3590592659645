import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "./store/actions/index";
// pages
// import Index from "views/Index.js";
import Location from "views/LocationPage";
import About from "views/AboutPage";
// import Timeline from "views/TimelinePage";
import Gallery from "views/GalleryPage";
// import Exterior from "views/ExteriorPage";
// import Interior from "views/InteriorPage";
//import VideoPage from "views/VideoPage";
// import LocalArea from "views/LocalAreaPage";
// import Register from "views/RegisterPage.js";
// import Share from "views/SharePage.js";
// import Properties from "views/PropertiesPage.js";
// import Terms from "views/TermsPage.js";
// import Privacy from "views/PrivacyPage.js";
// import PlotOverview from "views/PlotOverviewPage.js";
import Favourites from "views/Favourites.js";
// import SiteplanPhase from "views/SiteplanPhasePage.js";
// import ApartmentFloors from "views/ApartmentFloorPage";

// import BuyingScheme from "views/BuyingScheme";
//import InfoPage from "views/InfoPage";
import InfoPageBasic from "views/InfoPageBasic";
import Homepage from "views/Homepage";

import LiveNavbar from "./components/Navbars/LiveNavBar";
import { ThemeProvider } from "@material-ui/styles";
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from "theme/index.js";
import "./styles.css";
import * as fetch from 'node-fetch';
import HandleInfoPages from "handlers/HandleInfoPages";
import { HandleGallery } from "handlers/HandleGalleries";
import LiveNavBar from "./components/Navbars/LiveNavBar";
import ViewProperties from "views/ViewProperties";
import ViewSiteplan from "views/ViewSiteplan";
import ViewFloors from "views/ViewFloors";
import ViewProperty from "views/ViewProperty";
import ViewSpecification from "views/ViewSpecification";
import ViewSpecificationSharedOwnership from "views/ViewSpecificationSharedOwnership";
import ViewSpecificationPrivateSale from "views/ViewSpecificationPrivateSale";
//import ViewSpecification from "views/ViewSpecification";
import SharedOwnership from "views/SharedOwnership";
//import Amenities from "views/Amenities";
import ViewApartmentVideo from "views/ViewApartmentVideo";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBarItems: [],
      config: {}
    };
  }

  componentDidMount() {
    // this.props.onInitSitePagesSettings();
    // this.props.onInitNavigationSettings();
    // this.props.onInitDevStylesSettings();
    // this.props.onInitFilterSettings();
    // this.props.onInitDevelopmentSettings();
    // this.props.onInitPropertySettings();
    // this.props.onInitApartmentBlocks();
    // this.props.onInitAboutCatalystSettings();
    // this.props.onInitBuyingSchemeSettings();
    return fetch('https://us-central1-focus-digisuite.cloudfunctions.net/api/digisuites/digisuite-guinness-leasidelock/dev/config')
    .then(r => r.json())
    .then(config => {
      this.setState({
        config
      })
    })
  }
  
  componentDidUpdate(prevProps, prevState) {
    //console.log("before")
    //console.log(this.props)

    //console.log(this.state.config)
    //console.log("after")
    if (this.props.navigation !== prevProps.navigation) {
      if(this.props.navigation !== undefined) {
        this.setState({ navBarItems: this.props.navigation.sort(compareValues("order", "asc")) });
  
        function compareValues(key, order = "asc") {
          return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
              return 0;
            }
    
            const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
            const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }

            return order === "desc" ? comparison * -1 : comparison;
          };
        }
      }
    }
  }


  render() {
    return (
      <Router>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavbar {...this.props} items={this.state.navBarItems} hideTopBar={true} />
                  <Homepage {...this.props} config={this.state.config.homepage} />
                </React.Fragment>
              )}
            />
            <Route
              path="/location"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} config={this.state.config.homepage} hideTopBar={false} title="LOCATION" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <Location {...this.props}
                      latitude={51.5260078}
                      longitude={-0.0110498}
                    />
                  </div>
                </React.Fragment>
              )}
            />
            {
              Object.keys(this.state.config.galleries || {}).map((k, i) => (
                <Route
                  path={this.state.config.galleries[k].uri}
                  exact
                  render={() => (
                    <HandleGallery key={i} navBarItems={this.state.navBarItems} {...this.state.config.galleries[k]} />
                  )}
                />
              ))
            }            
            <Route
              path="/shared-ownership"
              exact
              render={(props) => (
              <React.Fragment>
                <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="SHARED OWNERSHIP SPECIFICATION" />
                <div style={{height: 'calc(100vh - 128px)', width: "100%", overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                  <ViewSpecificationSharedOwnership {...this.props} />
                </div>
              </React.Fragment>
              )}
            />
            <Route
              path="/private-sale"
              exact
              render={(props) => (
              <React.Fragment>
                <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="PRIVATE SALE SPECIFICATION" />
                <div style={{height: 'calc(100vh - 128px)', width: "100%", overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                  <ViewSpecificationPrivateSale {...this.props} />
                </div>
              </React.Fragment>
              )}
            />
            <Route
              path="/siteplan"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="SITE PLAN" />
                  <div style={{height:'calc(100vh - 128px)', backgroundColor: "#FEF9F2"}}>
                    <ViewSiteplan {...this.props} />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/floors/:id"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="SELECT PLOT" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <ViewFloors {...this.props} properties={this.state.config.properties} />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/floors/:id/:plotId"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="PLOT" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <ViewProperty {...this.props} {...props} properties={this.state.config.properties}/>
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/find-your-new-home/:plotId"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false}  title="Plot" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <ViewProperty {...this.props} {...props} properties={this.state.config.properties} gallery={this.state.config}/>
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/favourites/:plotId"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false}  title="Plot" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <ViewProperty {...this.props} {...props} properties={this.state.config.properties} gallery={this.state.config}/>
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/find-your-new-home"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} config={this.state.config.homepage} hideTopBar={false} title="FIND YOUR NEW HOME" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <ViewProperties {...this.props} {...props} properties={this.state.config.properties} />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/gallery"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="GALLERY" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <Gallery {...this.props} {...props} />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/favourites"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavBar items={this.state.navBarItems} hideTopBar={false} title="FAVOURITES" />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <Favourites {...this.props} heartSelected={["hello again"]} />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/amenities"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavbar items={this.state.navBarItems} hideTopBar={false} title={"AMENITIES"} />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <InfoPageBasic 
                      {...this.props}
                      {...props}
                      slides={[
                        {
                          image: "https://firebasestorage.googleapis.com/v0/b/focus-digisuite.appspot.com/o/digisuites/digisuite-guinness-leasidelock/galleries/gallery/images/KitchenHallway.jpg",
                          title: "The essentials you need",
                          subTitle: "and all close to hand",
                          icons: [
                            {
                              icon: "digisuites/digisuite-guinness-leasidelock/infoPages/amenities/list.1/icons/0/icon/gym_icon.png",
                              label: "Reading Station within a 10 minute walk"
                            }
                          ],
                          type: "iconed-with-background"
                        }
                      ]}
                    />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="/apartment-video"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavbar items={this.state.navBarItems} hideTopBar={false} title={"APARTMENT VIDEO"} />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <ViewApartmentVideo {...this.props} />
                  </div>
                </React.Fragment>
              )}
            />
            <HandleInfoPages
              navBarItems={this.state.navBarItems}
              infoPages={this.state.config.infoPages}
            />
            {/* <Route
              path="/about-guinness-homes1"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavbar items={this.state.navBarItems} hideTopBar={false} title={"ABOUT GUINNESS HOMES"} />
                  <div style={{height: 'calc(100vh - 128px)', overflow: 'auto', backgroundColor: "#FEF9F2"}}>
                    <AboutClient {...this.props} />
                  </div>
                </React.Fragment>
              )}
            /> */}
            
            {/*<Route
              path="/about-lq"
              exact
              render={(props) => (
                <React.Fragment>
                  <LiveNavbar items={this.state.navBarItems} title={"About L&Q"} />
                  <InfoPage
                    slides={[
                      {
                        image: "https://picsum.photos/1000/1000",
                        title: "Better places",
                        subTitle: "and better lives",
                        introduction: "At L&Q we believe passionately that people's health, security and happiness",
                        description: "Our relationships are built on trust,",
                        type: "image-right"
                      }
                    ]}
                  />
                </React.Fragment>
                  )} 
            />*/}

            {/*
digisuites/digisuite-guinness-leasidelock/infoPages/amenities/list.1/icons/1/icon/gym_icon.png
            ,
                          {
                            icon: "",
                            label: "On-site gym"
                          },
                          {
                            icon: "",
                            label: "A short walk to popular restaurants, coffee shops and bars"
                          },
                          {
                            icon: "",
                            label: "On-site nursery and close to schools and Reading University"
                          }
             */}

            {/* <Route
              path="/buying-schemes"
              render={(props) => <BuyingScheme {...this.props} />}
            />
            */
            /* <Route
              path="/about"
              render={(props) => <About {...this.props} />}
            />
            <Route
              path="/gallery-exterior"
              render={(props) => <Exterior {...this.props} />}
            />
            <Route
              path="/gallery-interior"
              render={(props) => <Interior {...this.props} />}
            />
            <Route
              path="/gallery-video"
              render={(props) => <VideoPage {...this.props} />}
            />
            <Route
              path="/homes-for-sale/:plot"
              render={(props) => <Properties {...Object.assign(props, this.props)} />}
            />
            <Route
              path="/homes-for-sale"
              render={(props) => <Properties {...this.props} />}
            />
            <Route
              path="/favourites"
              render={(props) => <Favourites {...this.props} />}
            />
            <Route
              path="/plot-overview/:plot"
              render={(props) => <PlotOverview {...this.props} />}
            />
            <Route
              path="/share-property/:plot"
              render={(props) => <Share {...props} />}
            />
            <Route
              path="/siteplan"
              render={(props) => <SiteplanPhase {...this.props} />}
            />
            <Route
              path="/apartment-floors/:block"
              render={(props) => <ApartmentFloors {...this.props} />}
            /> */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>

        </ThemeProvider>
        </MuiThemeProvider>
        {/* <div style={{position: 'absolute', top: '30px', right: '40px', zIndex: 100000}}>
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="95.901" height="59.143" viewBox="0 0 95.901 59.143">
              <g id="Group_21338" data-name="Group 21338" transform="translate(-225.296 -290.227)">
                <g id="Group_1557" data-name="Group 1557" transform="translate(225.296 314.274)">
                  <path id="Path_1936" data-name="Path 1936" d="M233.944,344.341v-5.689h-5.978v5.689H225.3V330.623h2.67v5.514h5.978v-5.514h2.689v13.718Z" transform="translate(-225.296 -330.623)" fill="#fff"/>
                  <path id="Path_1937" data-name="Path 1937" d="M253.142,343.286a4.9,4.9,0,0,1-1.489-3.8v-8.861h2.67v8.668a2.809,2.809,0,0,0,.687,2.041,2.537,2.537,0,0,0,1.906.706,2.563,2.563,0,0,0,1.925-.706,2.812,2.812,0,0,0,.687-2.041v-8.668h2.67v8.861a4.893,4.893,0,0,1-1.49,3.8,5.925,5.925,0,0,1-7.565,0Z" transform="translate(-235.963 -330.623)" fill="#fff"/>
                  <path id="Path_1938" data-name="Path 1938" d="M285.545,344.341l-6.23-9.732v9.732h-2.67V330.623h3.328l5.708,9.074v-9.074h2.67v13.718Z" transform="translate(-246.078 -330.623)" fill="#fff"/>
                  <path id="Path_1939" data-name="Path 1939" d="M308.758,333.158v11.183h-2.689V333.158h-4.334v-2.535h11.357v2.535Z" transform="translate(-256.232 -330.623)" fill="#fff"/>
                  <path id="Path_1940" data-name="Path 1940" d="M326.24,344.341V330.623h2.67v11.164h6.113v2.554Z" transform="translate(-266.149 -330.623)" fill="#fff"/>
                  <path id="Path_1941" data-name="Path 1941" d="M346.683,344.341V330.623h8.59v2.515h-5.92v3.134h5.359v2.38h-5.359v3.173h5.92v2.515Z" transform="translate(-274.423 -330.623)" fill="#fff"/>
                  <path id="Path_1942" data-name="Path 1942" d="M365.759,330.623h3.134l3.076,5.437,3.076-5.437h3l-4.837,7.952v5.766h-2.67v-5.766Z" transform="translate(-282.143 -330.623)" fill="#fff"/>
                </g>
                <g id="Group_1558" data-name="Group 1558" transform="translate(234.466 335.653)">
                  <path id="Path_1943" data-name="Path 1943" d="M257.379,366.536h2.728l-3.792,13.717h-2.747l-3.134-9.77-3.134,9.77h-2.786L240.7,366.536h2.825l2.515,9.442,3.037-9.442h2.767l3.057,9.481Z" transform="translate(-240.701 -366.536)" fill="#fff"/>
                  <path id="Path_1944" data-name="Path 1944" d="M287.244,380.253v-5.688h-5.978v5.688H278.6V366.536h2.67v5.514h5.978v-5.514h2.689v13.717Z" transform="translate(-256.038 -366.536)" fill="#fff"/>
                  <path id="Path_1945" data-name="Path 1945" d="M312.411,377.119h-5.572l-1.142,3.134h-2.824l5.262-13.717h3.1l5.224,13.717h-2.9Zm-4.683-2.438h3.792l-1.9-5.186Z" transform="translate(-265.863 -366.536)" fill="#fff"/>
                  <path id="Path_1946" data-name="Path 1946" d="M335,374.971h-1.393v5.282H330.92V366.536h5.359a4.291,4.291,0,0,1,3.173,1.2,4.094,4.094,0,0,1,1.2,3.018,3.887,3.887,0,0,1-2.922,3.889l2.941,5.611h-2.98Zm.774-2.3a2.251,2.251,0,0,0,1.578-.523,1.777,1.777,0,0,0,.571-1.374,1.8,1.8,0,0,0-.571-1.392,2.251,2.251,0,0,0-1.578-.523h-2.167v3.812Z" transform="translate(-277.214 -366.536)" fill="#fff"/>
                  <path id="Path_1947" data-name="Path 1947" d="M353.507,380.253V366.536h8.667v2.535h-6v3.385h5.34v2.457H356.2v5.34Z" transform="translate(-286.355 -366.536)" fill="#fff"/>
                </g>
                <g id="Group_1559" data-name="Group 1559" transform="translate(248.148 290.227)">
                  <path id="Path_1948" data-name="Path 1948" d="M271.056,302.727h-7.372V290.656h1.669v10.5h5.7Z" transform="translate(-263.684 -290.401)" fill="#f3e33d"/>
                  <path id="Path_1949" data-name="Path 1949" d="M285.936,302.553l-1.532-1.6a4.6,4.6,0,0,1-3.388,1.8,3.485,3.485,0,0,1-2.554-.919,2.932,2.932,0,0,1-.936-2.162,3.067,3.067,0,0,1,.519-1.822,7.4,7.4,0,0,1,1.609-1.55l.17-.136-.221-.238q-.426-.443-.655-.724a4.621,4.621,0,0,1-.511-.851,2.506,2.506,0,0,1-.281-1.115,2.756,2.756,0,0,1,.945-2.2,3.22,3.22,0,0,1,2.171-.817,3.314,3.314,0,0,1,2.3.809,2.73,2.73,0,0,1,.9,2.136,3.453,3.453,0,0,1-1.7,2.673l-.613.46,2.248,2.332,2.69-3v2.2l-1.634,1.89,2.741,2.826Zm-4.9-1.328a2.049,2.049,0,0,0,1.15-.341,5.805,5.805,0,0,0,1.1-.987l.051-.068-2.451-2.57-.613.46a2.265,2.265,0,0,0-1.021,1.822,1.637,1.637,0,0,0,.5,1.166A1.709,1.709,0,0,0,281.033,301.225Zm-1.226-8.087a2.649,2.649,0,0,0,.92,1.686l.374.408.817-.613a1.872,1.872,0,0,0,.9-1.5,1.4,1.4,0,0,0-.434-1.039,1.578,1.578,0,0,0-2.128-.026A1.374,1.374,0,0,0,279.807,293.138Z" transform="translate(-269.286 -290.227)" fill="#f3e33d"/>
                  <path id="Path_1950" data-name="Path 1950" d="M296.2,296.509a6.7,6.7,0,0,1,.519-2.673,5.587,5.587,0,0,1,1.379-1.983,6.387,6.387,0,0,1,1.932-1.2,6.055,6.055,0,0,1,4.46,0,6.393,6.393,0,0,1,1.932,1.2,5.6,5.6,0,0,1,1.38,1.983,6.72,6.72,0,0,1,.518,2.673,6.142,6.142,0,0,1-1.7,4.461l1.413,1.583-1.141,1-1.464-1.618a5.981,5.981,0,0,1-3.167.868,6.168,6.168,0,0,1-2.911-.715,5.794,5.794,0,0,1-2.256-2.179A6.4,6.4,0,0,1,296.2,296.509Zm2.979-3.5a5.443,5.443,0,0,0,0,7.006,4.227,4.227,0,0,0,3.081,1.26,4.283,4.283,0,0,0,2.112-.528L302.28,298.4l1.158-1,2.11,2.384a4.949,4.949,0,0,0,1.073-3.269,4.676,4.676,0,0,0-1.276-3.5,4.421,4.421,0,0,0-6.164,0Z" transform="translate(-276.844 -290.227)" fill="#f3e33d"/>
                  <path id="Path_1951" data-name="Path 1951" d="M325.344,303a2.214,2.214,0,0,1,.681-1.694,3.192,3.192,0,0,1,1.8-.792l2.213-.323q.63-.085.63-.613a1.373,1.373,0,0,0-.442-1.055,1.9,1.9,0,0,0-1.329-.408,1.808,1.808,0,0,0-1.294.451,1.884,1.884,0,0,0-.561,1.2l-1.532-.358a2.681,2.681,0,0,1,1.073-1.915,3.6,3.6,0,0,1,2.282-.741,3.48,3.48,0,0,1,2.57.842,2.934,2.934,0,0,1,.834,2.154v4.1a8.156,8.156,0,0,0,.1,1.345h-1.567a6.764,6.764,0,0,1-.085-1.107,2.988,2.988,0,0,1-2.639,1.362,2.76,2.76,0,0,1-1.975-.723A2.3,2.3,0,0,1,325.344,303Zm2.962,1.107a2.482,2.482,0,0,0,1.72-.579,2.348,2.348,0,0,0,.647-1.838v-.375l-2.5.375a1.579,1.579,0,0,0-.869.391,1.1,1.1,0,0,0-.323.835,1.129,1.129,0,0,0,.366.834A1.318,1.318,0,0,0,328.306,304.1Z" transform="translate(-288.639 -292.866)" fill="#f3e33d"/>
                  <path id="Path_1952" data-name="Path 1952" d="M342.038,292.829v2.588h1.771v1.448h-1.771v4.256a.971.971,0,0,0,1.141,1.124,2.626,2.626,0,0,0,.63-.068v1.362a3.187,3.187,0,0,1-1.09.153,2.256,2.256,0,0,1-1.669-.613,2.328,2.328,0,0,1-.613-1.72v-4.494h-1.566v-1.448h.442a1.208,1.208,0,0,0,.945-.349,1.3,1.3,0,0,0,.314-.911v-1.328Z" transform="translate(-294.114 -291.28)" fill="#f3e33d"/>
                </g>
              </g>
            </svg>
          </Link>
        </div> */}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navigation: state.nav.navigation,
    filterError: state.ftr.error,
    devError: state.dev.error,
    propError: state.prop.error,
    devStylesError: state.sty.error,
    navigationError: state.nav.error,
    sitePagesError: state.pag.error,
    aboutCatalystError: state.cat.error,
    buyingSchemeError: state.sch.error,
    apartmentBlocksError: state.blk.error,
    //homePageTagVisible: state.homePageTag.isTagVisisble
    homePageTagVisible: state.homePageTag
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onInitFilterSettings: () => dispatch(actionCreators.fetchFilterSettings()),
    onInitDevelopmentSettings: () => dispatch(actionCreators.fetchDevelopment()),
    onInitPropertySettings: () => dispatch(actionCreators.fetchProperties()),
    onInitDevStylesSettings: () => dispatch(actionCreators.fetchDevStyles()),
    onInitNavigationSettings: () => dispatch(actionCreators.fetchNavigation()),
    onInitSitePagesSettings: () => dispatch(actionCreators.fetchSitePages()),
    onInitApartmentBlocks: () => dispatch(actionCreators.fetchBlocks()),
    onInitAboutCatalystSettings: () => dispatch(actionCreators.fetchAboutCatalyst()),
    onInitBuyingSchemeSettings: () => dispatch(actionCreators.fetchBuyingScheme()),
    //tagVisible: () => dispatch(actionCreators.tagVisible()),
    //tagHidden: () => dispatch(actionCreators.tagHidden()),
    //onIntiHomepageTag: () => dispatch(actionCreators.isTagVisisble())
    //homePageTagVisible: () => dispatch(homePageTag())

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
