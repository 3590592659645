import React, { useEffect } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import { withRouter } from "react-router";


// nodejs library that concatenates strings
import classnames from "classnames";
import "./LiveNavbar.css";
import { TopBarGuinnessLeasidelock } from "@focusdigisuite/components";
import { makeStyles, useTheme } from "@material-ui/styles";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#391F39 !important",
    overflow: "auto !important",
    maxHeight: "100vh !important",
    display: 'block !important'
  },
  link: {
    color: 'white !important',
    fontSize: '35px !important',
    borderWidth: "0 !important",
    borderBottomWidth: "10px !important",
    borderStyle: "solid !important",
    borderColor: "transparent !important",
    textAlign: "center !important",
    '&:hover': {
      borderColor: "red !important",
    }
  },
  linkContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important'
  }
}));

function LiveNavbar(props) {
  const classes = useStyles();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  //let topbarvisible = true

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const goBack = () => {
    props.history.goBack();
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        //topbarvisible = true
        setNavbarColor("white");

      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
        ) {
          setNavbarColor("navbar-transparent");
          //topbarvisible = false
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  
  // let navItems = props.items.map((p) => {
  //   return (
  //     <NavItem key={p.id} >
  //       <NavLink
  //         to={"/" + p.path}
  //         tag={Link}
  //         onClick={toggleNavbarCollapse}
  //         className={
  //           p.title === "- Development timeline"
  //             ? props.location.pathname === "/" + p.path
  //               ? "body-Kannada navTimeline navSelected"
  //               : "body-Kannada navTimeline"
  //             : p.title === "Terms & conditions"
  //             ? props.location.pathname === "/" + p.path
  //               ? "body-Kannada navTC navSelected"
  //               : "body-Kannada navTC"
  //             : p.title === "Privacy policy"
  //             ? props.location.pathname === "/" + p.path
  //               ? "body-Kannada navPP navSelected"
  //               : "body-Kannada navPP"
  //             : p.title === "- Exterior"
  //             ? props.location.pathname === "/" + p.path
  //               ? "body-Kannada navTimeline navSelected"
  //               : "body-Kannada navTimeline"
  //             : p.title === "- Interior"
  //             ? props.location.pathname === "/" + p.path
  //               ? "body-Kannada navTimeline navSelected"
  //               : "body-Kannada navTimeline"
  //             : p.title === "- Local area"
  //             ? props.location.pathname === "/" + p.path
  //               ? "body-Kannada navTimeline navSelected"
  //               : "body-Kannada navTimeline"
  //             : props.location.pathname === "/" + p.path
  //             ? "body-Kannada navSelected"
  //             : "body-Kannada"
  //         }
  //       >
  //         {p.title}
  //       </NavLink>
  //     </NavItem>
  //   );
  // });

  // let formButton = null;
  // let backButton = null;
  // let noFormButton = true;

  // const pathForTitle = props.location.pathname.replace('/', '');
  // const itemForTitle = props.items.filter(obj => {
  //   return obj.path === pathForTitle
  // });

  // let showMenuButton = true;

  return (
    <React.Fragment>
      <TopBarGuinnessLeasidelock
        backgroundColor="#388189"
        transparent={props.hideTopBar}  //for transparent when side menu is close change to {topbarvisible}
        hideTopBar={props.hideTopBar}
        menuIconOpenColor="white"
        menuIconCloseColor="white"
        fullScreen
        homePageTagVisible={props.store}
        title={props.title || ""}
        links={[
          {
            label: "About Leaside Lock",
            uri: "/about-leaside-lock"
          },
          {
            label: "Location",
            uri: "/location"
          },
          {
             label: "Amenities",
             uri: "/amenities"
          },
          {
             label: "Shared Ownership",
             uri: "/shared-ownership"
          },
          {
             label: "Private Sale",
             uri: "/private-sale"
          },
          {
            label: "Siteplan",
            uri: "/siteplan"
          },
          {
            label: "Homes For Sale",
            uri: "/find-your-new-home"
          },
          {
            label: "Gallery",
            uri: "/gallery"
          },
          //{
          //  label: "Apartment Video",
          //  uri: "/apartment-video"
          //},
          {
            label: "Favourites",
            uri: "/favourites"
          },
          {
            label: "About Guinness Homes",
            uri: "/about-guinness-homes"
          }
        ]}
      
        theme={useTheme()}
        logo={"https://firebasestorage.googleapis.com/v0/b/focus-digisuite.appspot.com/o/digisuites%2Fdigisuite-guinness-leasidelock%2FstaticPages%2Fhomepage%2FiconImage%2Flogo.png?alt=media"}
      />
    </React.Fragment>
  );
}

export default withRouter(LiveNavbar);


// <Navbar
//         className={classnames(
//           "fixed-top",
//           (props.location.pathname.startsWith('/index') ? "navbar-home" : ""),
//           noFormButton === true
//             ? "navbar-transparent navbar-transparent-short"
//             : navbarColor
//         )}
//         color-on-scroll="300"
//         expand="lg"
//       >
//         {props.location !== undefined ? (
//           <Container style={{ margin: "0px 20px", maxWidth: "100%" }}>
//             <div className="navbar-translate">
//               {showMenuButton && 
//                 <button
//                   aria-expanded={navbarCollapse}
//                   className={classnames("navbar-toggler navbar-toggler", {
//                     toggled: navbarCollapse,
//                   })}
//                   onClick={toggleNavbarCollapse}
//                   style={{ zIndex: 800 }}
//                 >
//                   {(props.location.pathname === "/siteplan" || props.location.pathname.startsWith("/apartment-floors")) && !navbarCollapse ? (
//                     <span className="navbar-toggler-bar bar1" />
//                   ) : (
//                     <span className="navbar-toggler-bar bar1" />
//                   )}
//                   {(props.location.pathname === "/siteplan" || props.location.pathname.startsWith("/apartment-floors")) && !navbarCollapse ? (
//                     <span className="navbar-toggler-bar bar2" />
//                   ) : (
//                     <span className="navbar-toggler-bar bar2" />
//                   )}
//                   {(props.location.pathname === "/siteplan" || props.location.pathname.startsWith("/apartment-floors")) && !navbarCollapse ? (
//                     <span className="navbar-toggler-bar bar3" />
//                   ) : (
//                     <span className="navbar-toggler-bar bar3" />
//                   )}
//                 </button>
//               }
//               {backButton}
//               {formButton}

//               {/* {(props.location.pathname !== "/index" && window.header_logo != '') && 
//                 <Link to={"/index"}><div className="home-logo"><img src={window.header_logo} alt="" /></div></Link>
//               } */}

//             </div>

//             {(props.location.pathname !== "/index" && itemForTitle.length > 0) && 
//               <div className="page-title">{itemForTitle[0].title}</div>
//             }
//             {/* {props.location.pathname.indexOf("/apartment-floors") >-1 ?
//               <div className="page-title">Apartment Block</div> : null
//             } */}
//             <Collapse
//               className="justify-content-end"
//               navbar
//               isOpen={navbarCollapse}
//             >
//               <Link to={"/index"}><div className="home-logo"><img src={window.menu_logo} alt="" /></div></Link>
//               <Nav navbar>{navItems}</Nav>
//             </Collapse>
//           </Container>
//         ) : null}
//       </Navbar>