import React from "react";
import { useState, useEffect } from 'react';
import { Box, Grid, Typography, Container, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import StorageImg from "components/StorageImg";
import ReactCarouselGalleryProperty from "../components/UI/ReactCarousel/ReactCarouselGalleryProperty";
//import iconHeartSelected from '../assets/img/icons/heartSelected.svg';
//import iconHeartDeselected from '../assets/img/icons/heartDeselected.svg';

function numberWithCommas(x) {
  return (x || "0").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ViewPropertiesRenderer(props) {
  const v = props.property || {};
  
  const [heartSelected, setHeartSelected] = useState([]);
  const [privateShared, setPrivateShared] = useState([""]);
  // const [, forceUpdate] = useState(0);

    
  useEffect(() => {
      setHeartSelected(JSON.parse(localStorage.getItem('sessionHearts')))
      // localStorage.setItem('sessionHearts', JSON.stringify([]))
    
    
  },[]);

  
  useEffect(() => {
    v.flags && Object.entries(v.flags).filter(([label]) => label === 'sharedOwnership').map(([label2, value]) => (
      // v.flags && Object.entries(v.flags).filter(([label]) => label === 'sharedOwnership').map(([label2, value]) => (
    setPrivateShared(value)
    //forceUpdate(n => !n)
    ))
  },[v]);

  const handleHeartClick = (id) => {
    // Select / Deselect favourites with heart icons
    const data = localStorage.getItem('sessionHearts')
    JSON.parse(data)
    if (data.indexOf(id) == -1) {
      setHeartSelected([...heartSelected, id])
      localStorage.setItem('sessionHearts', JSON.stringify([...heartSelected, id]))
    } else {
      setHeartSelected(heartSelected.filter(v => v != id))
      localStorage.setItem('sessionHearts', JSON.stringify(heartSelected.filter(v => v != id)))
    }
  }

  let imagesSharedOwnership = [
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img0021.jpg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img0254.jpg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1413.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1469.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1484.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1489.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1567.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1607.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Img1611.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/Leaside Lock SO - Bedroom 2 Desk.jpeg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5021.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5020.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5018.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5010.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5009.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5005.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5002.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_5000.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4996.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4990.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4986.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4985.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4981-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4971.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4969.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4967.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4961.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4954.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4952.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4945.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4944.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4936.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4929.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4914.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4908.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4906.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4905.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4901.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4892.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4876.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4873.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4872.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4862.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4845.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4844.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4841.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4834-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4823.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4820.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4806.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4794.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9364.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9363.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9358.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9356.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9350.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9341.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9326-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9299.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9276-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9266-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9252.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9253.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9287.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9290.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9293.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9296.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9301.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9303.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9304.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9308.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9312.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9313.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9314.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9316.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9328.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9329.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9332.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9334.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9335.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9338.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9353.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9354.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9357.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9361.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/DSC_9362.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4782.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4786-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4787.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4819.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4821.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4824.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4826.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4828.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4839.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4840.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4843.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4848.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4850.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4851.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4853.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4863.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4866-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4869.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4871.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4878-HDR.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4885.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4894.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4899.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4902.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4910.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4911.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4912.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4916.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4918.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4922.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4923.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4924.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4930.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4931.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4932.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4934.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4935.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4938.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4941.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4947.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4949.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4951.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4957.jpg",
    // "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4960.jpg",
    "/digisuites/digisuite-guinness-leasidelock/mainImage-Ablocks/additionalcontent/SBH_4962.jpg"
      ]

  const imagesPrivateSale = [
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Bathroom.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Bedroom 2 Headboard.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Bedroom 2.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Hallway.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Kitchen Hallway.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Lounge Dining.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Master Headboard.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Master.jpeg",
    "/digisuites/digisuite-guinness-leasidelock/private-sale-images/Terrace.jpeg",
  ]

  // imagesSharedOwnership.push("hello")
  // {/* <StorageImg path={v.mainImage} */}
  // imagesSharedOwnership.forEach(element => {
  //     console.log(element)   
  // });
 
  // console.log("new images")

  return (
    <Box style={{padding: '30px', minHeight: '100%'}}>
      <Container style={{width: '100%', minHeight: '100px', color: "#FEF9F2"}} maxWidth="xl">
      {!props.showGallery ? 
        <Grid container spacing={4} style={{paddingBottom: '150px'}}>
          <Grid item xs={12} style={{paddingRight: '20px', backrgroundColor: '#FEF9F2', display: 'flex', flexDirection: 'row'}}>
            <Link to={"./"} style={{marginTop: "-15px", display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'rgb(57, 31, 57)'}}>
              <div style={{
                    clipPath: "polygon(0 50%, 100% 0, 100% 100%)",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "black",
                    marginRight: '10px',
                    marginLeft: '30px'
                    
                  }} />   
              <Typography variant="body1" className={'font-face-akb'} style={{fontWeight: 'bold', fontSize: '30px'}}>Back</Typography>
            </Link>
            <Link onClick={() => props.showGalleryClick(false)} className={!props.showGallery ? "overBackgroundChecked" : "overBackgroundNotChecked"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', right: '18%', padding: '1px', paddingLeft: "3px", width: '9.5%', marginTop: '-10px', borderRadius: "3px", alignItems: "center", justifyContent: "center"}}>
              <Typography variant="body1" className={'font-face-akb'} style={{fontWeight: 'bold', fontSize: '30px'}}>Overview</Typography>
            </Link>
            <Link onClick={() => props.showGalleryClick(true)} className={props.showGallery ? "galleryBackgroundChecked" : "galleryBackgroundNotChecked"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', right: '8%', padding: '1px', width: '9.5%', marginTop: '-10px', borderRadius: "3px", alignItems: "center", justifyContent: "center"}}>
              <Typography variant="body1" className={'font-face-akb'} style={{fontWeight: 'bold', fontSize: '30px'}}>Gallery</Typography>
            </Link>
            <Box item style={{position: 'absolute', right: '0%', display: 'flex', flexDirection: 'row', marginRight: "3%", marginTop: '-10px', height: "6%", cursor: 'pointer'}}>                          
              <img src={(heartSelected.indexOf(v.id) === -1) ? require(`../assets/img/icons/heartDeselectedPink.svg`) : require(`../assets/img/icons/heartSelected.svg`)} onClick={() => handleHeartClick(v.id)}></img>
              {/* <img src={(heartSelected.indexOf(v.id) === -1) ? require(`../assets/img/icons/heartDeselected.svg`) : require(`../assets/img/icons/heartSelected.svg`)} onClick={() => handleHeartClick(v.id)}></img> */}
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <StorageImg path={v.floorplan && v.floorplan[0] || undefined} style={{width: '100%', objectFit: 'contain'}} />
            <Box style={{position: "relative", width: "100%", display: 'flex', border: "3px solid #A65E45", borderRadius: "2px", alignItems: "center", justifyContent: "center"}}>
              <Typography variant="body1" className={'font-face-akr'} style={{color: "#A65E44", paddingTop: "0.5%", paddingBottom: "0.5%"}}>
                {privateShared === true ? "Shared Ownership" : "Private Sale"}
              {/* {(props.property.flags.sharedOwnership === true ? "true" : "false") || "no good"} */}
              </Typography>
            </Box>
            <Typography variant="h5" className={'font-face-akr'} gutterBottom style={{marginTop: "5%", color: 'rgb(57, 31, 57)', textAlign: 'left'}}>Key:</Typography>
            <Typography variant="body1" style={{marginBottom: '40px', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>
              {
                v.floorplanKey && Object.entries(v.floorplanKey).map(([label, value]) => (
                  <React.Fragment>
                    <span style={{fontWeight: 'bold'}}>{label}</span> - <span style={{fontWeight: 'bold'}}>{value}</span><span style={{fontWeight: 'bold'}}> | </span>
                  </React.Fragment>
                ))
              }
            </Typography>
              {/* <span style={{fontWeight: 'bold'}}>FF</span> - Fridge/Freezer | <span style={{fontWeight: 'bold'}}>HIU</span> - Heat Interface Unit | <span style={{fontWeight: 'bold'}}>W/D</span> - Washer/Dryer | <span style={{fontWeight: 'bold'}}>DW</span> - Dishwasher | <span style={{fontWeight: 'bold'}}>C</span> - Cupboard</Typography> */}
            <Typography variant="caption" className={'font-face-akr'} component="p" style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>The floor plans provided are intended to only give a general indication of the proposed floor layout, are not drawn to scale and are not intended to form part of any offer, contract, warranty or representation. Measurements are given to the widest point, are approximate and are given as a guide only. They must not be relied upon as a statement or representation of fact. Do not use these measurements for carpet sizes, appliance spaces or items of furniture. Kitchen, bathroom and utility layouts may differ to build, and specific plot sizes and layouts may vary within the same unit type. For further clarification regarding specific individual plots, please ask our Sales Associates.</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} style={{textAlign: 'left !important'}}>
            <Typography variant="h3"  className={'font-face-lb0'} style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{["One", "Two", "Three", "Four", "Five"][v.bedrooms - 1]} bedroom apartment</Typography>
            {/* <Typography variant="h3" style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}} gutterBottom>Type B</Typography> */}
            <Typography variant="h4" className={'font-face-akr'} gutterBottom style={{color: '#388189', marginBottom: '40px', fontWeight: 'bold', textAlign: 'left'}}>{v.name}</Typography>
            <Divider />
            {
              v.dimensions && Object.entries(v.dimensions).filter(([label]) => label !== 'Total Area').map(([label, {sizeInFeet, sizeInMeters}]) => (
                <React.Fragment>
                  <Grid container spacing={2} style={{padding: '10px 0px'}}>
                    <Grid item md={6}>
                      <Typography variant="body1" className={'font-face-akr'} style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{label}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="body1" className={'font-face-akr'} style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{`${sizeInFeet} / ${sizeInMeters}`}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))
            }
            {/*<Grid container spacing={2} style={{padding: '10px 0px'}}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>Living/Kitchen/Dining</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>6.68m x 4.17m / 21'11" x 13'8"</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{padding: '10px 0px'}}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>Bathroom</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>2.24m x 2.04m / 7'3" x 6'6"</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{padding: '10px 0px'}}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>Bedroom</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>4.68m x 2.74m / 15'14" x 8'11"</Typography>
              </Grid>
          </Grid>*/}
            {
              v.grossArea && (
                <React.Fragment>
                  <Divider />
                  <Grid container spacing={2} style={{padding: '10px 0px', marginBottom: '40px'}}>
                    <Grid item md={6}>
                      <Typography variant="body1" className={'font-face-akr'} style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>Total Area</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="body1" className={'font-face-akr'} style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{`${v.grossArea.sqFt} sq ft / ${v.grossArea.sqM} sq m`}</Typography>
                    </Grid>
                  </Grid>
                <Grid container spacing={2} style={{padding: '10px 0px'}}>
                  <Grid item md={6}>
                    <Typography variant="h5" className={'font-face-akr'} style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{privateShared === true ? "Share Value:" : "Full Market Value:" }</Typography>
                  </Grid>
                </Grid>
                </React.Fragment>
              )
            }
            {/* <Typography variant="h5" gutterBottom style={{marginBottom: '30px', color: 'rgb(57, 31, 57)', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{`Full market value: £${numberWithCommas(v.price)}`}</span></Typography> */}
            {
              v.otherPrices && Object.entries(v.otherPrices).map(([label, value]) => (
                <React.Fragment>
                  <Divider />
                  <Grid container spacing={2} style={{padding: '10px 0px'}}>
                    <Grid item md={6}>
                      <Typography variant="body1" className={'font-face-akr'} style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{label}</Typography>
                      {label === 'From (For 25% share)' && v.flags["sharedOwnership"] === true ?
                      <Typography variant="body1" className={'font-face-akr'} style={{fontWeight: 'bold', color: 'rgb(57, 31, 57)', textAlign: 'left'}}>Lease Term: 990 Years</Typography> : null}
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="body1" className={'font-face-akr'} style={{color: 'rgb(57, 31, 57)', textAlign: 'left'}}>{`£${numberWithCommas(value)}`}</Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))
            }
          </Grid>
        </Grid> : 
        <div>
          <Grid item xs={12} style={{padding: '20px', paddingTop: '0px', backrgroundColor: 'white', display: 'flex', flexDirection: 'row'}}>
            {/* <Link to="./" style={{marginTop: "-15px", display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'rgb(57, 31, 57)'}}>
            <div style={{
                    clipPath: "polygon(0 50%, 100% 0, 100% 100%)",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "black",
                    marginRight: '10px',
                    marginLeft: '30px'
                    
                  }} />
              <Typography variant="body1" style={{fontWeight: 'bold', fontSize: '30px'}}>Back</Typography>
            </Link> */}
            <Link onClick={() => props.showGalleryClick(false)} className={!props.showGallery ? "overBackgroundChecked" : "overBackgroundNotChecked"} style={{zIndex: "1000", display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', right: '18%', padding: '1px', paddingLeft: "3px", width: '9.5%', marginTop: '-10px', borderRadius: "3px", alignItems: "center", justifyContent: "center"}}>
              <Typography variant="body1" className={'font-face-akb'} style={{fontWeight: 'bold', fontSize: '30px'}}>Overview</Typography>
            </Link>
            <Link onClick={() => props.showGalleryClick(true)} className={props.showGallery ? "galleryBackgroundChecked" : "galleryBackgroundNotChecked"} style={{zIndex: "1000",display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', right: '8%', padding: '1px', width: '9.5%', marginTop: '-10px', borderRadius: "3px", alignItems: "center", justifyContent: "center"}}>
              <Typography variant="body1" className={'font-face-akb'} style={{fontWeight: 'bold', fontSize: '30px'}}>Gallery</Typography>
            </Link>
            <Box item style={{zIndex: "1000", position: 'absolute', right: '0%', display: 'flex', flexDirection: 'row', marginRight: "3%", marginTop: '-10px', height: "6%", cursor: 'pointer'}}>                          
              <img src={(heartSelected.indexOf(v.id) === -1) ? require(`../assets/img/icons/heartDeselectedPink.svg`) : require(`../assets/img/icons/heartSelected.svg`)} onClick={() => handleHeartClick(v.id)}></img>
              {/* <img src={(heartSelected.indexOf(v.id) === -1) ? require(`../assets/img/icons/heartDeselected.svg`) : require(`../assets/img/icons/heartSelected.svg`)} onClick={() => handleHeartClick(v.id)}></img> */}
            </Box>
          </Grid>
          <div
            style={{
               position: "fixed",
               top: "0",
               left: "0",
              width: "100%",
              height: "100%",
            }}
            id="carousel"
          >
            <Container style={{ margin: "0px", maxWidth: "100%", padding: "0px" }}>
              

              {/*{props.property.gallery !== undefined ?*/}
             

              {/*<ReactCarouselGalleryProperty slides={props.property.gallery} value={0} show={true} />
              <ReactCarouselGalleryProperty slides={props.galleries} value={0} show={true} />
              <ReactCarouselGalleryProperty slides={props.property.mainImage} value={0} show={true} />*/}
              <ReactCarouselGalleryProperty slides={privateShared === true ? imagesSharedOwnership : imagesPrivateSale} value={0} show={true} />
              : null
            </Container>
          </div>
        </div>
        }
      </Container>
    </Box>
  );
}

class ViewProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: [],
      showGallery: false
    }
  }

  componentDidMount() {
    fetch('https://us-central1-focus-digisuite.cloudfunctions.net/api/digisuites/digisuite-guinness-leasidelock/dev/properties')
      .then(res => res.json())
      .then(properties => {
        this.setState({
          properties
        })
      })
  }

  getProperty() {
    //console.log(this.state.properties[this.props.match.params.plotId || "property id not found"])
    return this.state.properties[this.props.match.params.plotId || ""] || {}
  }

  showGalleryClick = (showHide) => {
    this.setState({ showGallery: showHide })
  }

  render() {
    return (
      <ViewPropertiesRenderer
        property={this.getProperty()}
        showGallery={this.state.showGallery}
        showGalleryClick={this.showGalleryClick}
        //sharedOwnership={this.state.properties.flags.sharedOwnership}
      />
    );
  }
}

export default ViewProperty;