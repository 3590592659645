import * as actionTypes from '../actions/actionTypes';

const initialState = {
    aboutCatalyst: {
      background_image : '',
      side_image : '',
      logo : '',
      title : '',
      content : ''
    },
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_ABOUT_CATALYST:
            return {
                ...state,
                aboutCatalyst : action.aboutCatalyst,
                error: false
            };
        case actionTypes.FETCH_ABOUT_CATALYST_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;
