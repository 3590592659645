import * as actionTypes from '../actions/actionTypes';

const isTagVisible = (state, action) => {
    switch(action.type){
        case actionTypes.TAG_VISIBLE:
            return true
        case actionTypes.TAG_HIDDEN:
            return false
        default:
            return true
    }
}
export default isTagVisible