import React from "react";
import {
  Marker
} from "google-maps-react";

class CustomMarker extends Marker {
  state = {
    markerSet: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.updated === false) {
      return true;
    }

    return false;
  }

  render() {
    return (

      <Marker
        position={{ lat: 51.5260078, lng: -0.0110498 }}
        icon={this.props.icon}
        name={this.props.name}
      />
    );
  }
}

export default CustomMarker;
