import * as actionTypes from '../actions/actionTypes';

const initialState = {
    buyingScheme: {
      lead_icon : '',
      main_image : '',
      lead_text : '',
      items: [],
      footer_text : '',
    },
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_BUYING_SCHEME:
            return {
                ...state,
                buyingScheme : action.buyingScheme,
                error: false
            };
        case actionTypes.FETCH_BUYING_SCHEME_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;
