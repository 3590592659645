import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const fetchBlocks = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/apartment_blocks")
        .then((response) => {
            dispatch(dispatchedBlocks(response.data));
        })
        .catch((error) => {
            dispatch(fetchBlocksFailed());
        });
    }
};

export const fetchBlocksFailed = () => {
    return {
        type: actionTypes.FETCH_BLOCKS_FAILED
    };
}

export const dispatchedBlocks = (data) => {
    
    let blocks = data.map((p) => {
        return {
          id: p.id,
          title: p.acf.title,
          floors: p.acf.floors !== false ? p.acf.floors : []
        };
      });

    return {
        type: actionTypes.FETCH_BLOCKS,
        apartmentBlocks: blocks
    };
}
