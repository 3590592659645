import React from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import { ImageMapper as NewImageMapper } from "@focusdigisuite/components";
import { useParams } from "react-router-dom";

const RenderFloor = (props) => {
  const fillTypes = {
    active: {
      fill: 'rgba(0, 88, 112, 0.5)',
      hoverFill: 'rgb(0, 88, 112,0.8)'
    },
    available: {
      fill: 'rgba(56,129,137, 0.5)',
      hoverFill: 'rgb(56,129,137, 0.8)'
    },
    reserved: {
      fill: 'rgb(218,129,133, 0.5)',
      hoverFill: 'rgb(218,129,133, 0.8)'
    },
    "not-released": {
      fill: 'rgb(255,255,255, 0.9)',
      hoverFill: 'rgb(255,255,255, 0.8)'
    },
    "coming-soon": {
      fill: 'rgb(217, 217, 217, 0.5)',
      hoverFill: 'rgb(217, 217, 217, 0.8)'
    },
    "sold": {
      fill: 'rgb(217, 217, 217, 0.5)',
      hoverFill: 'rgb(217, 217, 217, 0.8)'
    }
  };

  const getFillType = (status = "coming-soon", type = "fill") => {
    return fillTypes[status || "coming-soon"][type];
  }

  // console.log(Object.values(props.properties || {}).map((v) => v.otherPolygons || {}), props.path);

  return (
    <Grid item sm={12} md={4} lg={4} xl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '30px'}}>
      <NewImageMapper
        src={props.src}
        key={props.key}
        map={{
          areas: [...Object.values(props.properties || {}).filter(v => v.path == props.path && v.flags.visible).map(v => ({
            _id: v.id,
            shape: "poly",
            coords: v.polygon.split(',').map(v => parseFloat(v)),
            href: props.toURI(v.id),
            fill: getFillType(v.status, "fill"),
            hoverFill: getFillType(v.status, "hoverFill")
          })), ...Object.values(props.properties || {}).filter(v => v.flags.visible && (Object.keys(v.otherPolygons || {}).indexOf(props.path) != -1)).map(v => ({
            _id: v.id,
            shape: "poly",
            coords: Object.entries(v.otherPolygons || {}).filter(([k]) => k == props.path).map(([k, v]) => v.split(',').map(v => parseFloat(v)))[0],
            href: props.toURI(v.id),
            fill: getFillType(v.status, "fill"),
            hoverFill: getFillType(v.status, "hoverFill")
          }))]
        }}
        width={405}
        height={270}
      />
      <Typography variant="h5" style={{ fontSize: '20px', color: 'rgb(0, 88, 112)', marginTop: '20px'}} className={'font-face-avr'}>
        {props.name}
      </Typography>
  </Grid>
  );
}


class ViewProperties extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: [],
      selectedBlock: ""
    }
  }

  componentDidMount() {

    var str = window.location.pathname;
    //var lastSlash = str.lastIndexOf("/");

    var blockName = str.replace("floors", "").replaceAll("/", "");

    //if (blockName === "") {
    //  lastSlash = str.substr(0, str.lastIndexOf("/",str.lastIndexOf("/")-1)); 
    //  blockName = str.substring(lastSlash + 1);
    //}

    this.setState({ selectedBlock: blockName })

    fetch('https://us-central1-focus-digisuite.cloudfunctions.net/api/digisuites/digisuite-guinness-leasidelock/dev/properties')
      .then(res => res.json())
      .then(properties => {
        this.setState({
          properties
        })
      });
  }

  render() {
    if (this.state.selectedBlock !== "")
    {
      return (
        <Box style={{padding: '30px', backgroundColor: 'white', minHeight: '100%'}}>
          <Container style={{width: '100%', minHeight: '100px', color: "#2B2B2C"}} maxWidth="xl">
            <Grid container>
              <Grid item sm={8} md={9} style={{marginBottom: '100px'}}>
                {this.state.selectedBlock === 'headwaterpointa' ?
                  <Grid container>
                      {
                        ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth"].map((v, i) => {
                          return (
                            <RenderFloor
                              key={i}
                              name={`${v.toUpperCase()} FLOOR`}
                              properties={this.state.properties}
                              path={`${this.state.selectedBlock}/${v}`}
                              toURI={(id) => `/floors/headwaterpointa/${id}`}
                              src={require(`../assets/img/floors/` + this.state.selectedBlock + `_${v.toUpperCase()}-FLOOR.jpg`)}
                            />
                          )
                        })
                      }
                    </Grid> : 
                    <Grid container>
                    {
                      ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth", "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth"].map((v, i) => {
                        return (
                          <RenderFloor
                            key={i}
                            name={`${v.toUpperCase()} FLOOR`}
                            properties={this.state.properties}
                            path={`${this.state.selectedBlock}/${v}`}
                            toURI={(id) => `/floors/headwaterpointb/${id}`}
                            src={require(`../assets/img/floors/` + this.state.selectedBlock + `_${v.toUpperCase()}-FLOOR.jpg`)}
                          />
                        )
                      })
                    }
                  </Grid>
                  }

                {/* <Grid container>
                  {
                    ["ground", "first", "second", "third", "fourth", "fifth", "sixth", "seventh"].map((v, i) => {
                      return (
                        <RenderFloor
                          key={i}
                          name={`${v[0].toUpperCase()}${v.substring(1)} Floor`}
                          properties={this.state.properties}
                          path={`${"bakers-yard"}/${v}`}
                          toURI={(id) => `/floors/bakers-yard/${id}`}
                          src={require(`../assets/img/floors/Floorplates_DA_V1-${v.toUpperCase()} FLOOR.png`)}
                        />
                      )
                    })
                  }
                </Grid> */}
              </Grid>
              <Grid item sm={4} md={3} style={{margin: '20px 0px', zIndex: 1}}>
                <Typography variant="h4" gutterBottom className={'font-face-lbi'} style={{ color: 'rgb(0, 88, 112)' }}>
                  {this.state.selectedBlock === 'headwaterpointa' ? 'HEADWATER POINT A' : this.state.selectedBlock === 'headwaterpointb' ? 'HEADWATER POINT B' : this.state.selectedBlock.toUpperCase()}
                </Typography>
    
                {this.state.selectedBlock === 'headwaterpointa' ?
                  <img src={require('../assets/img/floors/BlockALocator.png')} width='100%' style={{width: '100%'}} />
                  : <img src={require('../assets/img/floors/BlockBLocator.png')} width='100%' style={{width: '100%'}} />
                }
                <img src={require('../assets/img/northIndicator.jpg')} style={{ width: '60px', position: 'absolute', right: '70px'}} />
                
                <Grid container style={{display: 'flex', flexDirection: 'column', margin: '5px 0px 20px'}}>
                  <Grid item style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0px'}}>
                    <div style={{
                      //clipPath: "polygon(0% 0%, 65% 25%, 100% 80%, 0% 100%)",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#388189",
                      marginRight: '20px',
                      borderRadius: '50%', 
                      border: '1px solid #555555'
                    }} />
                    <Typography variant="body1" className={'font-face-avr'} style={{ color: '#555555', fontSize: '16px' }}>
                      Available
                    </Typography>
                  </Grid>
                  <Grid item style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0px'}}>
                    <div style={{
                      //clipPath: "polygon(0% 0%, 65% 25%, 100% 80%, 0% 100%)",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#da8185",
                      marginRight: '20px',
                      borderRadius: '50%', 
                      border: '1px solid #555555'
                    }} />
                    <Typography variant="body1" className={'font-face-avr'} style={{ color: '#555555', fontSize: '16px' }}>
                      Reserved
                    </Typography>
                  </Grid>
                  <Grid item style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0px'}}>
                    <div style={{
                      //clipPath: "polygon(0% 0%, 65% 25%, 100% 80%, 0% 100%)",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "white",
                      marginRight: '20px',
                      borderRadius: '50%', 
                      border: '1px solid #555555'
                    }} />
                    <Typography variant="body1" className={'font-face-avr'} style={{ color: '#555555', fontSize: '16px' }}>
                      Not released
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="caption" gutterBottom style={{display: "block", color: '#555555', fontSize: '10px'}} className={'font-face-avr'}>
                  The floor plans provided are intended to only give a general indication of the proposed floor layout, are not drawn to scale and are not intended to form part of any offer, contract, warranty or representation. Measurements are given to the widest point, are approximate and are given as a guide only. They must not be relied upon as a statement or representation of fact. Do not use these measurements for carpet sizes, appliance spaces or items of furniture. Kitchen, bathroom and utility layouts may differ to build, and specific plot sizes and layouts may vary within the same unit type. For further clarification regarding specific individual plots, please ask our Sales Associates.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      );
    } else {
      return null;
    }
    
  }
}

export default ViewProperties;