import * as actionTypes from '../actions/actionTypes';

const initialState = {
    aboutDevelopment: {
        aboutDevelopment: null,
        description1: null,
        description2: null,
        slides: []
    },
    developmentTimeline: [],
    localAreaGallery: [],
    interiorGallery: [],
    mainGallery: [],
    exteriorGallery: [],
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_DEVELOPMENT:
            return {
                ...state,
                aboutDevelopment : action.aboutDevelopment,
                developmentTimeline : action.developmentTimeline,
                localAreaGallery: action.localAreaGallery,
                interiorGallery: action.interiorGallery,
                mainGallery: action.mainGallery,
                exteriorGallery: action.exteriorGallery,
                error: false
            };
        case actionTypes.FETCH_DEVELOPMENT_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;



