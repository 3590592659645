import React from "react";
import { PlayArrow } from "@material-ui/icons";

//images={this.state.galleryItems} openCarousel

const sevenImageSection = (props) => {
  //console.log(props.images);
  return (
  <div className="row" style={{ padding: "0px", margin: "0px", width: "100%" }}>
    <div
      className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
      style={{ padding: "0px" }}
    >
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[0].id);
          }}
          src={typeof props.images[0] == "string" ? `https://img.youtube.com/vi/${props.images[0]}/maxres2.jpg` : props.images[0].src}
          style={{ width: "100%", height: "66vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[0] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[0].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[1].id);
          }}
          src={typeof props.images[1] == "string" ? `https://img.youtube.com/vi/${props.images[1]}/maxres2.jpg` : props.images[1].src}
          style={{ width: "100%", height: "34vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[1] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[1].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
    </div>
    <div
      className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
      style={{ padding: "0px" }}
    >
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[2].id);
          }}
          src={typeof props.images[2] == "string" ? `https://img.youtube.com/vi/${props.images[2]}/maxres2.jpg` : props.images[2].src}
          style={{ width: "100%", height: "33vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[2] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[2].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[3].id);
          }}
          src={typeof props.images[3] == "string" ? `https://img.youtube.com/vi/${props.images[3]}/maxres2.jpg` : props.images[3].src}
          style={{ width: "100%", height: "33vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[3] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[3].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[4].id);
          }}
          src={typeof props.images[4] == "string" ? `https://img.youtube.com/vi/${props.images[4]}/maxres2.jpg` : props.images[4].src}
          style={{ width: "100%", height: "34vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[4] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[4].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
    </div>
    <div
      className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
      style={{ padding: "0px" }}
    >
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[5].id);
          }}
          src={typeof props.images[5] == "string" ? `https://img.youtube.com/vi/${props.images[5]}/maxres2.jpg` : props.images[5].src}
          style={{ width: "100%", height: "33vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[5] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[5].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
      <div style={{ width: "100%", position: 'relative' }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[6].id);
          }}
          src={typeof props.images[6] == "string" ? `https://img.youtube.com/vi/${props.images[6]}/maxres2.jpg` : props.images[6].src}
          style={{ width: "100%", height: "67vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
        {typeof props.images[6] == "string" &&
          <div
            onClick={() => {
              props.openCarousel(props.images[6].id);
            }}
            style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
          >
            <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
          </div>
        }
      </div>
    </div>
  </div>
)};

export default sevenImageSection;
