import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

/// Generate breakpoints so we can use them in the theme definition ///
const breakpoints = createBreakpoints({});

let theme = createMuiTheme({
  breakpoints,
  palette: {
    text: {
      primary: 'black',
      secondary: 'white',
      thirdly: '#FFFFFF',
    },
    background: {
      primary: 'white',
      secondary: "#388189"
    },
    title: {
      fontFamily: "font-face-avr",
      fontSize: "min(5vw, 50px)",
      fontWeight: 'bold'
    }, 
    largeTitle: {
      fontFamily: "font-face-avr",
      fontSize: "min(5vw, 50px)",
      fontWeight: 'bold'
    },
    subTitle: {
      fontFamily: "font-face-avr",
      fontSize: "min(5vw, 50px)",
      fontWeight: 'bold'
    },
  }
});

theme = {
  ...theme,
  title: {
    fontFamily: "font-face-avr",
    fontSize: "min(5vw, 50px)",
    fontWeight: 'bold'
  }, 
  largeTitle: {
    fontFamily: "font-face-avr",
    fontSize: "min(5vw, 50px)",
    fontWeight: 'bold'
  },
  subTitle: {
    fontFamily: "font-face-avr",
    fontSize: "min(5vw, 50px)",
    fontWeight: 'bold'
  },
    /*Info pages introduction text selections*/
  //introductions: {
  //  fontFamily: 'AktivRegular',
  //  fontSize: "min(5vw, 50px)",
  //},


  textual: {
    title: {
      fontFamily: "font-face-avr",
      fontSize: "min(5vw, 50px)",
      fontWeight: 'bold'
    },
    subTitle: {
      fontFamily: "font-face-avr",
      fontSize: "min(8vw, 75px)"
    },
    intro: {
      fontFamily: "font-face-avr",
      fontSize: "min(2.5vw, 26px)",
      fontWeight: 'bold'
    },
    description: {
      fontFamily: "font-face-akl",
      fontSize: "min(2.5vw, 26px)",
    }
  },
  homepage: {
    container: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "center center",  
    
    },
    title: {
      fontSize: "min(6vw, 66px)",
      color: theme.palette.text.thirdly
    },
    subTitle: {
      fontSize: "min(8vw, 88px)",
      color: theme.palette.text.secondary
    }
  },
  slideImages: {
    left: {
      clipPath: `polygon(0% 0%, 100% 15%, 60% 85%, 0% 100%)`
    },
    right: {
      clipPath: `polygon(100% 0%, 0% 15%, 40% 85%, 100% 100%)`
    },
    half: {

    }, 
    smallScreen: {

    }
  },
  iconContainer: {
  
    
  },

  overrides: {},
};




export default theme;