import React from "react";
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import { Link } from "react-router-dom";
import ReactCarouselSmallCompare from '../../UI/ReactCarousel/ReactCarouselSmallCompare';
import ReactCarouselSmallFloorplan from '../../UI/ReactCarousel/ReactCarouselSmallFloorplan';
import { Box, Grid, Typography, Container, Divider } from "@material-ui/core";
import StorageImg from "components/StorageImg";

const compareProperty = (props) => {

  //console.log(props);

  let bathroomLabel = props.propData.bathrooms > 1 ? "bathrooms" : "bathroom";
  let bedroomLabel = props.propData.bedrooms > 1 ? "bedrooms" : "bedroom";

  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Aux>
    <div style={{ width: "100%" }}>

    <div style={{ position: "relative", marginBottom: "20px" }}>
        {props.propData.mainImage !== undefined ? 
        <ReactCarouselSmallCompare
          slides={props.propData.mainImage}
          style={{ height: '330px' }}
        />
        : null}
        <Grid item style={{position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'row', margin: "15px", padding: '1px 6px', backgroundColor: "white", color: '#388189', borderRadius: "3px"}}>
          <Typography variant="h6" className={'font-face-akb'} style={{textTransform: 'capitalize'}}>{props.propData.status}</Typography>
        </Grid>
        <Grid item style={{position: 'absolute', top: '0%', left: '86%', display: 'flex', flexDirection: 'row', margin: "4%", height: "11.5%", cursor: 'pointer'}}>                          
          <img src={(props.heartSelected.indexOf(props.propData.id) === -1) ? require(`../../../assets/img/icons/heartDeselected.svg`) : require(`../../../assets/img/icons/heartSelected.svg`)} onClick={() => props.handleHeartClick(props.propData.id)}></img>
        </Grid>
        {/* <div className="statusOverlay font-face-avr" style={{ textTransform: 'uppercase', color: 'rgb(56, 129, 137)', background: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>
          {props.propData.status}
        </div> */}
        <Grid item style={{postion: "relative", width: "100%", display: 'flex', flexDirection: 'row', border: "3px solid #A65E45", borderRadius: "2px", alignItems: "center", justifyContent: "center"}}>
          <Box style={{alignItems: "center", justify: "center"}}>
            <Typography variant="body1" className={'font-face-akr'} style={{color: "#A65E44", paddingTop: "2%", paddingBottom: "2%"}}>
              {props.propData.flags.sharedOwnership === true ? "Shared Ownership" : "Private Sale"}
            </Typography>
          </Box>
        </Grid>
        
        
        {/* <div style={{ textTransform: 'uppercase', color: '#d2ab9b', background: 'white', fontSize: '14px', margin: '15px 0px', textAlign: 'center', border: '2px solid #d2ab9b', padding: '8px' }} className={'font-face-avr'}>
          {props.propData.flags && props.propData.flags.sharedOwnership === true ? 
          'SHARED OWNERSHIP' : ''}
        </div> */}

      </div>

      <div style={{width: '100%', position: 'relative', padding: '0px 20px 10px 30px', borderRight: '1px rgba(255,255,255,.2) solid', float: 'left'}}>
        <Grid item md={12} style={{textAlign: 'left !important'}}>
            <Typography variant="h3" style={{color: 'black', textAlign: 'left', fontStyle: 'italic'}} className={'font-face-akr'}>{["One", "Two", "Three", "Four", "Five"][props.propData.bedrooms - 1]} bedroom apartment</Typography>
            {/* <Typography variant="h3" style={{color: '#ff8026', textAlign: 'left'}} gutterBottom>Type B</Typography> */}
            <Typography variant="h4" gutterBottom style={{color: 'rgb(56, 129, 137)', fontWeight: 'bold', textAlign: 'left'}} className={'font-face-lb0'}>{props.propData.name}</Typography>
            {/* <Typography variant="h5" gutterBottom style={{marginBottom: '30px', color: 'rgb(56, 129, 137)', textAlign: 'left'}}><span className={'font-face-akl'}>{`Full market value: £${numberWithCommas(props.propData.price)}`}</span></Typography> */}
            {
              props.propData.otherPrices && Object.entries(props.propData.otherPrices).map(([label, value]) => (
                <React.Fragment>
                  <Divider />
                  <Grid container spacing={2} style={{padding: '10px 0px'}}>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{fontWeight: 'bold', color: 'black', textAlign: 'left'}}  className={'font-face-lb0'}>{label}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{color: 'black', textAlign: 'left'}}  className={'font-face-akr'}>{`£${numberWithCommas(value)}`}</Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))
            }
            <Divider />
            {
              props.propData.dimensions && Object.entries(props.propData.dimensions).map(([label, {sizeInFeet, sizeInMeters}]) => (
                <React.Fragment>
                  <Grid container spacing={2} style={{padding: '10px 0px'}}>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{color: 'black', textAlign: 'left'}}  className={'font-face-akr'}>{label}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{color: 'black', textAlign: 'left'}}  className={'font-face-akr'}>{`${sizeInFeet} / ${sizeInFeet}`}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))
            }
            {/* <Grid container spacing={2} style={{padding: '10px 0px'}}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgb(85, 85, 85)', textAlign: 'left'}} className={'font-face-vb'}>Living/Kitchen/Dining</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{color: 'rgb(85, 85, 85)', textAlign: 'left'}} className={'font-face-v'}>6.68m x 4.17m / 21'11" x 13'8"</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{padding: '10px 0px'}}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgb(85, 85, 85)', textAlign: 'left'}} className={'font-face-vb'}>Bathroom</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{color: 'rgb(85, 85, 85)', textAlign: 'left'}} className={'font-face-v'}>2.24m x 2.04m / 7'3" x 6'6"</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{padding: '10px 0px'}}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgb(85, 85, 85)', textAlign: 'left'}} className={'font-face-vb'}>Bedroom</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{color: 'rgb(85, 85, 85)', textAlign: 'left'}} className={'font-face-v'}>4.68m x 2.74m / 15'14" x 8'11"</Typography>
              </Grid>
            </Grid> */}
            {
              props.propData.grossArea && (
                <React.Fragment>
                  <Divider />
                  <Grid container spacing={2} style={{padding: '10px 0px', marginBottom: '40px'}}>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{color: 'black', textAlign: 'left'}}  className={'font-face-akr'}>Total Area</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{color: 'black', textAlign: 'left'}}  className={'font-face-akr'}>{`${props.propData.grossArea.sqM} sq m  / ${props.propData.grossArea.sqFt} sq ft`}</Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            }
            <Typography variant="h5" gutterBottom style={{color: 'rgb(85, 85, 85)', textAlign: 'left'}}  className={'font-face-akr'}>Key:</Typography>
            <Typography variant="body1" style={{marginBottom: '40px', color: 'rgb(85, 85, 85)', textAlign: 'left'}}  className={'font-face-akr'}><span className={'font-face-akr'}>FF</span> - Fridge/Freezer | <span className={'font-face-akr'}>HIU</span> - Heat Interface Unit | <span className={'font-face-akr'}>W/D</span> - Washer/Dryer | <span className={'font-face-akr'}>DW</span> - Dishwasher | <span className={'font-face-akr'}>C</span> - Cupboard</Typography>
            <Typography variant="caption" component="p" style={{color: 'rgb(85, 85, 85)', textAlign: 'left'}}  className={'font-face-akr'}>The floor plans provided are intended to only give a general indication of the proposed floor layout, are not drawn to scale and are not intended to form part of any offer, contract, warranty or representation. Measurements are given to the widest point, are approximate and are given as a guide only. They must not be relied upon as a statement or representation of fact. Do not use these measurements for carpet sizes, appliance spaces or items of furniture. Kitchen, bathroom and utility layouts may differ to build, and specific plot sizes and layouts may vary within the same unit type. For further clarification regarding specific individual plots, please ask our Sales Associates.</Typography>
          </Grid>
        {/* <div style={{height: '35vh', marginBottom: '20px', border: '1px transparent solid'}}>
          <p
            style={{ color: "black", fontSize: "22px", margin: "20px 0 5px 0" }}
            className="bold-GalanoGrotesque"
          >
            {props.propData.name}
          </p>
          <p
            style={{ color: "black", fontSize: "22px", margin: props.propData.soPriceText !== "" ? "0px" : "0 0 20px 0" }}
            className="body-GalanoGrotesque"
          >
            {"From £" + numberWithCommas(props.propData.price)}
          </p>
          {props.propData.soPriceText !== "" ?
          <p style={{ color: "#18243F", fontSize: "18px", margin: "0 0 20px 0" }}>
            {props.propData.soPriceText}
          </p> : null}

          <div
            style={{ color: "black", fontSize: "14px", margin: "20px 0 0 0" }}
            className="body-GalanoGrotesque"
          >
            <img
              src={require("assets/img/icons/icon_blue_bedroom.png")}
              alt=""
              style={{ width: "25px", height: "auto", marginRight: "10px" }}
            />{" "}
            {props.propData.bedrooms} {bedroomLabel}
          </div>

          <div
            style={{ color: "black", fontSize: "14px", margin: "10px 0 20px 0" }}
            className="body-GalanoGrotesque"
          >
            <img
              src={require("assets/img/icons/icon_blue_bathroom.png")}
              alt=""
              style={{
                width: "25px",
                height: "auto",
                marginRight: "10px",
              }}
            />{" "}
            {props.propData.bathrooms} {bathroomLabel}
          </div>

          <div className="body-GalanoGrotesque" style={{
            color: "black",
            wordWrap: "normal",
            maxHeight: "170px",
            overflow: "auto",
            fontSize: "14px",
            marginTop: '0px'
          }}>
          {props.propData.description}
          </div>
        </div> */}
      </div>
      
      {/* <div style={{width: '50%', padding: '0px 20px 10px 30px', position: 'relative', float: 'left'}}>
        <div style={{height: '35vh', border: '1px transparent solid', marginBottom: '20px'}}>
          {props.propData.dimensions.length > 0 ? 
          props.propData.dimensions.map(room => {
          return (
            <div style={{width: '100%', padding: '14px 10px 30px 1px', marginTop: '30px', borderBottom: '1px #37596b solid', marginTop: '0px' }}>
              <p  className="bold-GalanoGrotesque" style={{color: '#f2eec4', fontSize: '13px', fontWeight: 'bold', textTransform: 'uppercase'}}>{room}</p>
              <div  className="body-GalanoGrotesque" style={{color: '#37596b', paddingBottom: '10px', float: 'left', position: 'relative', width: '170px', clear: 'none', fontSize: '13px'}}>{room.sizeInMeters}</div>
              <div  className="body-GalanoGrotesque" style={{color: '#37596b', marginBottom: '10px', float: 'left', position: 'relative', fontSize: '13px'}}>{room.sizeInFeet}</div>
            </div>
          )}) : null}
        </div>
      </div> */}

      <div style={{ clear: 'both' }}></div>  
    
      {/* <div className="compare-home-top" style={{ position: "relative", marginBottom: "20px", marginTop: "20px" }}>
        {props.propData.floorplans !== undefined ? 
        <ReactCarouselSmallFloorplan
                        slides={props.propData.floorplans}
                      />
                      : null}
      </div> */}

      <div style={{width: '50%', padding: '0px 20px 10px 30px', position: 'relative', margin: '0 auto', textAlign: 'center'}}>
        <Link
          to={"/find-your-new-home/" + props.propData.id}
          className="bold-GalanoGrotesque viewPropsButtonBrownCompare font-face-avr"
          style={{ color: "white", display: 'inline-block', background: 'rgb(218, 129, 133)', textTransform: 'none' }}
        >
          View home
        </Link>
      </div>

      {/* <div style={{width: '50%', padding: '0px 20px 10px 30px', position: 'relative', float: 'left'}}>
        <Link
          to="/register-interest"
          className="bold-GalanoGrotesque viewPropsButtonBlue"
          style={{ color: "#000", display: 'inline-block' }}
        >
          REGISTER INTEREST
        </Link>
      </div> */}

    </div>
    </Aux>
  );
};

export default compareProperty;

