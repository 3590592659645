import React, { Component } from "react";
import "./MapControls.css";
import { Box, Typography } from "@material-ui/core";
import { Close as CloseIcon, Check as TickIcon } from "@material-ui/icons";

class MapControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transportChecked: true,
      foodChecked: true,
      educationChecked: true,
      fitnessChecked: true,
      entertainmentChecked: true,
      shopsChecked: true,
      showControls: false,
    };

    this.handleTransportCheckboxChange = this.handleTransportCheckboxChange.bind(this);
    this.handleFoodCheckboxChange = this.handleFoodCheckboxChange.bind(this);
    this.handleEducationCheckboxChange = this.handleEducationCheckboxChange.bind(this);
    this.handleFitnessCheckboxChange = this.handleFitnessCheckboxChange.bind(this);
    this.handleEntertainmentCheckboxChange = this.handleEntertainmentCheckboxChange.bind(this);
    this.handleShopsCheckboxChange = this.handleShopsCheckboxChange.bind(this);
  }

  controlsSection = null;
  imgUrl = window.imageUrl;

  componentDidMount() {

    this.props.mounted();
  }

  handleTransportCheckboxChange = () => {
    this.setState({ transportChecked: !this.state.transportChecked }, () => {
      this.props.showTransport(this.state.transportChecked);
    });
  };
  handleFoodCheckboxChange = () => {
    this.setState({ foodChecked: !this.state.foodChecked }, () => {
      // this.props.showFood(this.state.foodChecked);
    });
  };
  handleEducationCheckboxChange = () => {
    this.setState({ educationChecked: !this.state.educationChecked }, () => {
      this.props.showEducation(this.state.educationChecked);
    });
  };
  handleFitnessCheckboxChange = () => {
    this.setState({ fitnessChecked: !this.state.fitnessChecked }, () => {
      this.props.showFitness(this.state.fitnessChecked);
    });
  };
  handleEntertainmentCheckboxChange = () => {
    this.setState({ entertainmentChecked: !this.state.entertainmentChecked }, () => {
      this.props.showEntertainment(this.state.entertainmentChecked);
    });
  };

  handleShopsCheckboxChange = () => {
    this.setState({ shopsChecked: !this.state.shopsChecked }, () => {
      this.props.showShops(this.state.shopsChecked);
    });
  };

  toggleMapControls = () => {
    this.setState({ showControls: !this.state.showControls });
  };

  resetFilters = () => {
    this.setState({
      transportChecked: true,
      foodChecked: true,
      educationChecked: true,
      fitnessChecked: true,
      entertainmentChecked: true,
      shopsChecked: true,
    });

    this.props.resetControls();
  };

  render() {
    const transportIcon = require('../icons/new/Transport.svg');
    const foodIcon = require('../icons/new/FoodDrink.svg');
    const educationIcon = require('../icons/new/Education.svg');
    const fitnessIcon = require('../icons/new/Gym.svg');
    const entertainmentIcon = require('../icons/new/Entertainment.svg');
    const shopsIcon = require('../icons/new/Shop.svg');

    return (
      <div style={{ position: "fixed", bottom: "0", left: "46px", zIndex: "200", overflow: "hidden", width: "350px" }} >
        <Box onClick={this.toggleMapControls} width="100%" padding={2} style={{backgroundColor: "#DA8185", color: '#481D3Fb'}} display="flex" flexDirection="row" alignItems="center">
          <svg id="Group_5425" data-name="Group 5425" xmlns="http://www.w3.org/2000/svg" width="22.069" height="22.069" viewBox="0 0 22.069 22.069">
            <g id="Group_79" data-name="Group 79" transform="translate(0 0)">
              <g id="Group_78" data-name="Group 78" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Path_71" data-name="Path 71" d="M306.158,285h-20.3a.86.86,0,0,0-.86.86,8.4,8.4,0,0,0,2.818,6.274l3.767,3.349a3.066,3.066,0,0,1,1.028,2.289v8.389a.861.861,0,0,0,1.337.716l5.075-3.384a.86.86,0,0,0,.383-.716v-5.005a3.066,3.066,0,0,1,1.028-2.289l3.767-3.349a8.4,8.4,0,0,0,2.818-6.274.86.86,0,0,0-.86-.86Zm-3.1,5.849L299.29,294.2a4.787,4.787,0,0,0-1.605,3.575v4.545l-3.355,2.237v-6.781a4.787,4.787,0,0,0-1.605-3.575l-3.767-3.349a6.682,6.682,0,0,1-2.184-4.128h18.469a6.68,6.68,0,0,1-2.184,4.128Zm0,0" transform="translate(-284.959 -285.211)" fill="white"/>
              </g>
            </g>
          </svg>

          <Typography variant="h5" gutterBottom style={{color: 'white', margin: '0px 15blpx', flexGrow: 1, textAlign: 'left', paddingLeft: "15px", paddingTop: "10px"}}>
            Filter
          </Typography>
          
          {this.state.showControls === true &&
            <CloseIcon
              style={{fill: "white"}}
              onClick={this.toggleMapControls}
            />
          }
        </Box>

        <div className={(this.state.showControls === true ? 'activeFilter' : 'inactiveFilter') + ' filterViewport'} style={{ backgroundColor: "#fff" }}>
          <div style={{padding: '10px'}}>
            <Box onClick={this.handleTransportCheckboxChange} width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{cursor: "pointer"}}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{backgroundColor: '#E9E9E9', width: '20px', height: '20px'}}>
                {this.state.transportChecked && <TickIcon />}
              </Box>
              <img
                className="actionContainer"
                src={transportIcon}
                style={{ height: "auto", width: "45px", margin: "0px 10px 0px 20px"}}
              />

              <Typography variant="body1" gutterBottom style={{color: 'black', margin: '0px 10px'}}>
                Transport
              </Typography>
            </Box>
            <Box onClick={this.handleFoodCheckboxChange} width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{cursor: "pointer"}}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{backgroundColor: '#E9E9E9', width: '20px', height: '20px'}}>
                {this.state.foodChecked && <TickIcon />}
              </Box>
              <img
                className="actionContainer"
                src={foodIcon}
                style={{ height: "auto", width: "45px", margin: "0px 10px 0px 20px"}}
              />

              <Typography variant="body1" gutterBottom style={{color: 'black', margin: '0px 10px'}}>
                Food &amp; drink
              </Typography>
            </Box>
            <Box onClick={this.handleEducationCheckboxChange} width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{cursor: "pointer"}}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{backgroundColor: '#E9E9E9', width: '20px', height: '20px'}}>
                {this.state.educationChecked && <TickIcon />}
              </Box>
              <img
                className="actionContainer"
                src={educationIcon}
                style={{ height: "auto", width: "45px", margin: "0px 10px 0px 20px"}}
              />

              <Typography variant="body1" gutterBottom style={{color: 'black', margin: '0px 10px'}}>
                Education
              </Typography>
            </Box>
            <Box onClick={this.handleFitnessCheckboxChange} width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{cursor: "pointer"}}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{backgroundColor: '#E9E9E9', width: '20px', height: '20px'}}>
                {this.state.fitnessChecked && <TickIcon />}
              </Box>
              <img
                className="actionContainer"
                src={fitnessIcon}
                style={{ height: "auto", width: "45px", margin: "0px 10px 0px 20px"}}
              />

              <Typography variant="body1" gutterBottom style={{color: 'black', margin: '0px 10px'}}>
                Fitness &amp; leisure
              </Typography>
            </Box>
            <Box onClick={this.handleEntertainmentCheckboxChange} width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{cursor: "pointer"}}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{backgroundColor: '#E9E9E9', width: '20px', height: '20px'}}>
                {this.state.entertainmentChecked && <TickIcon />}
              </Box>
              <img
                className="actionContainer"
                src={entertainmentIcon}
                style={{ height: "auto", width: "45px", margin: "0px 10px 0px 20px"}}
              />

              <Typography variant="body1" gutterBottom style={{color: 'black', margin: '0px 10px'}}>
                Entertainment
              </Typography>
            </Box>
            <Box onClick={this.handleShopsCheckboxChange} width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{cursor: "pointer"}}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{backgroundColor: '#E9E9E9', width: '20px', height: '20px'}}>
                {this.state.shopsChecked && <TickIcon />}
              </Box>
              <img
                className="actionContainer"
                src={shopsIcon}
                style={{ height: "auto", width: "45px", margin: "0px 10px 0px 20px"}}
              />

              <Typography variant="body1" gutterBottom style={{color: 'black', margin: '0px 10px'}}>
                Shop &amp; amenities
              </Typography>
            </Box>
            <Box width="100%" padding={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <Typography variant="h6" gutterBottom style={{color: 'black', margin: '0px 10px', cursor: 'pointer', textDecoration: "underline"}} onClick={this.resetFilters}>
                Reset Filters
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default MapControls;
