import React from "react";
import { useState, useEffect } from "react";
import { Box, Checkbox, Grid, Typography, Container} from "@material-ui/core";
import { FormControlLabel } from '@mui/material';
import { Check as CheckIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import StorageImg from "components/StorageImg";
import { values } from "lodash";
import _ from "lodash";
import CompareProperty from "../components/Property/CompareProperty/CompareProperty";
import Aux from "../hoc/Auxiliary/Auxiliary";
import { Row } from "reactstrap";
// import { PlotFilters } from "@focusdigisuite/components";
import PlotFilters from "../components/Property/PropertyFilters/PlotFilters";
import { propNames } from "ui-box";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    fontSize: "32px",
    color: "lightGrey",
    '&.Mui-checked': {
      color: "darkGrey",
    },
  },
}));


function ViewProperties(props) {
  const classes = useStyles();
  const [visibleProperties, setVisibleProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [heartSelected, setHeartSelected] = useState([]);
  const [propertyCompare, setPropertyCompare] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showCompare, setshowCompare] = useState(false)
  const [filterPlots, setfilterPlots] = useState("all")
  const [filterBedrooms, setfilterBedrooms] = useState("1")
  const [filterFloor, setfilterFloor] = useState("all")
  const [filterPrice, setfilterPrice] = useState(600000)
  const [filterSharedOwnership, setfilterSharedOwnership] = useState(true)
  const [filterPrivateSale, setfilterPrivateSale] = useState(true)
  const [results, setresults] = useState()
  

  useEffect(() => {
    if (properties !== undefined && Object.keys(properties)[0] !== undefined) {
    console.log("render inside filter")  

    const _plot = filterPlots,
          _beds = filterBedrooms,
          _floor = filterFloor,
          price = filterPrice,
          ownership = filterSharedOwnership,
          // availability = filterAvailability,
          pSale = filterPrivateSale;

    let unfiltered = []
    Object.keys(properties).map((k) => (
      unfiltered[k] = properties[k] 
    ))
    let filtered = unfiltered;
    let filtered1 = unfiltered;

    if (ownership === true && pSale === true) {
      console.log("1")
      filtered = [
        Object.values(unfiltered).filter((v) => v.flags.visible).filter(function (el) {
          return el
          // return el.flags.sharedOwnership === true || el.flags.helpToBuy === true;
        }),
      ];
    }

    if (ownership === true && pSale === false) {
      console.log("2")
      filtered = [
        Object.values(unfiltered).filter((v) => v.flags.visible).filter(function (el) {
          return el.flags.sharedOwnership === true;
        }),
      ];
    }
    
    if (ownership === false && pSale === true) {
      console.log("3")
      filtered = [
          Object.values(unfiltered).filter((v) => v.flags.visible).filter(function (el) {
          return el.flags.sharedOwnership === false;
          // return el.flags.helpToBuy === true;
        }),
      ];
    }
    
    if ((_plot === "all") && (_floor === "all")) {
        console.log("4")
        filtered1 = [
          ...filtered.map((el) => {return ( el.filter((v) => v.bedrooms >= Number(_beds)).filter((v) => v.price <= Number(price)))})
        ];
      }
      
    if ((_plot === "all") && (_floor !== "all")) {
      console.log("5")
        filtered1 = [
          ...filtered.map((el) => {
            return ( el.filter((v) => v.bedrooms >= Number(_beds)).filter((v) => ["ground", "first", "second", "third", "fourth", "fith", "sixth", "seventh", "eighth", "ninth", "tenth", "twelth", "thirteenth", "fourteenth", "fifteenth"].indexOf((v.path || "").split('/')[1]) === Number(_floor)).filter((v) => v.price <= Number(price)))

          })
        ];
      }
      
    if ((_plot !== "all") && (_floor === "all")) {
      console.log("6")
      //console.log(filtered);
      filtered1 = [
        // ...filtered.filter(function (el) {
          Object.values(filtered).filter(function (el) {
          //console.log(el.path.indexOf(_plot) > -1)
          //console.log("working plot not-all floor all")
          return (
            //el.path.indexOf(_plot) > -1 && 
            el.path.indexOf(_plot) > -1 && 
            //filtered1.count > 0 &&
            //el.plot === _plot &&
            //el.path.indexOf(_plot) > -1 &&
            //el.path._plot >= -1 &&
            el.bedrooms >= Number(_beds) &&
            ((Number(el.price) <= Number(price)))
            //el.path.indexOf(_plot) > -1 
            //el.houseType === _plot 
            
            );
          }),
        ];
      }
      
      
      if ((_plot !== "all") && (_floor !== "all")) {
        console.log("7")
        //console.log(filtered);
        filtered1 = [
          ...filtered.map((el) => {
            const floor2 = (["ground", "first", "second", "third", "fourth", "fith","sixth","seventh", "eighth","ninth", "tenth", "twelth", "thirteenth", "fourteenth", "fifteenth"].indexOf((el.path || "").split('/')[1]))
            return ( el.filter((v) => v.bedrooms >= Number(_beds)).filter((v) => floor2 === Number(_floor)).filter((v) => v.price <= Number(price)))
          })
          // ...filtered.filter(function (el) {
          // Object.values(filtered).filter(function (el) {
          //   const floor = (["ground", "first", "second", "third", "fourth", "fith","sixth","seventh", "eighth"].indexOf((el.path || "").split('/')[1]))
          //   //console.log(el.path.indexOf(_plot) > -1)
          //   //console.log("working plot not-all floor not-all")
          //   return (
          //     //el.path.indexOf(_plot) > -1 && 
          //     el.path.indexOf(_plot) > -1 && 
          //     //filtered1.count > 0 &&
          //     //el.plot === _plot &&
          //     //el.path.indexOf(_plot) > -1 &&
          //     //el.path._plot >= -1 &&
          //     el.bedrooms >= Number(_beds) && 
          //     floor === Number(_floor) &&
          //     ((Number(el.price) <= Number(price)))
              //el.path.indexOf(_plot) > -1 
              //el.houseType === _plot 
              
            //   );
            // }),
          ];
        }
        
          // filtered1 = [
            //   Object.values(filtered).filter((v) => v.flags.visible).filter(function (el) {
              //     return (
                //       //Number(el.bedrooms) >= Number(beds) &&
                //       //el.status === availability &&
                //       ((Number(el.price) <= Number(price))) //&&
                //       // ((Number(el.price) <= Number(price) && el.soPrice === "") || (Number(el.soPrice) <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) //&&
                //       // ((Number(el.price) >= Number(priceMin) && el.soPrice === "") || (Number(el.soPrice) >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) //&&
                //       //el.squareFootage > Number(area)
                //     );
                //   }),
                // ];
               // console.log("filtered1 ", filtered1)
  
                let items = filtered1;
                let itemsArray = {}
                  Object.values(items).map((vals) => (
                    Object.values(vals).map((k, index) => {
                      itemsArray[String(k["id"])] = properties[String(k["id"])]
                    })
                  ))
                setVisibleProperties(itemsArray)
                if (items.length === 0 ) {
                  setresults("No Results")
                } else {
                  setresults("")
                }
  }
},[filterBedrooms, filterFloor, filterPrice, filterSharedOwnership, filterPrivateSale])

  useEffect(() => {
    fetch('https://us-central1-focus-digisuite.cloudfunctions.net/api/digisuites/digisuite-guinness-leasidelock/dev/properties')
    .then(res => res.json())
    .then(propertiesCollected => {
      setVisibleProperties(propertiesCollected)
      setProperties(propertiesCollected)
      setHeartSelected(JSON.parse(localStorage.getItem('sessionHearts')))
    })  
  },[]);

  const getData = () => {
    // get data from localstorage
    const data = localStorage.getItem('sessionHearts')
    JSON.parse(data)
    return data
  }

  const handleHeartClick = (id) => {
    // Select / Deselect favourites with heart icons
    const data = localStorage.getItem('sessionHearts')
    JSON.parse(data)
    if (data.indexOf(id) == -1) {
      setHeartSelected([...heartSelected, id])
      localStorage.setItem('sessionHearts', JSON.stringify([...heartSelected, id]))
    } else {
      setHeartSelected(heartSelected.filter(v => v != id))
      localStorage.setItem('sessionHearts', JSON.stringify(heartSelected.filter(v => v != id)))
    }
  }
  
  const onSelect = (id) => {
    if (selected.indexOf(id) == -1) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter(v => v != id))
    }
  }
        
  const toggleCompareProperties = () => {
    setshowCompare(!showCompare);
  };
  

  const resetControlsHandler = () => {
    //let unfiltered = [...plotsUnfiltered.areas];
      setfilterPlots("all")
      setfilterBedrooms("1")
      setfilterFloor("all")
      setfilterPrice(600000)
      setfilterSharedOwnership(true)
      setfilterPrivateSale(true)
  };


  const selectedPlotChangedHandler = (ret) => {
    
      setfilterPlots(ret)
      setPropertyCompare([])
      // setMaxCompareSelected(false)
      // setCompareButtonActive(false)
    
  };

  const selectedBedroomsChangedHandler = (ret) => {
      setfilterBedrooms(ret)
      setPropertyCompare([])
      // setMaxCompareSelected(false)
      // setCompareButtonActive(false)
  };

  const selectedFloorChangedHandler = (ret) => {
      setfilterFloor(ret)
      setPropertyCompare([])
      // setMaxCompareSelected(false)
      // setCompareButtonActive(false)
  };

  const selectedPriceChangedHandler = (ret) => {
      setfilterPrice(ret)
      setPropertyCompare([])
      // setMaxCompareSelected(false)
      // setCompareButtonActive(false)
  };

  const sharedOwnershipChangedHandler = (ret) => {
      setfilterSharedOwnership(ret)
      setPropertyCompare([])
      // setMaxCompareSelected(false)
      // setCompareButtonActive(false)
  };

  const helpToBuyChangedHandler = (ret) => {
      setfilterPrivateSale(ret)
      setPropertyCompare([])
      // setMaxCompareSelected(false)
      // setCompareButtonActive(false)
  };
 
   if (showCompare) {
      return (
        <>
          <Aux>
            <div style={{ background: "#FEF9F2"}}>
              <div style={{
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
                <Grid item xs={12} style={{padding: '20px', backrgroundColor: '#FEF9F2', display: 'flex', flexDirection: 'row'}}>
                  <Link onClick={toggleCompareProperties} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'rgb(57, 31, 57)'}}>
                    <div style={{
                      clipPath: "polygon(0 50%, 100% 0, 100% 100%)",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "black",
                      marginRight: '10px',
                      marginLeft: '30px'
                      
                    }} />
                    <Typography variant="body1" className={'font-face-akb'} style={{fontWeight: 'bold', fontSize: '25px'}}> Back</Typography>
                  </Link>
                </Grid>
              </div>
              <span
                className="bold-GalanoGrotesque"
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  zIndex: "1",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "30px",
                  marginLeft: '472px'
                }}
              >           
              </span>
            </div>
            <Row style={{ margin: "0", background: '#FEF9F2' }}>
              <div className="compare-home-wrapper" >
                <div className="compare-home-inner" style={{overflow: "auto"}}>
                  <div className="compare-home-inner-left">
                    <CompareProperty heartSelected={heartSelected} setHeartSelected={setHeartSelected} handleHeartClick={handleHeartClick} propData={properties[selected[0]]} />
                  </div>
                </div>
                <div className="compare-home-inner" style={{overflow: "auto"}}>
                  <div className="compare-home-inner-right">
                    <CompareProperty heartSelected={heartSelected} setHeartSelected={setHeartSelected} handleHeartClick={handleHeartClick}  propData={properties[selected[1]]} />
                  </div>
                </div>
              </div>
            </Row>
          </Aux>
        </>
      );
    } else {
      return (
        <>
          <Box style={{marginTop: "15px", padding: '30px', backgroundColor: props.backgroundColor, minHeight: '100%'}}>
            <Container style={{width: '100%', minHeight: '100px', color: "#FEF9F2"}} maxWidth="xl">
              <Grid container spacing={4} style={{paddingBottom: '0px'}}>
              {(results === "No Results") ? 
                <div
                  style={{
                    position: "absolute",
                    top: "45vh",
                    width: "100vw",
                    textAlign: "center",
                    zIndex: "20000"
                  }}
                  >
                  <Typography variant="h2" style={{margin: "0 auto", textAlign: 'center', fontWeight: 'bold', fontSize: '30px', color: 'lightGrey'}} className={'font-face-bold'}>{results}</Typography>
                </div>
                :null     
              }
                {Object.entries(visibleProperties || {}).filter(([k, v]) => v.flags.visible).sort(([ka], [kb]) => {
                  if (parseInt(ka.match(/\d+/)[0]) <= parseInt(kb.match(/\d+/)[0]))
                    return -1;
                  if (parseInt(ka.match(/\d+/)[0]) > parseInt(kb.match(/\d+/)[0]))
                    return 1;
                  return 0;
                }).map(([k, v], i) => (
                  <Grid item xl={4} md={4} sm={6} xs={12} key={i}>
                    <Grid container >
                    <Grid item style={{width: '100%', position: 'relative', paddingBottom: '60%'}}>
                        <StorageImg path={v.mainImage} src={require('../assets/img/Room.PNG')} style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', top: 0, left: 0}} />
                        <Grid item style={{position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'row', margin: "15px", padding: '1px 6px', backgroundColor: "white", color: '#388189', borderRadius: "3px"}}>
                          <Typography variant="h6" className={'font-face-akb'} style={{textTransform: 'capitalize'}}>{v.status}</Typography>
                        </Grid>
                        <Grid item style={{position: 'absolute', top: '0%', left: '84%', display: 'flex', flexDirection: 'row', margin: "4%", height: "14.5%", cursor: 'pointer'}}>                          
                          <img src={(heartSelected.indexOf(v.id) === -1) ? require(`../assets/img/icons/heartDeselected.svg`) : require(`../assets/img/icons/heartSelected.svg`)} onClick={() => handleHeartClick(v.id)}></img>
                        </Grid>
                      </Grid>
                      <Grid item style={{postion: "relative", width: "100%", display: 'flex', flexDirection: 'row', border: "3px solid #A65E45", borderRadius: "2px", alignItems: "center", justifyContent: "center"}}>
                        <Box style={{alignItems: "center", justify: "center"}}>
                          <Typography variant="body1" className={'font-face-akr'} style={{color: "#A65E44", paddingTop: "2%", paddingBottom: "2%"}}>
                            {v.flags.sharedOwnership === true ? "Shared Ownership" : "Private Sale"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item style={{display: 'flex', flexDirection: 'column', padding: '10px 0px 5px'}}>
                        <Typography variant="h5" className={'font-face-akr'} style={{color: "#1D1D1B"}}>{v.name}</Typography>
                        <Typography variant="body1"  className={'font-face-akr'} style={{color: "#1D1D1B"}}>
                          From £{(v.price.toString().length > 3) ? (v.price.toString().slice(0, -3)) + (",") + (v.price.toString().slice(-3, v.price.toString().length)) : v.price}
                        </Typography>
                      </Grid>
                      <Grid item style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', padding: '10px 0px 10px', textAlign: 'center'}}>
                        <div>
                          <Typography variant="body1"  className={'font-face-akr'} style={{color: "#1D1D1B"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.221" height="13.39" viewBox="0 0 17.221 13.39" style={{marginRight: '7px', marginBottom: '4px'}}>
                              <g id="Group_150" data-name="Group 150" transform="translate(0 0)">
                                <path id="Path_90" data-name="Path 90" d="M364.8,397.932a1.512,1.512,0,0,0-.583-1.76v-3.658A1.515,1.515,0,0,0,362.7,391H350.52a1.515,1.515,0,0,0-1.514,1.513v3.658a1.512,1.512,0,0,0-.583,1.76H348v6.458h1.009v-1.544h15.2v1.544h1.009v-6.458Zm-14.278-5.923H362.7a.505.505,0,0,1,.5.5v3.4H362.2v-.5a1.515,1.515,0,0,0-1.514-1.514h-3.065a1.51,1.51,0,0,1-2.018,0h-3.065a1.515,1.515,0,0,0-1.514,1.514v.5h-1.007v-3.4a.505.505,0,0,1,.5-.5Zm5.586,3.9h-4.074v-.5a.505.505,0,0,1,.5-.5H355.6a.505.505,0,0,1,.5.5Zm1.009-.5a.505.505,0,0,1,.5-.5h3.065a.505.505,0,0,1,.5.5v.5h-4.074Zm-7.77,2.018a.505.505,0,0,1,.5-.5h13.521a.5.5,0,1,1,0,1.009H349.85a.505.505,0,0,1-.5-.5Zm-.336,1.514h15.2v2.9h-15.2Zm0,0" transform="translate(-348 -391)" fill="#2b2b2c"/>
                              </g>
                            </svg>
                            {v.bedrooms < 2 ? "1 Bedroom" : `${v.bedrooms} Bedrooms`}
                          </Typography>
                        </div>
                        <Typography variant="body1" className={'font-face-akr'} style={{color: "#1D1D1B"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18.75" height="17.12" viewBox="0 0 18.75 17.12" style={{marginRight: '7px', marginBottom: '4px'}}>
                            <g id="Group_151" data-name="Group 151" transform="translate(0 0)">
                              <path id="Path_91" data-name="Path 91" d="M18.343,28.783h-.41V23.891a1.63,1.63,0,1,0-3.261,0v.052a1.631,1.631,0,0,0-1.218,1.575v.41a.408.408,0,0,0,.408.408H16.3a.408.408,0,0,0,.408-.408v-.41a1.631,1.631,0,0,0-1.223-1.577v-.051a.815.815,0,1,1,1.63,0v4.891H.408A.408.408,0,0,0,0,29.19v3.251a5.316,5.316,0,0,0,4.9,5.294v1.238a.408.408,0,0,0,.408.408H6.94a.408.408,0,0,0,.408-.408V37.75h4.881v1.223a.408.408,0,0,0,.408.408h1.63a.408.408,0,0,0,.408-.408V37.605a5.318,5.318,0,0,0,4.076-5.164V29.19A.408.408,0,0,0,18.343,28.783Zm-2.448-3.263v0H14.269v0a.813.813,0,1,1,1.625,0ZM6.532,38.565H5.717V37.75h.815Zm7.327-.815v.815h-.815V37.75h.815Zm-.418-.815H5.309A4.5,4.5,0,0,1,.815,32.441V31.228h1.2a.408.408,0,0,0,0-.815H.815V29.6h17.12v.815H4.463a.408.408,0,0,0,0,.815H17.935v1.213A4.5,4.5,0,0,1,13.441,36.935Z" transform="translate(0 -22.261)" fill="#2b2b2c"/>
                              <path id="Path_92" data-name="Path 92" d="M379.25,145.919V145.1a.408.408,0,0,0-.815,0v.815a.408.408,0,1,0,.815,0Z" transform="translate(-364.576 -140.212)" fill="#2b2b2c"/>
                              <path id="Path_93" data-name="Path 93" d="M423.772,145.919V145.1a.408.408,0,1,0-.815,0v.815a.408.408,0,1,0,.815,0Z" transform="translate(-407.468 -140.212)" fill="#2b2b2c"/>
                              <path id="Path_94" data-name="Path 94" d="M77.765,244.87a.408.408,0,1,0,.288.119A.411.411,0,0,0,77.765,244.87Z" transform="translate(-74.524 -236.718)" fill="#2b2b2c"/>
                            </g>
                          </svg>
                          {v.bathrooms < 2 ? "1 Bathroom" : `${v.bathrooms} Bathrooms`}
                        </Typography>
                        <Typography variant="body1" className={'font-face-akr'} style={{color: "#1D1D1B"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="17.026" viewBox="0 0 20.826 17.026" style={{marginRight: '5px', marginBottom: '4px'}}>
                            <g id="Ladder-up" transform="translate(0.252 -5.73)">
                              <path id="Path_58541" data-name="Path 58541" d="M20.006,6.016H16.181a.318.318,0,0,0-.318.318V10.32H12.233a.318.318,0,0,0-.318.318v3.284H8.207a.318.318,0,0,0-.318.318v3.8H4.337a.318.318,0,0,0-.318.318v3.537H.316a.318.318,0,1,0,0,.636H4.337a.318.318,0,0,0,.318-.318V18.675H8.207a.318.318,0,0,0,.318-.318v-3.8h3.708a.318.318,0,0,0,.318-.318V10.957h3.629a.318.318,0,0,0,.318-.318V6.652h3.507a.318.318,0,1,0,0-.636Z" transform="translate(0 -0.025)" fill="#2b2b2c" stroke="#2b2b2c" stroke-width="0.5"/>
                              <path id="Path_58542" data-name="Path 58542" d="M7.342,11.7a.305.305,0,0,0,.217-.09l4.535-4.535v3.048a.329.329,0,1,0,.659,0V6.31a.329.329,0,0,0-.329-.33H8.612a.329.329,0,0,0,0,.659H11.66L7.125,11.174a.306.306,0,0,0,.217.523Z" transform="translate(-4.802 0)" fill="#2b2b2c" stroke="#2b2b2c" stroke-width="0.5"/>
                            </g>
                          </svg>
                          Floor {`${["ground", "first", "second", "third", "fourth", "fifth","sixth","seventh", "eighth", "ninth", "tenth", "eleventh", "twelveth", "thirteenth", "fourteenth", "fifteenth"].indexOf((v.path || "").split('/')[1])}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', paddingTop: '0px', paddingBottom: '5px', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Link to={`/find-your-new-home/${v.id}`}>
                          <Grid item style={{display: 'flex', cursor: 'pointer', padding: '5px 0px', marginRight: "0px", borderRadius: "3px", backgroundColor: '#DA8185', color: 'white', width: "280px", alignItems: 'center', justifyContent: "center"}} >
                            <Typography variant="body1" className={'font-face-akr'}>View plot details</Typography>
                          </Grid>
                        </Link>
                        <FormControlLabel
                          label={<Typography style={{color: "black"}}>Compare home</Typography>}
                          labelPlacement="end"
                          value="Parent"
                          name="Parent"
                          control={
                            <Checkbox className={classes.checkbox}
                            disabled={selected.indexOf(v.id) === -1 && selected.length >= 2}
                            checked={selected.indexOf(v.id) !== -1}
                            onChange={(event) => {
                              onSelect(v.id)
                            }}
                            color="success" 
                            id={`prop-check-${v.id}`}
                            name="Agreed"
                            value="Agreed"
                            />
                          }/>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                ))}
              </Grid>
            </Container>
            <Row style={{marginTop: "60px"}}>
                <div
                    style={{
                      position: "fixed",
                      bottom: "0px",
                      left: "30px",
                      width: "400px",
                    }}
                >
          <PlotFilters
          hideFloors={false}
          selectedPlot={selectedPlotChangedHandler}
          selectedBedrooms={selectedBedroomsChangedHandler}
          selectedFloor={selectedFloorChangedHandler}
          selectedPrice={selectedPriceChangedHandler}
          
          sharedOwnershipChecked={sharedOwnershipChangedHandler}
          privateSaleChecked={helpToBuyChangedHandler}
          // helpToBuyChecked={helpToBuyChangedHandler}
          price={filterPrice}
          sharedOwnership={filterSharedOwnership}
          privateSale={filterPrivateSale}
          resetControls={resetControlsHandler}
            />               
            </div>
            </Row>
            <Row>
                <div
                    style={{
                      position: "fixed",
                      bottom: "0px",
                      right: "30px",
                      width: "400px",
                    }}
                  >
                    <button
                        onClick={() => toggleCompareProperties()}
                        style={{ backgroundColor:(selected.length === 2 ? "#DA8185" : "rgb(165, 165, 165) "),borderRadius: "3px", width: "350px", position: 'absolute', margin: '0', color: 'white', height: 'auto', padding: '15px 0px', bottom: '0px', right: '0px', fontWeight: '800', fontSize: '20px', textAlign: 'center' }}
                        disabled={selected.length !== 2}
                        >
                        <img
                              src={require("assets/img/icons/compare.png")}
                              alt=""
                              style={{
                                width: "23px",
                                height: "auto",
                                marginRight: "20px",
                              }}
                              />
                            <img
                              src={require("assets/img/icons/compare.png")}
                              alt=""
                              style={{
                                width: "23px",
                                height: "auto",
                                marginRight: "20px",
                              }}
                              />
                             <span>Compare Homes</span>
                    </button>
                </div>
            </Row>
          </Box>
        </>
      );
    }
}

export default ViewProperties;