import React, { Component } from "react";
import "./PropertyFilters.css";

class PropertyFilters extends Component {
  state = {
    selectedPlot: "all",
    selectedBedrooms: "1",
    selectedFloor: "all",
    selectedPrice: 600000,
    sharedOwnershipChecked: true,
    privateSaleChecked: true,
    helpToBuyChecked: true,
    showControls: false,
  };

  controlsSection = null;

  componentDidMount() {
    this.setState({
      selectedPlot: this.props.selectedPlot,
      selectedBedrooms: this.props.selectedBedrooms,
      selectedFloor: this.props.selectedFloor,
    });
  }

  plotChangedHandler = (event) => {
    this.setState({ selectedPlot: event.target.value, showControls: false });
    this.props.selectedPlot(event.target.value);
  };

  bedroomsChangedHandler = (event) => {
    this.setState({ selectedBedrooms: event.target.value, showControls: false });
    this.props.selectedBedrooms(event.target.value);
  };

  floorChangedHandler = (event) => {
    this.setState({ selectedFloor: event.target.value, showControls: false  });
    this.props.selectedFloor(event.target.value);
  };

  priceChangedHandler = (event) => {
    this.setState({ selectedPrice: event.target.value, showControls: false  });
    this.props.selectedPrice(event.target.value);
  };

  // handleSharedOwnershipCheckboxChange = (event) => {
  //   this.setState({ sharedOwnershipChecked: event.target.checked });
  //   this.props.sharedOwnershipChecked(event.target.checked);
  // };

  // handleHelpToBuyCheckboxChange = (event) => {
  //   this.setState({ helpToBuyChecked: event.target.checked });
  //   this.props.helpToBuyChecked(event.target.checked);
  // };
  buyingSchemesChangedHandler = (event) => {
    if (event.target.value === "all") {
      this.setState({ sharedOwnershipChecked: true, showControls: false  });
      this.props.sharedOwnershipChecked(true);
      this.setState({ privateSaleChecked: true });
      this.props.privateSaleChecked(true);
      // this.setState({ helpToBuyChecked: false});
      // this.props.helpToBuyChecked(false);
    }
    if (event.target.value === "sharedOwnership") {
      this.setState({ sharedOwnershipChecked: true, showControls: false  });
      this.props.sharedOwnershipChecked(true);
      this.setState({ privateSaleChecked: false });
      this.props.privateSaleChecked(false);
      // this.setState({ helpToBuyChecked: false });
      // this.props.helpToBuyChecked(false);
    }
    if (event.target.value === "privateSale") {
      this.setState({ sharedOwnershipChecked: false, showControls: false  });
      this.props.sharedOwnershipChecked(false);
      this.setState({ privateSaleChecked: true });
      this.props.privateSaleChecked(true);
      // this.setState({ helpToBuyChecked: false });
      // this.props.helpToBuyChecked(false);
    }
    // if (event.target.value === "helpToBuy") {
    //   this.setState({ sharedOwnershipChecked: false });
    //   this.props.sharedOwnershipChecked(false);
    //   this.setState({ privateSaleChecked: false });
    //   this.props.privateSaleChecked(false);
    //   this.setState({ helpToBuyChecked: true });
    //   this.props.helpToBuyChecked(true);
    // }
  };
  
  toggleMapControls = (event) => {
    this.setState({ showControls: !this.state.showControls });
  };

  resetFilters = (event) => {
    this.setState({
      selectedPlot: "all",
      selectedBedrooms: "1",
      selectedFloor: "all",
      selectedPrice: 600000,
      sharedOwnershipChecked: false,
      privateSale: false,
      helpToBuyChecked: false,
      selectedBuyingScheme: "all",
      showControls: false 
    });

    this.props.resetControls();
    
  };

  render() {
    return (
      <div style={{ position: "fixed", bottom: "0", left: "2%", zIndex: "200", overflow: "hidden", width: "280px" }} >
        <div onClick={this.toggleMapControls} style={{marginBottom: "2px", backgroundColor: "#388189", width: "100%", fontSize: '12px', fontWeight: 'bold', padding: '20px', height: '75px', border: "10px", borderColor: "white"}}>
          <img
            className="actionContainer"
            onClick={this.toggleMapControls}
            src={require("assets/img/icons/icon_filter.svg")}
            alt=""
            style={{ width: "25px", height: "auto", marginRight: "10px", verticalAlign: "middle" }}
          />
          <span
            className="actionText"
            onClick={this.toggleMapControls}
            style={{ textTransform: "uppercase", fontSize: "18px", color: "#fff", marginLeft: "10px", verticalAlign: "middle" }}
          >
            <span className="font-face-title" style={{fontSize: "22px", fontWeight: "bold", textTransform: "none"}}>Filter</span>
          </span>

          {this.state.showControls === true &&
            <span
              onClick={this.toggleMapControls}
              style={{
                color: "#fff",
                position: "absolute",
                right: "25px",
                cursor: "pointer",
                top: "25px"
              }}
            >
              <img src={require("assets/img/filter-close.svg")} alt="" />
            </span>
          }
        </div>
        
        {/* <div className={(this.state.showControls === true ? 'activeFilter' : 'inactiveFilter') + ' filterPropertyViewport'} style={{backgroundColor: "#fff"}}>
          <div style={{ padding: '15px', margin: '0px'}}>
            <label style={{ color: "#18243F", fontSize: "20px", fontWeight: "bold", fontFamily: "HelveticaNeue, Arial, sans-serif" }}>
              Block
            </label>
            <select onChange={(event) => this.plotChangedHandler(event, "input_plot")}
              value={this.state.selectedPlot}
              id="input_plot"
              name="input_plot"
              className="form-control"
              style={{backgroundColor: "#EFEFEF"}}
            >
              <option key={"all"} value={"all"}>
                {"All"}
              </option>
              <option key={"marshwood"} value={"marshwood"}>
                {"Marshwood"}
              </option>
              <option key={"lace"} value={"lace"}>
                {"Lace"}
              </option>
            </select>
          </div>   
        </div> */}


        <div className={(this.state.showControls === true ? 'activeFilter' : 'inactiveFilter') + ' filterPropertyViewport'} style={{backgroundColor: "#fff", marginTop: "-3px"}}>
          <div className={(this.state.showControls === true ? 'activeFilter' : 'inactiveFilter') + ' filterPropertyViewport'} style={{backgroundColor: "#fff"}}>
            <div style={{padding: "5px", paddingLeft: '15px', paddingRight: '15px',  margin: '0px'}}>
              <label className="font-face-subTitle" style={{ color: "#18243F", fontSize: "14px"}}>
                MIN BEDROOMS
              </label>
              <select onChange={(event) => this.bedroomsChangedHandler(event, "input_bedrooms")}
                value={this.state.selectedBedrooms}
                id="input_bedrooms"
                name="input_bedrooms"
                className="form-control"
                style={{backgroundColor: "#EFEFEF"}}
              >
                <option key={"1"} value={"1"}>
                  {"1"}
                </option>
                <option key={"2"} value={"2"}>
                  {"2"}
                </option>
                {/* <option key={"3"} value={"3"}>
                  {"3"}
                </option> */}

              </select>
            </div>


          <div style={{padding: "5px", paddingLeft: '15px', paddingRight: '15px', margin: '0px'}}>
            <label className="font-face-subTitle" style={{ color: "#18243F", fontSize: "14px"}}>
              MAX PRICE
            </label>
            <select
              onChange={(event) =>
                this.priceChangedHandler(event, "input_price")
              }
              id="input_price"
              name="input_price"
              className="form-control"
              value={this.state.selectedPrice}
              style={{backgroundColor: "#EFEFEF"}}
            >
               <option key={"600000"} value={600000}>
                {"600,000"}
              </option>
              <option key={"300000"} value={300000}>
                {"300,000"}
              </option>
              <option key={"150000"} value={150000}>
                {"150,000"}
              </option>
              {/* {this.props.priceDropdpwnOptions.map((p) => (
                <option
                  key={p.id}
                  value={p.value}
                >
                  {p.display}
                </option>
              ))} */}
            </select>
          </div>

          {this.props.hideFloors !== true &&
          <div style={{padding: "5px", paddingLeft: '15px', paddingRight: '15px', margin: '0px'}}>
            <label className="font-face-subTitle" style={{ color: "#18243F", fontSize: "14px"}}>
              FLOOR
            </label>
            <select onChange={(event) => this.floorChangedHandler(event, "input_floor")}
              value={this.state.selectedFloor}
              id="input_floor"
              name="input_floor"
              className="form-control"
              style={{backgroundColor: "#EFEFEF"}}
            >
              <option key={"all"} value={"all"}>
                {"All"}
              </option>
              {/* <option key={"0"} value={"0"}>
                {"Ground"}
              </option> */}
              <option key={"1"} value={"1"}>
                {"First"}
              </option>
              <option key={"2"} value={"2"}>
                {"Second"}
              </option>
              <option key={"3"} value={"3"}>
                {"Third"}
              </option>
              <option key={"4"} value={"4"}>
                {"Fourth"}
              </option>
              <option key={"5"} value={"5"}>
                {"Fith"}
              </option>
              <option key={"6"} value={"6"}>
                {"Sixth"}
              </option>
              <option key={"7"} value={"7"}>
                {"Seventh"}
              </option>
              <option key={"8"} value={"8"}>
                {"Eighth"}
              </option>
              <option key={"9"} value={"9"}>
                {"Ninth"}
              </option>
              <option key={"10"} value={"10"}>
                {"Tenth"}
              </option>
              <option key={"11"} value={"11"}>
                {"Eleventh"}
              </option>
              <option key={"12"} value={"12"}>
                {"Twelth"}
              </option>
              <option key={"13"} value={"13"}>
                {"Thirteenth"}
              </option>
              <option key={"14"} value={"14"}>
                {"Fourteenth"}
              </option>
              <option key={"15"} value={"15"}>
                {"Fithteenth"}
              </option>
            </select>
          </div>
          }

          <div style={{padding: "5px", paddingLeft: '15px', paddingRight: '15px', margin: '0px'}}>
            <label className="font-face-subTitle" style={{ color: "#18243F", fontSize: "14px"}}>
              BUYING SCHEMES
            </label>
            <select onChange={(event) => this.buyingSchemesChangedHandler(event, "input_buying_scheme")}
              value={this.state.selectedBuyingScheme}
              id="input_floor"
              name="input_floor"
              className="form-control"
              style={{backgroundColor: "#EFEFEF"}}
            >
              <option key={"all"} value={"all"}>
                {"All"}
              </option>
              <option key={"sharedOwnershipChecked"} value={"sharedOwnership"}>
                {"Shared Ownership"}
              </option>
              <option key={"privateSaleChecked"} value={"privateSale"}>
                {"Private Sale"}
              </option>
              {/* <option key={"helpToBuyChecked"} value={"helpToBuy"}>
                {"Help To Buy"}
              </option> */}
            </select>
          </div>
        <div style={{padding: "5px", paddingLeft: '15px', paddingRight: '15px', margin: '0px 0px', textAlign: 'left'}}>
          <p
            className="actionText"
            onClick={this.resetFilters}
            style={{ textTransform: "uppercase", textDecoration: "underline", fontSize: "14px", fontWeight: 'bold' }}
          >
            <span className="font-face-subTitle" >Reset Filters</span>
          </p>
        </div>
        


        
        {/* <div style={{ padding: "20px 20px 0", margin: "0px" }}>
            <label
              className="body-GalanoGrotesque"
              style={{ color: "#A2D9F1", fontSize: "16px", marginBottom: '0px' }}
            >
              Available schemes
            </label>
            <span className="customcheck">
              <label
                className="customcheck body-GalanoGrotesque"
                style={{ marginLeft: "35px", fontSize: "16px", color: "#A2D9F1", marginBottom: '0px' }}
              >
                {" "}
                Shared Ownership
                <input
                  type="checkbox"
                  checked={this.state.sharedOwnershipChecked}
                  onChange={this.handleSharedOwnershipCheckboxChange}
                  className="customcheck"
                  name="entertainment"
                  value="yes"
                />
                <span className="customcheck-target"></span>
              </label>
            </span>
          </div> */}

          {/* <div style={{ padding: "0px 20px", margin: "-10px 0" }}>
            <span className="customcheck">
              <label
                className="customcheck body-GalanoGrotesque"
                style={{ marginLeft: "35px", fontSize: "16px", color: "#A2D9F1" }}
              >
                {" "}
                Help to Buy
                <input
                  type="checkbox"
                  checked={this.state.helpToBuyChecked}
                  onChange={this.handleHelpToBuyCheckboxChange}
                  className="customcheck"
                  name="shopping"
                  value="yes"
                />
                <span className="customcheck-target"></span>
              </label>
            </span>
          </div> */}

          </div>
        </div>
      </div>
    );
  }
}

export default PropertyFilters;
