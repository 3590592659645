import React from "react";
import { PlayArrow } from "@material-ui/icons";

const twoImageSection = (props) => (
  <div className="row" style={{ padding: "0px", margin: "0px", width: "100%" }}>
  <div
    className="col-md-12"
    style={{ padding: "0px" }}
  >
    <div style={{ width: "100%", position: 'relative' }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[0].id);
        }}
        src={typeof props.images[0] == "string" ? `https://img.youtube.com/vi/${props.images[0]}/maxres2.jpg` : props.images[0].src}
        loading="lazy" 
        style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
      {typeof props.images[0] == "string" &&
        <div
          onClick={() => {
            props.openCarousel(props.images[0].id);
          }}
          style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', cursor: 'pointer'}}
        >
          <PlayArrow style={{color: 'rgba(255,255,255,0.8)', fontSize: '75px'}} />
        </div>
      }
    </div>
  </div>
</div>
  );
  
  export default twoImageSection;
