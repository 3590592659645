import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const fetchDevelopment = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/developments/83")
        .then((response) => {
            dispatch(dispatchedDevelopment(response.data));
        })
        .catch((error) => {
            dispatch(fetchDevelopmentFailed());
        });
    }
};

export const fetchDevelopmentFailed = () => {
    return {
        type: actionTypes.FETCH_DEVELOPMENT_FAILED
    };
}

export const dispatchedDevelopment = (data) => {

    const imgUrl = window.imageUrl;

    let aboutDevelopment = {
        aboutDevelopment: null,
        description1: null,
        description2: null,
        slides: [],
        longitude: data.acf.longitude,
        latitude: data.acf.latitude
    }; 

    let developmentTimeline = [];
    let localAreaGallery = [];
    let interiorGallery = [];
    let mainGallery = [];
    let exteriorGallery = [];

    for (var property in data.acf) {

        if (data.acf.hasOwnProperty(property)) {
            var obj = data.acf[property];
            if (obj.timeline_image !== undefined) {
              var _timeline = {};
              _timeline.image = imgUrl + obj.timeline_image;
              _timeline.bannerText = obj.timeline_banner_text;
              _timeline.timelineDate = obj.timeline_date;
              _timeline.timelineLabel = obj.timeline_label;
              developmentTimeline.push(_timeline);
            }
          }

        if (property === 'timeline') {
          developmentTimeline = data.acf[property];
        }

        if (property === "local_area_gallery") {
          if (data.acf[property]) {
            localAreaGallery = data.acf[property].map((p, index) => {
                return {
                  src: imgUrl + p.url,
                  id: p.id,
                  caption: p.caption,
                  index: index,
                };
              });
          }
        }

        if (property === "interior_gallery") {
          if (data.acf[property]) {
            interiorGallery = data.acf[property].map((p, index) => {
                return {
                  src: imgUrl + p.url,
                  id: p.id,
                  caption: p.caption,
                  index: index,
                };
              });
          }
        }

        if (property === "main_gallery") {
          if (data.acf[property]) {
            mainGallery = data.acf[property].map((p, index) => {
                return {
                  src: imgUrl + p.url,
                  id: p.id,
                  caption: p.caption,
                  index: index,
                };
              });
          }
        }

        if (property === "exterior_gallery") {
          if (data.acf[property]) {
            exteriorGallery = data.acf[property].map((p, index) => {
                return {
                  src: imgUrl + p.url,
                  id: p.id,
                  caption: p.caption,
                  index: index,
                };
              });
          }
        }

        if (property === 'about_development') {
          aboutDevelopment.carousel = data.acf[property];
        }
    }

    return {
        type: actionTypes.FETCH_DEVELOPMENT,
        aboutDevelopment: aboutDevelopment,
        developmentTimeline: developmentTimeline,
        localAreaGallery: localAreaGallery,
        interiorGallery: interiorGallery,
        mainGallery: mainGallery,
        exteriorGallery: exteriorGallery
    };
};

