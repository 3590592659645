import React from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";

export default class SharedOwnership extends React.Component {
  render() {
    return (
      <Box style={{padding: '30px', minHeight: '100%'}}>
        <Container style={{width: '100%', minHeight: '100px', color: "#2B2B2C"}} maxWidth="xl">
          <Grid container>
            <Grid item sm={6} md={7} style={{padding: '20px 25px 20px 0px'}}>
              <Typography variant="h5" gutterBottom style={{fontWeight: 'bold', marginBottom: '50px'}}>
                Shared Ownership is a Government-backed scheme for first-time buyers who are unable to afford a suitable home at the full market price.*
              </Typography>
              <Typography variant="h4" gutterBottom>
              How it works:
              </Typography>
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="253.995" viewBox="0 0 555.234 253.995">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_45595" data-name="Path 45595" d="M62.848,76.079l-2.682,5.865-26.4,25.9V245.349H98.983V161.033h61.739v83.84h64.921L228.6,106.246l-33.016-31.34Z" transform="translate(-33.77 -74.906)" fill="none" stroke="#481d3f" stroke-width="1"/>
                  </clipPath>
                  <clipPath id="clip-path-2">
                    <rect id="Rectangle_5512" data-name="Rectangle 5512" width="555.234" height="253.995" fill="none"/>
                  </clipPath>
                </defs>
                <g id="Group_21252" data-name="Group 21252" transform="translate(-79.371 -427.102)">
                  <path id="Path_45538" data-name="Path 45538" d="M130,11.173,63.735,77.438H203.75L130,10.852" transform="translate(81.89 427.531)" fill="#481d3f"/>
                  <g id="Group_5409" data-name="Group 5409" transform="translate(114.476 504.969)">
                    <g id="Group_5408" data-name="Group 5408" clip-path="url(#clip-path)">
                      <line id="Line_1581" data-name="Line 1581" x1="259.753" y2="255.233" transform="translate(-158.127 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1582" data-name="Line 1582" x1="259.753" y2="255.233" transform="translate(-150.581 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1583" data-name="Line 1583" x1="259.753" y2="255.233" transform="translate(-143.035 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1584" data-name="Line 1584" x1="259.753" y2="255.233" transform="translate(-135.488 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1585" data-name="Line 1585" x1="259.753" y2="255.233" transform="translate(-127.942 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1586" data-name="Line 1586" x1="259.753" y2="255.233" transform="translate(-120.396 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1587" data-name="Line 1587" x1="259.753" y2="255.233" transform="translate(-112.849 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1588" data-name="Line 1588" x1="259.753" y2="255.233" transform="translate(-105.303 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1589" data-name="Line 1589" x1="259.753" y2="255.233" transform="translate(-97.756 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1590" data-name="Line 1590" x1="259.753" y2="255.233" transform="translate(-90.21 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1591" data-name="Line 1591" x1="259.753" y2="255.233" transform="translate(-82.664 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1592" data-name="Line 1592" x1="259.753" y2="255.233" transform="translate(-75.117 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1593" data-name="Line 1593" x1="259.753" y2="255.233" transform="translate(-67.571 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1594" data-name="Line 1594" x1="259.753" y2="255.233" transform="translate(-60.025 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1595" data-name="Line 1595" x1="259.753" y2="255.233" transform="translate(-52.478 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1596" data-name="Line 1596" x1="259.753" y2="255.233" transform="translate(-44.932 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1597" data-name="Line 1597" x1="259.753" y2="255.233" transform="translate(-37.385 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1598" data-name="Line 1598" x1="259.753" y2="255.233" transform="translate(-29.839 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1599" data-name="Line 1599" x1="259.753" y2="255.233" transform="translate(-22.293 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1600" data-name="Line 1600" x1="259.753" y2="255.233" transform="translate(-14.746 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1601" data-name="Line 1601" x1="259.753" y2="255.233" transform="translate(-7.2 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1602" data-name="Line 1602" x1="259.753" y2="255.233" transform="translate(0.346 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1603" data-name="Line 1603" x1="259.753" y2="255.233" transform="translate(7.893 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1604" data-name="Line 1604" x1="259.753" y2="255.233" transform="translate(15.439 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1605" data-name="Line 1605" x1="259.753" y2="255.233" transform="translate(22.986 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1606" data-name="Line 1606" x1="259.753" y2="255.233" transform="translate(30.532 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1607" data-name="Line 1607" x1="259.753" y2="255.233" transform="translate(38.078 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1608" data-name="Line 1608" x1="259.753" y2="255.233" transform="translate(45.625 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1609" data-name="Line 1609" x1="259.753" y2="255.233" transform="translate(53.171 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1610" data-name="Line 1610" x1="259.753" y2="255.233" transform="translate(60.717 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1611" data-name="Line 1611" x1="259.753" y2="255.233" transform="translate(68.264 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1612" data-name="Line 1612" x1="259.753" y2="255.233" transform="translate(75.81 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1613" data-name="Line 1613" x1="259.753" y2="255.233" transform="translate(83.357 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1614" data-name="Line 1614" x1="259.753" y2="255.233" transform="translate(90.903 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1615" data-name="Line 1615" x1="259.753" y2="255.233" transform="translate(98.449 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1616" data-name="Line 1616" x1="259.753" y2="255.233" transform="translate(105.996 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1617" data-name="Line 1617" x1="259.753" y2="255.233" transform="translate(113.542 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1618" data-name="Line 1618" x1="259.753" y2="255.233" transform="translate(121.088 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1619" data-name="Line 1619" x1="259.753" y2="255.233" transform="translate(128.635 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1620" data-name="Line 1620" x1="259.753" y2="255.233" transform="translate(136.181 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1621" data-name="Line 1621" x1="259.753" y2="255.233" transform="translate(143.728 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1622" data-name="Line 1622" x1="259.753" y2="255.233" transform="translate(151.274 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                      <line id="Line_1623" data-name="Line 1623" x1="259.753" y2="255.233" transform="translate(158.82 -63.07)" fill="none" stroke="#481d3f" stroke-width="0.975"/>
                    </g>
                  </g>
                  <g id="Group_5411" data-name="Group 5411" transform="translate(79.371 427.102)">
                    <g id="Group_5410" data-name="Group 5410" clip-path="url(#clip-path-2)">
                      <path id="Path_45596" data-name="Path 45596" d="M49.695,60.738a5.518,5.518,0,0,0,5.521-5.522V22.086h33.13a5.521,5.521,0,1,0,0-11.043H49.695a5.518,5.518,0,0,0-5.522,5.522V55.216a5.518,5.518,0,0,0,5.522,5.522m-44.174,77.3a5.506,5.506,0,0,0,3.9-1.618L132.519,13.329,255.612,136.424a5.521,5.521,0,0,0,7.808-7.808l-127-127a5.519,5.519,0,0,0-7.808,0l-127,127a5.522,5.522,0,0,0,3.9,9.426M38.651,254h66.26a5.519,5.519,0,0,0,5.522-5.522V176.692h44.173v71.781A5.518,5.518,0,0,0,160.128,254h66.259a5.519,5.519,0,0,0,5.522-5.522V138.041a5.522,5.522,0,1,0-11.044,0V242.952H165.649V171.171a5.519,5.519,0,0,0-5.522-5.522H104.911a5.518,5.518,0,0,0-5.522,5.522v71.781H44.172v-99.39a5.521,5.521,0,0,0-11.043,0V248.474A5.519,5.519,0,0,0,38.651,254" transform="translate(0 0)" fill="#fede1d"/>
                    </g>
                  </g>
                  <line id="Line_1624" data-name="Line 1624" x2="446.077" transform="translate(91.999 504.513)" fill="none" stroke="#481d3f" stroke-width="0.875" stroke-dasharray="3.489 3.489"/>
                  <line id="Line_1625" data-name="Line 1625" x2="446.077" transform="translate(91.999 671.213)" fill="none" stroke="#481d3f" stroke-width="0.875" stroke-dasharray="3.489 3.489"/>
                  <line id="Line_1626" data-name="Line 1626" y2="47.897" transform="translate(357.799 446.157)" fill="none" stroke="#481d3f" stroke-width="0.875"/>
                  <line id="Line_1627" data-name="Line 1627" y2="144.919" transform="translate(357.799 514.974)" fill="none" stroke="#481d3f" stroke-width="0.875"/>
                  <line id="Line_1628" data-name="Line 1628" x2="17.969" transform="translate(358.254 515.493)" fill="none" stroke="#481d3f" stroke-width="1"/>
                  <line id="Line_1629" data-name="Line 1629" x2="17.969" transform="translate(358.254 567.832)" fill="none" stroke="#481d3f" stroke-width="1"/>
                  <line id="Line_1630" data-name="Line 1630" x2="17.969" transform="translate(358.254 620.171)" fill="none" stroke="#481d3f" stroke-width="1"/>
                  <text id="Pay_rent_on_the_remaining_share" data-name="Pay rent on the
              remaining share" transform="translate(385 465.554)" fill="#481d3f" font-size="17" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Pay rent on the</tspan><tspan x="0" y="17">remaining share</tspan></text>
                  <text id="Buy_25_-75_of_a_property" data-name="Buy 25%-75%
              of a property" transform="translate(438 585.554)" fill="#481d3f" font-size="17" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Buy 25%-75%</tspan><tspan x="0" y="17">of a property</tspan></text>
                  <text id="_25_" data-name="25%" transform="translate(385 623.554)" fill="#481d3f" font-size="12" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">25%</tspan></text>
                  <text id="_50_" data-name="50%" transform="translate(385 571.554)" Wfill="#481d3f" font-size="12" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">50%</tspan></text>
                  <text id="_75_" data-name="75%" transform="translate(385 520.554)" fill="#481d3f" font-size="12" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">75%</tspan></text>
                </g>
              </svg>
            </Grid>
            <Grid item sm={6} md={5} style={{padding: '20px 0px 20px 25px', display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <Typography variant="h4" gutterBottom>
                FAQs:
              </Typography>
              <Typography variant="h5" gutterBottom style={{ borderWidth: '0px', borderBottomWidth: '5px', borderStyle: 'solid', borderColor: 'rgb(254, 222, 29)'}}>
                How it works:
              </Typography>
              <Typography variant="body1" gutterBottom>
                You initially purchase between 25% to 75% of the home’s market value. You then pay subsidised rent on the remainder calculated at 2.75% per annum. 
              </Typography>
              <Typography variant="h5" gutterBottom style={{ borderWidth: '0px', borderBottomWidth: '5px', borderStyle: 'solid', borderColor: 'rgb(254, 222, 29)'}}>
                Am I eligible?
              </Typography>
              <Typography variant="body1" gutterBottom>
                To be considered eligible for the Shared Ownership scheme your yearly income must be below £80,000. For London the limit is £90,000.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Those who already own a home are not eligible for the scheme. You would need to sell your current home to be considered. 
              </Typography>
              <Typography variant="h5" gutterBottom  style={{ borderWidth: '0px', borderBottomWidth: '5px', borderStyle: 'solid', borderColor: 'rgb(254, 222, 29)'}}>
                Will I need to pay a deposit?
              </Typography>
              <Typography variant="body1" gutterBottom>
                In terms of a deposit you will need 5% of the percentage of the home you’re purchasing.
              </Typography>
              <Typography variant="body1" gutterBottom>
                If the property’s market value is £200,000 and you’re buying 35% then your proportion will be £70,000. This means you’ll need a deposit of £3,500 alongside a mortgage of £66,500.
              </Typography>
            </Grid>
            <Grid item sm={12} style={{margin: '20px 0px'}}>
              <Typography variant="caption" gutterBottom>
              *Shared Ownership – Terms and conditions apply. This scheme with [Example Housing Association] is subject to status and fitting criteria. Minimum and maximum share values will apply and rent is payable on the unsold share. This scheme cannot be used in conjunction with any other offer and is available on selected plots only. Please speak to a member of our Sales Team for more details.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}