import React from "react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./ReactCarouselGallery.css"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class ReactCarouselGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onchange = this.onchange.bind(this);
  }

  onchange(value) {
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {}

  componentWillMount() {}

  render() {
    return (
        <div
          style={{
            marginTop: '130px',
            height: `calc(100vh - 130px)`,
            border: '0px #2f234e solid'
          }}
        >
        <Carousel
          animationSpeed={1000} 
          centered
          slidesPerPage={1} 
          value={this.state.value}
          onChange={this.onchange}
          infinite
          arrowLeft={<div style={{ position: "absolute", right: "135px", bottom: "50px", zIndex: "6000", border: '2px', borderColor: "#DA8185", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", borderRadius: "50%", height: "60px", width: "60px", justifyContent: "center", alignItems: "center"}} ><ChevronLeftIcon fontSize='large' style={{color: '#FEF9F2', marginRight: '1px'}} /></div>}
          arrowLeftDisabled={<div style={{ position: "absolute", right: "135px", bottom: "50px", zIndex: "6000" ,  border: '2px', borderColor: "#DA8185", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", borderRadius: "50%", height: "60px", width: "60px", justifyContent: "center", alignItems: "center"}}><ChevronLeftIcon fontSize='large' style={{color: '#FEF9F2', marginRight: '1px'}} /></div>}
          arrowRight={<div style={{ position: "absolute", right: "50px", bottom: "50px", zIndex: "6000", border: '2px', borderColor: "#DA8185", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", borderRadius: "50%", height: "60px", width: "60px", justifyContent: "center", alignItems: "center"}}><ChevronRightIcon fontSize='large' style={{color: '#FEF9F2', marginLeft: '1px'}} /></div>}
          arrowRightDisabled={<div style={{ position: "absolute", right: "50px", bottom: "50px", zIndex: "6000", border: '2px', borderColor: "#DA8185", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", borderRadius: "50%", height: "60px", width: "60px", justifyContent: "center", alignItems: "center"}}><ChevronRightIcon fontSize='large' style={{color: '#FEF9F2', marginLeft: '1px'}} /></div>}
          keepDirectionWhenDragging
          addArrowClickHandler
          style={{
            height: `calc(100vh - 130px)`
          }}
        >
          {this.props.slides.map((item, i) => {
            if (typeof item == "string") {
              return (
                <div key={item.id} style={{ height: `calc(100vh - 130px)`}}>
                  <div
                    className="galleryImage"
                    style={{
                      position: 'relative',
                      height: `calc(100vh - 130px)`,
                      borderRadius: "0px",
                      width: "100vw",
                      background: `rgba(0,0,0,0.7)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: '0px'
                      // borderLeft: '20px #362b54 solid',
                      // borderRight: '20px #362b54 solid'
                    }}
                  >
                    <iframe
                      key={this.state.value + " " + this.props.show} 
                      src={`https://www.youtube.com/embed/${item}?autohide=1&showinfo=0&enablejsapi=1&widgetid=3&modestbranding=1`}
                      frameborder="0"
                      style={{
                        width: "80%",
                        height: "67%",
                        border: "none"
                      }}
                    />
                  </div>
                </div>
              );
            }

            return (
              <div key={item.id}>
                <img className="galleryImage"
                  style={{
                    height: "100vh",
                    borderRadius: "0px",
                    objectFit: "contain",
                    width: "100vw",
                    background: `rgba(0,0,0,0.7)`
                    // borderLeft: '20px #362b54 solid',
                    // borderRight: '20px #362b54 solid'
                  }}
                  src={item.src}
                  alt={item.altText}
                />
                {/* <div className="imageTextOverlay">
                  {item.caption}
                </div> */}
              </div>
            );
          })}
        </Carousel>
        {/* <div
          style={{
            position: "absolute",
            bottom: "50px",
            width: "100%",
          }}
        >
          <Dots
            value={this.state.value}
            onChange={this.onchange} 
            number={this.props.slides.length}
          />
        </div> */}
      </div>
    );
  }
}
export default ReactCarouselGallery;
