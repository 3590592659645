import React, { Fragment, Component } from "react";
import SevenImageSection from "./GalleryPageSections/SevenImageSection";
import SixImageSection from "./GalleryPageSections/SixImageSection";
import FiveImageSection from "./GalleryPageSections/FiveImageSection";
import FourImageSection from "./GalleryPageSections/FourImageSection";
import ThreeImageSection from "./GalleryPageSections/ThreeImageSection";
import TwoImageSection from "./GalleryPageSections/TwoImageSection";
import OneImageSection from "./GalleryPageSections/OneImageSection";
import {connect} from 'react-redux';
import ReactCarouselGallery from "../components/UI/ReactCarousel/ReactCarouselGallery";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

class GalleryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      animating: false,
      showCarousel: false,
      imagePosition: 0
    };
  }

  gallerySection = null;

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }

  onExiting = () => {
    this.setState({ animating: true });
  };
  onExited = () => {
    this.setState({ animating: false });
  };
  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.galleryItems.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.galleryItems.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  closeCarousel = () => {
    this.setState({ activeIndex: -1, showCarousel: false });
  };

  openCarousel = (index, imagePosition) => {
    this.setState({ activeIndex: index, showCarousel: true, imagePosition: imagePosition });
  };

  render() {
    this.gallerySection = [];

    //console.log(this.props.galleryItems.length)

    if (this.props.galleryItems.length > 0) {
      const maxSize = 7;
      const sections = Math.ceil(this.props.galleryItems.length / maxSize);

      let i;
      for (i = 0; i < sections; i++) {
        const temparray = this.props.galleryItems.slice(i * maxSize, (i + 1) * maxSize);

        switch (temparray.length) {
          case 1:
            this.gallerySection.push(
              <OneImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          case 2:
            this.gallerySection.push(
              <TwoImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          case 3:
            this.gallerySection.push(
              <ThreeImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          case 4:
            this.gallerySection.push(
              <FourImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          case 5:
            this.gallerySection.push(
              <FiveImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          case 6:
            this.gallerySection.push(
              <SixImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          case 7:
            this.gallerySection.push(
              <SevenImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
          default:
            this.gallerySection.push(
              <SevenImageSection
                images={temparray}
                openCarousel={this.openCarousel}
              />
            );
            break;
        }
      }
    }

    return (
      <Fragment> 
        <div style={{ margin: "0px", height: "calc(100vh - 130px)", width: "100%", position: 'relative'}}>
          {/* this.state.showCarousel ? null :  */}
          
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              overflow: "auto",
              marginTop: "130px"
            }}
            id="carousel"
          >
            <Container style={{ margin: "0px", maxWidth: "100%" }}>
              <Row>
                <Col
                  className="ml-auto mr-auto"
                  style={{ padding: "0px" }}
                >
                  <Card
                    className="page-carousel"
                    style={{ height: "100%", marginBottom: "0px" }}
                  >
                  {this.gallerySection}
                  
                  
                    {/* <Carousel
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                      style={{ height: "100%" }}
                    >
                      <CarouselIndicators
                        items={this.props.galleryItems}
                        activeIndex={this.state.activeIndex}
                        onClickHandler={this.goToIndex}
                      />
                      {this.props.galleryItems.map((item) => {
                        return (
                          <CarouselItem
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={item.id}
                          >
                            {this.gallerySection}
                          </CarouselItem>
                        );
                      })}

                      <a
                        className="left carousel-control carousel-control-prev"
                        data-slide="prev"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          this.previous();
                        }}
                        role="button"
                      >
                        <img src={require("assets/img/icons/arrow-left.png")} alt="" />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="right carousel-control carousel-control-next"
                        data-slide="next"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          this.next();
                        }}
                        role="button"
                      >
                        <img src={require("assets/img/icons/arrow-right.png")} alt="" />
                        <span className="sr-only">Next</span>
                      </a>
                    </Carousel> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className={(this.state.showCarousel === true ? 'activeGallery' : 'inactiveGallery') + ' overlayGallery'}>
          <div
            onClick={this.closeCarousel}
            style={{
              position: "absolute",
              top: "130px",
              right: "30px",
              cursor: "pointer",
              color: "white",
              zIndex: "1000",
              marginTop: '20px',
            }}
          >
            <img src={require("assets/img/filter-close.svg")} style={{width: '35px'}} alt="" />
          </div>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
            id="carousel"
          >
            <Container style={{ margin: "0px", maxWidth: "100%", padding: "0px" }}>
            <ReactCarouselGallery slides={this.props.galleryItems} value={this.state.imagePosition} show={this.state.showCarousel} />
              {/* <Row>
                <Col
                  className="page-header ml-auto mr-auto"
                  style={{ padding: "0px" }}
                >
                  <Card
                    className="page-carousel"
                    style={{ height: "100%", marginBottom: "0px" }}
                  >
                    {this.props.galleryItems.find(x => x.id === this.state.activeIndex) && 
                      <div>
                          <img
                            style={{
                              height: "100vh",
                              width: "100%",
                              borderRadius: "0px",
                              objectFit: "cover"
                            }}
                            src={this.props.galleryItems.find(x => x.id === this.state.activeIndex).src}
                            alt={this.props.galleryItems.find(x => x.id === this.state.activeIndex).altText}
                          />
                          <span className="body-GalanoGrotesque">{this.props.galleryItems.find(x => x.id === this.state.activeIndex).caption}</span>
                      </div>
                    }
                  </Card>
                </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GalleryPage;
