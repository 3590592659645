import React from "react";
import Theme from "../theme/index";
import { Route } from "react-router";
import LiveNavBar from "components/Navbars/LiveNavBar";
import { storage } from "firebase";
import { ref, getDownloadURL  } from "firebase/storage";
import GalleryPage from "views/GalleryPage";

export class HandleGallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      youtubeVideos: [],
      images: []
    }
  }

  async componentDidMount() {
    let images = [];
    for (let path of this.props.images) {
      const pathReference = ref(storage, path);
      images.push({src: await getDownloadURL(pathReference)})
    }
    this.setState({
      images: images,
      youtubeVideos: this.props.youtubeVideos
    })
  }

  render() {
    return (
      <React.Fragment>
        <LiveNavBar items={this.props.navBarItems} title={this.props.name} />
        <div style={{height: 'calc(100vh - 128px)'}}>
          <GalleryPage
            galleryItems={[...this.state.youtubeVideos, ...this.state.images]}
          />
        </div>
      </React.Fragment>
    );
  }
}


const HandleGalleries = (props) => {
  return Object.keys(props.galleries || {}).map((k, i) => {
    return <HandleGallery key={i} navBarItems={props.navBarItems} {...props.galleries[k]} />;
  })
}

export default HandleGalleries;
