import React, { Component } from "react";
import {
  Map,
  GoogleApiWrapper,
  InfoWindow,
} from "google-maps-react";
import googleMapStyles from "./mapStyles";
import MapControls from "./MapControls/MapControls";
import CustomMarker from "./CustomMarker";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import './LocationMap.css'

export class LocationMap extends Component {  
  mapOptionsCreator(map) {
    return {
      scrollwheel: false,
      zoomControlOptions: {
        position: map.ControlPosition.RIGHT_CENTER, // as long as this is not set it works
        style: map.ZoomControlStyle.SMALL,
      },
      draggable: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      panControl: false,
    };
  }

  state = {
    showTransport: true,
    showGym: true,
    showEducation: true,
    showFitness: true,
    showEntertainment: true,
    showShops: true,
    transportPlaces: [],
    gymPlaces: [],
    educationPlaces: [],
    fitnessPlaces: [],
    entertainmentPlaces: [],
    shopsPlaces: [],
    selectedPlace: "",
    activeMarker: null,
    showingInfoWindow: false,
    imageIcons: [],
    markersSet: false,
    directions: null
  };

  transportMarkers = null;
  foodMarkers = null;
  educationMarkers = null;
  fitnessMarkers = null;
  entertainmentMarkers = null;
  shopsMarkers = null;
  mapControls = null;
  locationMarker = null;

  imgUrl = window.imageUrl;

  get locationCoordinates() {
    return { lat: this.props.latitude , lng: this.props.longitude };
  }

  componentDidMount() {
    // this.setState({ updated: true });

    // this.setState({ imageIcons: this.props.imageIcons });

    // axios
    //   .get("/wp-json/wp/v2/development_styles/209?_embed")
    //   .then((response) => {
    //     this.setState({ imageIcons: response.data.acf.location_images });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }


  setMarkersrendered = () => {
    if (this.state.markersSet === false) {
      this.setState({ markersSet: true });
    }
  };

  transportChangedHandler = (checkStatus) => {
    this.setState({ showTransport: checkStatus });
  };

  foodChangedHandler = (checkStatus) => {
    this.setState({ showGym: checkStatus });
  };

  educationChangedHandler = (checkStatus) => {
    this.setState({ showEducation: checkStatus });
  };

  fitnessChangedHandler = (checkStatus) => {
    this.setState({ showFitness: checkStatus });
  };

  entertainmentChangedHandler = (entertainmentStatus) => {
    this.setState({ showEntertainment: entertainmentStatus });
  };

  shopsChangedHandler = (checkStatus) => {
    this.setState({ showShops: checkStatus });
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      markersSet: true,
    });
  };

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        markersSet: true,
      });
    }
  };

  onInfoWindowClose = () => {};

  resetControlsHandler = () => {
    this.setState({
      showTransport: true,
      showGym: true,
      showEducation: true,
      showFitness: true,
      showEntertainment: true,
      showShops: true,
    });
  };

  getPlaces = (mapProps, map, types, locationName, markerSection) => {
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    let nav = [];

    const request = {
      location: locationName,
      radius: "2000",
      types: [types],
      rankby: "prominence"
    };
    

    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        nav = results.map((p) => {
          return {
            id: p.id,
            lat: p.geometry.location.lat(),
            lng: p.geometry.location.lng(),
            name: p.name,
            vicinity: p.vicinity,
            coordinates: {
              lat: p.geometry.location.lat(),
              lng: p.geometry.location.lng(),
            },
          };
        });

        switch (markerSection) {
          case 1:
            this.setState({ transportPlaces: nav });
            break;
          case 2:
            this.setState({ gymPlaces: nav });
            break;
          case 3:
            this.setState({ educationPlaces: nav });
            break;
          case 4:
            this.setState({ fitnessPlaces: nav });
            break;
          case 5:
            this.setState({ entertainmentPlaces: nav });
            break;
          case 6:
            this.setState({ shopsPlaces: nav });
            break;
          default:
            break;
        }
      }
    });
  };

  fetchPlaces = (mapProps, map) => {
    const { google } = mapProps;
    var pyrmont = new google.maps.LatLng(this.props.latitude, this.props.longitude);
    this.getPlaces(mapProps, map, "bus_station", pyrmont, 1);
    this.getPlaces(mapProps, map, "restaurant", pyrmont, 2);
    this.getPlaces(mapProps, map, "school", pyrmont, 3);
    this.getPlaces(mapProps, map, "gym", pyrmont, 4);
    this.getPlaces(mapProps, map, "bar", pyrmont, 5);
    this.getPlaces(mapProps, map, "supermarket", pyrmont, 6);

    // const directionsService = new google.maps.DirectionsService();

    // const origin = { lat: 40.756795, lng: -73.954298 };
    // const destination = { lat: 41.756795, lng: -78.954298 };

    // directionsService.route(
    //   {
    //     origin: origin,
    //     destination: destination,
    //     travelMode: google.maps.TravelMode.DRIVING
    //   },
    //   (result, status) => {
    //     if (status === google.maps.DirectionsStatus.OK) {
    //       this.setState({
    //         directions: result
    //       });
    //     } else {
    //       console.error(`error fetching directions ${result}`);
    //     }
    //   }
    // );
  };

  rad = (x) => {
    return (x * Math.PI) / 180;
  };

  findDistanceFromLocation = (p1, p2) => {
    let R = 6378137; // Earth’s mean radius in meter
    let dLat = this.rad(p2.lat - p1.lat);
    let dLong = this.rad(p2.lng - p1.lng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) *
        Math.cos(this.rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return Math.round(d * 0.00062137 * 10) / 10;
  };

  render() {
    const zoom = 14;
    
    const image = require('./icons/SVG/lockPin.png');

    // const transportMarkerImage = require('./icons/SVG/Transport.svg');
    const educationMarkerImage = require('./icons/SVG/Education.svg');
    const entertainmentMarkerImage = require('./icons/SVG/Museum.svg');
    const fitnessMarkerImage = require('./icons/SVG/Gym.svg');
    const shoppingMarkerImage = require('./icons/SVG/Shop.svg');

    // if (this.state.showTransport === true) {
    //   this.transportMarkers = this.state.transportPlaces.slice(0,5).map((p, index) => {
    //     return (
    //       <CustomMarker
    //         key={index}
    //         position={{ lat: p.lat, lng: p.lng }}
    //         icon={{
    //           url: transportMarkerImage,
    //           scaledSize:  new this.props.google.maps.Size(75, 75)
    //         }}
    //         onClick={this.onMarkerClick}
    //         name={p.name}
    //         vicinity={p.vicinity}
    //         coordinates={p.coordinates}
    //         tracksViewChanges={false}
    //         updated={this.state.markersSet}
    //       />
    //     );
    //   });
    // } else {
    //   this.transportMarkers = null;
    // }

    // if (this.state.showGym === true) {
    //   this.foodMarkers = this.state.gymPlaces.slice(0,5).map((p, index) => {
    //     return (
    //       <CustomMarker
    //         key={index}
    //         position={{ lat: p.lat, lng: p.lng }}
    //         icon={{
    //           url: gymMarkerImage,
    //           scaledSize:  new this.props.google.maps.Size(75, 75)
    //         }}
    //         onClick={this.onMarkerClick}
    //         name={p.name}
    //         vicinity={p.vicinity}
    //         coordinates={p.coordinates}
    //         updated={this.state.markersSet}
    //       />
    //     );
    //   });
    // } else {
    //   this.foodMarkers = null;
    // }

    if (this.state.showEducation === true) {
      this.educationMarkers = this.state.educationPlaces.slice(0,5).map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={{
              url: educationMarkerImage,
              scaledSize:  new this.props.google.maps.Size(75, 75)
            }}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.educationMarkers = null;
    }

    if (this.state.showFitness === true) {
      this.fitnessMarkers = this.state.fitnessPlaces.slice(0,5).map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={{
              url: fitnessMarkerImage,
              scaledSize:  new this.props.google.maps.Size(75, 75)
            }}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.fitnessMarkers = null;
    }

    if (this.state.showEntertainment === true) {
      this.entertainmentMarkers = this.state.entertainmentPlaces.slice(0,5).map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={{
              url: entertainmentMarkerImage,
              scaledSize:  new this.props.google.maps.Size(75, 75)
            }}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.entertainmentMarkers = null;
    }

    if (this.state.showShops === true) {
      this.shopsMarkers = this.state.shopsPlaces.slice(0,5).map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={{
              url: shoppingMarkerImage,
              scaledSize:  new this.props.google.maps.Size(75, 75)
            }}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.shopsMarkers = null;
    }

    this.mapControls = (
      <MapControls
        showTransport={this.transportChangedHandler}
        showGym={this.foodChangedHandler}
        showEducation={this.educationChangedHandler}
        showFitness={this.fitnessChangedHandler}
        showEntertainment={this.entertainmentChangedHandler}
        showShops={this.shopsChangedHandler}
        resetControls={this.resetControlsHandler}
        imageIcons={this.props.imageIcons}
        mounted={this.setMarkersrendered}
      />
    );

    this.locationMarker = (
      <CustomMarker
        position={{ lat: this.props.latitude, lng: this.props.longitude }}
        icon={{
          url: image,
          scaledSize: new this.props.google.maps.Size(100, 100),
          anchor: { x: 0, y: 150 },
        }}
        // onClick={this.onMarkerClick}
        name={"The Lock"}
        updated={this.state.markersSet}
        //this.setMarkersrendered
      />
    );

    return (
      this.props.latitude !== undefined ?
      <div style={{ height: "100vh", position: 'relative' }}>
        <Map
          google={this.props.google}
          onReady={this.fetchPlaces}
          zoom={zoom}
          initialCenter={{ lat: this.props.latitude, lng: this.props.longitude }}
          disableDefaultUI={false}
          styles={googleMapStyles.purple}
          onClick={this.onMapClicked}
          mapTypeControlOptions={{
            position: this.props.google.maps.ControlPosition.TOP_RIGHT,
          }}
        >
          {this.locationMarker}
          {this.transportMarkers}
          {this.foodMarkers}
          {this.educationMarkers}
          {this.fitnessMarkers}
          {this.entertainmentMarkers}
          {this.shopsMarkers}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <Box padding={2} bgcolor="white">

              <Typography variant="body1" gutterBottom >
                <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="15" viewBox="0 0 6.899 10.348" style={{marginRight: '10px'}}>
                  <g id="Group_5423" data-name="Group 5423" transform="translate(0)">
                    <path id="Path_45605" data-name="Path 45605" d="M269.888,371.9A3.449,3.449,0,0,0,264,374.339a2.836,2.836,0,0,0,.222,1.206l2.459,5.215a.775.775,0,0,0,.313.35.872.872,0,0,0,.91,0,.817.817,0,0,0,.32-.35l2.452-5.215a2.83,2.83,0,0,0,.222-1.206,3.323,3.323,0,0,0-1.011-2.439Zm-1.219,3.658a1.723,1.723,0,1,1,.506-1.219,1.663,1.663,0,0,1-.506,1.219Zm0,0" transform="translate(-264 -370.89)" fill="grey"/>
                  </g>
                </svg>
                <h7 style={{color: "black"}}>
                  {this.state.selectedPlace.name}
                </h7>
              </Typography>
                {this.state.selectedPlace.coordinates !== undefined &&
                <Typography variant="body1" gutterBottom style={{color: 'black'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10.142" height="17.008" viewBox="0 0 10.142 17.008" style={{marginRight: '10px', marginBottom: '4px'}}>
                    <g id="Group_21368" data-name="Group 21368" transform="translate(-229 -146.661)">
                      <path id="Path_58540" data-name="Path 58540" d="M235.241,149.47a1.4,1.4,0,1,0-1.4-1.4,1.4,1.4,0,0,0,1.4,1.4Zm.078,4.837h3.823v-1.4h-2.808l-1.56-2.575a1.45,1.45,0,0,0-1.17-.7.7.7,0,0,0-.39.078L229,151.03v4.057h1.4V152.2l1.638-.546L229,163.669h1.4l2.263-6.319,1.794,2.419v3.9h1.4v-4.993l-1.95-3.511.546-2.262Zm0,0" fill="grey"/>
                    </g>
                  </svg>
                  <h7 style={{color: "black"}}>
                    {this.findDistanceFromLocation(
                      this.locationCoordinates,
                      this.state.selectedPlace.coordinates
                    ) + " miles"}
                  </h7>
                </Typography>
              }
            </Box>
          </InfoWindow>
          {/* <DirectionsRenderer
            directions={this.state.directions}
          /> */}
        </Map>
        {this.mapControls}
      </div> : null
    );
  }
}

LocationMap.defaultProps = googleMapStyles;

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyBAiniBmzicSy9pnlPiaU3s3gFI-wo8sMY",
// })(LocationMap);

const mapStateToProps = (state) => {
  return {
    imageIcons: state.sty.imageIcons,
    aboutDevelopment: state.dev.aboutDevelopment
  };
};

export default connect(mapStateToProps)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBAiniBmzicSy9pnlPiaU3s3gFI-wo8sMY",
  })(LocationMap)
);

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCnmWH1M-h-cPbeSbmWxiB0zwtFSNCACeY",
// })(LocationMap);
