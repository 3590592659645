import axios from '../../axios';
import * as actionTypes from './actionTypes';
import _ from "lodash";

export const fetchNavigation = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/navigation?per_page=100")
        .then((response) => {
            dispatch(dispatchedNavigation(response.data));
        })
        .catch((error) => {
            dispatch(fetchNavigationFailed());
        });
    }
};

export const fetchNavigationFailed = () => {
    return {
        type: actionTypes.FETCH_NAVIGATION_FAILED
    };
}

export const dispatchedNavigation = (data) => {

    let nav = data.map((p) => {
        return {
          id: p.id,
          title: p.acf.navigation.page_title,
          path: p.acf.navigation.path,
          disabled: p.acf.navigation.hide_page,
          order: Number(p.acf.navigation.page_order),
        };
      });

      nav = _.reject(nav, function (el) {
        return el.disabled === true;
      });

    return {
        type: actionTypes.FETCH_NAVIGATION,
        navigation: nav,
    };
}
