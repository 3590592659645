import * as actionTypes from '../actions/actionTypes';

const initialState = {
    properties:[],
    favouriteProps:[],
    error: false,
    apiMessage: "",
    updateCount: 0
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_PROPERTIES:
            return {
                ...state,
                properties : action.properties,
                favouriteProps : action.favouriteProps,
                error: false,
                apiMessage: "Fetch Properties Complete",
                updateCount: initialState.updateCount + 1
            };
        case actionTypes.FETCH_PROPERTIES_FAILED:
            return {
                ...state,
                error: true
            };
        case actionTypes.PROPERTY_UPDATE_SUCCESS:
            return {
                ...state,
                updatedProperty : action.updatedProperty,
                error: false,
                apiMessage: "Property Updated",
                updateCount: state.updateCount + 1
            };
        case actionTypes.PROPERTY_UPDATE_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;