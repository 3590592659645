import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const fetchBuyingScheme = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/site_pages/455?_embed")
        .then((response) => {
            dispatch(dispatchedBuyingScheme(response.data));
        })
        .catch((error) => {
            dispatch(fetchBuyingSchemeFailed());
        });
    }
};

export const fetchBuyingSchemeFailed = () => {
    return {
        type: actionTypes.FETCH_ABOUT_CATALYST_FAILED
    };
}

export const dispatchedBuyingScheme = (data) => {

    const imgUrl = window.imageUrl;

    let buyingScheme = {
        lead_icon : imgUrl + data.acf.lead_icon,
        main_image : imgUrl + data.acf.main_image,
        lead_text : data.acf.lead_text,
        items: data.acf.items,
        footer_text : data.acf.footer_text,
     }

    return {
        type: actionTypes.FETCH_BUYING_SCHEME,
        buyingScheme: buyingScheme
    };
}
