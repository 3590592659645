import React, { Fragment, Component } from "react";
import SevenImageSection from "./GalleryPageSections/SevenImageSection";
import SixImageSection from "./GalleryPageSections/SixImageSection";
import FiveImageSection from "./GalleryPageSections/FiveImageSection";
import FourImageSection from "./GalleryPageSections/FourImageSection";
import ThreeImageSection from "./GalleryPageSections/ThreeImageSection";
import TwoImageSection from "./GalleryPageSections/TwoImageSection";
import OneImageSection from "./GalleryPageSections/OneImageSection";
import {connect} from 'react-redux';
import ReactCarouselGallery from "../components/UI/ReactCarousel/ReactCarouselGallery";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

class ViewApartmentVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }

  render() {

    return (
        <iframe style={{ width: "100%", height: "100%" }} src="https://my.matterport.com/show/?m=toyeNsWB714" />
    );
  }
}

export default ViewApartmentVideo;
