import * as actionTypes from '../actions/actionTypes';

const initialState = {
    imageIcons: {
        development_marker: null,
        transport_marker: null,
        eating_out_marker: null,
        education_marker: null,
        fitness_marker: null,
        entertainment_marker: null,
        shopping_marker: null,
        filter_icon: null,
        transport_check_icon: null,
        eating_out_check_icon: null,
        education_check_icon: null,
        fitness_check_icon: null,
        entertainment_check_icon: null,
        shopping_check_icon: null
    },
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_DEV_STYLES:
            return {
                ...state,
                imageIcons : action.imageIcons,
                error: false
            };
        case actionTypes.FETCH_DEV_STYLES_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;
