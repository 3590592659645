import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const fetchDevStyles = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/development_styles/209?_embed")
        .then((response) => {
            dispatch(dispatchedDevStyles(response.data));
        })
        .catch((error) => {
            dispatch(fetchDevStylesFailed());
        });
    }
};

export const fetchDevStylesFailed = () => {
    return {
        type: actionTypes.FETCH_DEV_STYLES_FAILED
    };
}

export const dispatchedDevStyles = (data) => {
    return {
        type: actionTypes.FETCH_DEV_STYLES,
        imageIcons: data.acf.location_images,
    };
}
