import React, { Fragment } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";
import {connect} from 'react-redux';
//import { withRouter } from "react-router";
import { InfoCarousel_V2 } from "@focusdigisuite/components";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [], activeIndex: 0, animating: false, toggled: false };

    // This binding is necessary to make `this` work in the callback
    this.toggleText = this.toggleText.bind(this);
  }
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");

  }
  componentWillMount() {

  }

  onExiting = () => {
    this.setState({ animating: true })
  };
  onExited = () => {
    this.setState({ animating: false })
  };
  next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.props.aboutDevelopment.carousel.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex })
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.aboutDevelopment.carousel.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex })
  };
  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex })
  };

  toggleText() {
    this.setState(state => ({
      toggled: !state.toggled
    }));
  }

  render() {

    return (
      <Fragment>
        <div style={{height: 'calc(100vh - 128px)'}}>
          <InfoCarousel_V2
            slides={[
              {
                image: "https://picsum.photos/1000/1000",
                title: "Welcome to Leaside Lock",
                subTitle: "A VIBRANT RIVERSIDE NEIGHBOURHOOD IN THE HEARTH OF SOUGHT-AFTER EAST LONDON",
                introduction: "string",
                description: "string",
                type: "image-left"
              },
              {
                image: "https://picsum.photos/1000/1000",
                title: "string",
                subTitle: "string",
                introduction: "string",
                description: "string",
                type: "image-left"
              },
              {
                image: "https://picsum.photos/1000/1000",
                title: "string",
                subTitle: "string",
                introduction: "string",
                description: "string",
                type: "image-left"
              }
            ]}
          />
        </div>
        {/* {this.props.aboutDevelopment.carousel ?
        <Row style={{ margin: '0px' }}>
          <Col sm={12} id="carousel" style= {{ padding: '0px' }}>
            <Container style={{ margin: '0px', maxWidth: '100%'}}>
              <Row>
                <Col className="page-header ml-auto mr-auto" style={{ padding: '0px'}}>
                  <Card className="page-carousel" style={{ height: '100%', marginBottom: '0px'}}>
                    <Carousel
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                      style={{ height: '100%' }}
                      interval= {false}
                    >
                      
                      {this.props.aboutDevelopment.carousel.map((item, index) => {
                        return (
                          <CarouselItem
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={index}
                          >
                            <div style={{ display: 'inline-flex', width: '100%'}}>
                              <Col sm={12} style={{ padding: '0px', height: '86.6vh' }}>
                                <img style={{ height: "100%", borderRadius: '0px', objectFit: 'cover' }} src={window.imageUrl + item.background_image.url} alt={item.background_image.alt} title={item.background_image.title}  />
                                <CarouselCaption
                                  captionText={item.background_image.caption || ""}
                                  captionHeader=""
                                />
                                {this.state.toggled && 
                                  <div
                                  onClick={this.toggleText}
                                  style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "30px",
                                    cursor: "pointer",
                                    color: "white",
                                    zIndex: "1200",
                                    marginTop: '20px',
                                  }}
                                >
                                  <img src={require("assets/img/filter-close.svg")} style={{width: '35px'}} alt="" />
                                </div>
                                }
                                {!this.state.toggled && <Col className="about-item" style={{ position: 'absolute', right: '0px', top: '0px', height: '100%', width: '600px', maxHeight: '100%'}}>
                                <div className="content-section" style={{maxHeight: 'calc(100% - 60px)', overflow: 'auto'}}>
                                  <h3>{item.header}</h3><p>{item.paragraph_1.split('<br>').map(v => [v, <br/>, <br/>])}</p>
                                  <p>{item.paragraph_2.split('<br>').map(v => [v, <br/>, <br/>])}</p>
                                </div>
                                <CarouselIndicators
                                    items={this.props.aboutDevelopment.carousel}
                                    activeIndex={this.state.activeIndex}
                                    onClickHandler={this.goToIndex}
                                  />
                              </Col> }
                              </Col>
                            </div>
                          </CarouselItem>
                        );
                      })}
                    </Carousel>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        : null} */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    aboutDevelopment: state.dev.aboutDevelopment
  };
}

export default connect(mapStateToProps)(AboutPage);
