import React, { Fragment } from 'react';
import {connect} from 'react-redux';
//import { withRouter } from "react-router";
import { InfoCarousel_V2 } from "@focusdigisuite/components";
import Theme from "../theme/index";
import ReactCarouselGalleryProperty from "../components/UI/ReactCarousel/ReactCarouselGalleryPropertyV2";
import { Box, Grid, Container, Typography } from "@material-ui/core";
import gymIcon from '../assets/img/icons/amenities/gym_icon.png'
import cafeIcon from '../assets/img/icons/amenities/cafe_icon.png'
import conciergeIcon from '../assets/img/icons/amenities/concierge_icon.png'
import terraceIcon from '../assets/img/icons/amenities/terrace_icon.png'

class InfoPageBasic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [], activeIndex: 0, animating: false, toggled: false };

    // This binding is necessary to make `this` work in the callback
    this.toggleText = this.toggleText.bind(this);
  }
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");

  }
  componentWillMount() {

  }

  onExiting = () => {
    this.setState({ animating: true })
  };
  onExited = () => {
    this.setState({ animating: false })
  };
  next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.props.aboutDevelopment.carousel.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex })
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.aboutDevelopment.carousel.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex })
  };
  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex })
  };

  toggleText() {
    this.setState(state => ({
      toggled: !state.toggled
    }));
  }

  render() {

    return (
        <Fragment>

          <Grid container style={{backgroundColor: "#FEF9F2", height: 'calc(100vh - 128px)', overflow: "hidden"}}>
            <Grid container item xs={6} direction="column" style={{height: 'calc(100vh - 128px)'}}>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                <Box  style={{alignItems: "center", justifyContent: "center"}}>
                  <Box>
                    <img src={gymIcon}></img>
                  </Box>
                  <Box style={{display: "flex", justifyContent: "center", paddingTop: "6%"}}>
                    <Typography variant="h5" className="font-face-akr" style={{color: "black"}}>GYM</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                <Box style={{alignItems: "center", justifyContent: "center"}}>
                  <Box>  
                    <img src={conciergeIcon}></img>
                  </Box>
                  <Box style={{display: "flex", justifyContent: "center", paddingTop: "6%"}}>
                    <Typography variant="h5" className="font-face-akr" style={{color: "black"}}>CONCIERGE</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                <Box style={{alignItems: "center", justifyContent: "center"}}>  
                  <Box>  
                    <img src={cafeIcon}></img>
                  </Box>
                  <Box style={{display: "flex", justifyContent: "center", paddingTop: "6%"}}>
                    <Typography variant="h5" className="font-face-akr" style={{color: "black"}}>CAFE</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                <Box style={{alignItems: "center", justifyContent: "center"}}> 
                  <Box>  
                    <img src={terraceIcon}></img>
                  </Box>
                  <Box style={{display: "flex", justifyContent: "center", paddingTop: "6%"}}>
                    <Typography variant="h5" className="font-face-akr" style={{color: "black"}}>TERRACE</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={6} direction="column" style={{}} >
              <Grid item xs={12} sm={12} md={12} lg={12} style={{width: "100%"}}>
                <Box>
                <ReactCarouselGalleryProperty slides={
                    [
                      "/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/gym.jpg",
                      "/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/cafe.jpg",
                      "/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/concierge.jpg",
                      "/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/terrace.jpg"
                    ]
                        } value={0} show={true}
                />
                </Box>


 
            
            
            
            
            
                        
              {/* <h1>RIGHT</h1> */}
              </Grid>
            </Grid>
          </Grid>

{/* 
          <Box>
                <ReactCarouselGalleryProperty slides={
                    [
                      "/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/gym.jpg",
                      "/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/cafe.png",
                      //"/digisuites/digisuite-guinness-leasidelock/Amenities/cardImage/terrace.png"
                    ]
                        } value={0} show={true}
                />
                </Box> */}

         


        {/*<div style={{height: 'calc(100vh - 128px)'}}>
          <InfoCarousel_V2
            slides={[
              {
                getImageURL: "https://picsum.photos/1000/1000",
                backgroundImage: "https://picsum.photos/1000/1000",
                type: "centered"
              },
              {
                image: "https://picsum.photos/1000/1000",
                type: "centered"
              },
              {
                image: "https://picsum.photos/1000/1000",
                type: "centered"
              }
            ]}
            theme={Theme}
          />
        </div>*/}
        {/* 
        
        slides={
                [
                  //"/digisuites/digisuite-guinness-leasidelock/galleries/gallery/images/aerialview1.jpg",
                  //"/digisuites/digisuite-guinness-leasidelock/galleries/gallery/images/image6.jpg",
                  "/digisuites/digisuite-guinness-leasidelock/galleries/gallery/images/Master.jpg",
                  "/digisuites/digisuite-guinness-leasidelock/galleries/gallery/images/LoungeDining.jpg",
                  "/digisuites/digisuite-guinness-leasidelock/galleries/gallery/images/KitchenHallway.jpg"
              ]
                } value={0} show={true} />
              : null
        
        
        
        
        {this.props.aboutDevelopment.carousel ?
        <Row style={{ margin: '0px' }}>
          <Col sm={12} id="carousel" style= {{ padding: '0px' }}>
            <Container style={{ margin: '0px', maxWidth: '100%'}}>
              <Row>
                <Col className="page-header ml-auto mr-auto" style={{ padding: '0px'}}>
                  <Card className="page-carousel" style={{ height: '100%', marginBottom: '0px'}}>
                    <Carousel
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                      style={{ height: '100%' }}
                      interval= {false}
                    >
                      
                      {this.props.aboutDevelopment.carousel.map((item, index) => {
                        return (
                          <CarouselItem
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={index}
                          >
                            <div style={{ display: 'inline-flex', width: '100%'}}>
                              <Col sm={12} style={{ padding: '0px', height: '86.6vh' }}>
                                <img style={{ height: "100%", borderRadius: '0px', objectFit: 'cover' }} src={window.imageUrl + item.background_image.url} alt={item.background_image.alt} title={item.background_image.title}  />
                                <CarouselCaption
                                  captionText={item.background_image.caption || ""}
                                  captionHeader=""
                                />
                                {this.state.toggled && 
                                  <div
                                  onClick={this.toggleText}
                                  style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "30px",
                                    cursor: "pointer",
                                    color: "white",
                                    zIndex: "1200",
                                    marginTop: '20px',
                                  }}
                                >
                                  <img src={require("assets/img/filter-close.svg")} style={{width: '35px'}} alt="" />
                                </div>
                                }
                                {!this.state.toggled && <Col className="about-item" style={{ position: 'absolute', right: '0px', top: '0px', height: '100%', width: '600px', maxHeight: '100%'}}>
                                <div className="content-section" style={{maxHeight: 'calc(100% - 60px)', overflow: 'auto'}}>
                                  <h3>{item.header}</h3><p>{item.paragraph_1.split('<br>').map(v => [v, <br/>, <br/>])}</p>
                                  <p>{item.paragraph_2.split('<br>').map(v => [v, <br/>, <br/>])}</p>
                                </div>
                                <CarouselIndicators
                                    items={this.props.aboutDevelopment.carousel}
                                    activeIndex={this.state.activeIndex}
                                    onClickHandler={this.goToIndex}
                                  />
                              </Col> }
                              </Col>
                            </div>
                          </CarouselItem>
                        );
                      })}
                    </Carousel>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        : null} */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    aboutDevelopment: state.dev.aboutDevelopment
  };
}

export default connect(mapStateToProps)(InfoPageBasic);
