import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDd0KyCebicPOLhwl9FZQp9P2UE1aTdwek",
  storageBucket: "focus-digisuite.appspot.com",
}

export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);