import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const fetchAboutCatalyst = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/site_pages/439?_embed")
        .then((response) => {
            dispatch(dispatchedAboutCatalyst(response.data));
        })
        .catch((error) => {
            dispatch(fetchAboutCatalystFailed());
        });
    }
};

export const fetchAboutCatalystFailed = () => {
    return {
        type: actionTypes.FETCH_ABOUT_CATALYST_FAILED
    };
}

export const dispatchedAboutCatalyst = (data) => {

    const imgUrl = window.imageUrl;

    let aboutCatalyst = {
        background_image : imgUrl + data.acf.background_image,
        side_image : imgUrl + data.acf.side_image,
        logo : imgUrl + data.acf.logo,
        title : data.acf.title,
        bold_section : data.acf.heading,
        column_one : data.acf.column_one,
        column_two : data.acf.column_two,
        column_three : data.acf.column_three,
     }

    return {
        type: actionTypes.FETCH_ABOUT_CATALYST,
        aboutCatalyst: aboutCatalyst
    };
}
