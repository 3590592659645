import * as actionTypes from '../actions/actionTypes';

const initialState = {
    navigation:[],
    error: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_NAVIGATION:
            return {
                ...state,
                navigation : action.navigation,
                error: false
            };
        case actionTypes.FETCH_NAVIGATION_FAILED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }

};

export default reducer;