import React from "react";
import { Box, Grid, Typography, Container, Divider, Hidden } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SwipeableViews from 'react-swipeable-views';

const data = [
  {
    title: "General",
    content: [
      "Free standing washer/dryer located in store cupboard",
      "White ladder internal doors",
      "Chrome door furniture",
      "Balcony or terrace to most homes",
      "Video door entry system",
      "Communal bicycle storage for every home",
      "NHBC 12-year warranty"
    ],
    img: require('../assets/img/Room.PNG')
  },
  {
    title: "Electrical and Heating",
    content: [
      "Matt white switches and sockets to all rooms",
      "LED downlighters to the main bathroom, kitchen and en-suite if applicable",
      "Pendant lighting to bedrooms, hall, landing and living/dining areas",
      "Television points to the lounge and master bedroom",
      "Mains operated ceiling mounted smoke detector",
      "Communal heating system with radiators",
      "Video door entry system"
    ],
    img: require('../assets/img/gallery/Electrics.jpg')
  },
  {
    title: "Kitchen/Dining/Living Areas",
    content: [
     
      "Stainless steel Bosch built-in oven with electric hob",
      "Bosch slimline cooker hood in silver",
      "Integrated Bosch fridge/freezer",
      "Integrated Bosch dishwasher",
      "Gooseneck tap in brushed stainless steel",
      "Chicago laminate worktops",
      "Graphite grey kitchen cabinets",
      "Graphite grey splashback over the hob and upstand to kitchen worktop"
    ],
    img: require('../assets/img/gallery/kitchen2.jpg')
  },
  {
    title: "Bathrooms",
    content: [
      "Back-to-wall WC in white with chromium plated flush plate",
      "Semi-countertop washbasin in white",
      "Double robe hook and heated towel rail",
      "Steel bath in white with glass screen to main bathroom",
      "White resin shower tray to en suite",
      "Bath shower mixer in chrome"
    ],
    img: require('../assets/img/gallery/Bathroom2.jpg')
  }
];

class ViewSpecification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0
    }
    this._map = React.createRef();
    this.load = this.load.bind(this);
    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.changeIndex = this.changeIndex.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.load();
  }

  load() {
    this._map.current && this._map.current.initCanvas();
  }

  changeIndex(index) {
    this.setState({index});
  }

  goForward() {
    let index = this.state.index + 1;
    if (index >= 0 && index < data.length)
      this.setState({index});
  }

  goBack() {
    let index = this.state.index - 1;
    if (index >= 0 && index < data.length)
      this.setState({index});
  }

  render() {  
    return (
      <Box style={{padding: '0px', backgroundColor: '#FEF9F2', width: "100%"}}>
        <Container style={{color: "black", backgroundColor: "#FEF9F2", padding: "0px", margin: "0px", minWidth: "100%"}} >
          <Grid container item  style={{padding: "0px", margin: "0px", minWidth: "100%"}}>
            <Grid item xs={12} style={{marginTop: "60px"}}>
              <Typography
                variant="h5"
                style={{ fontFamily: "AktivRegular", textAlign: 'center'}}
              >
                We aim to create new home that are energy-efficient,
              </Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '50px'}}>
              <Typography
                variant="h5"
                style={{ fontFamily: "AktivRegular", textAlign: 'center'}}
              >
                feature contemporary designs and excellent build quality
              </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 0 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 0 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                  <img src={require('../assets/img/Room2.png')} style={{width: '70px'}} />
                </div>
              </div>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 1 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 1 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                  <img src={require('../assets/img/Electrics.png')} style={{width: '70px'}} />
                </div>
              </div>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 2 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 2 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                  <img src={require('../assets/img/Kitchen.png')} style={{width: '70px'}} />
                </div>
              </div>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{clipPath: 'circle(50%)', width: '150px', height: '150px', backgroundColor: this.state.index != 3 ? 'lightGrey' : '#DA8185', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                <div style={{clipPath: 'circle(47%)', width: '150px', height: '150px', backgroundColor: this.state.index != 3 ? 'white' : 'white', marginBottom: '0px', display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "0px"}}>
                  <img src={require('../assets/img/Bathroom.png')} style={{width: '70px'}} />
                </div>
              </div>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography
                variant="h6"
                style={{ fontFamily: "AktivRegular", textAlign: 'center', marginTop: "20px"}}
              >
                General
              </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography
                  variant="h6"
                  style={{ fontFamily: "AktivRegular", textAlign: 'center', marginTop: "20px"}}
                  >
                  Electrical
                  <br></br>
                  {`& Heating`} 
                </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography
                  variant="h6"
                  style={{ fontFamily: "AktivRegular", textAlign: 'center', marginTop: "20px"}}
                >
                Kitchen
                <br></br>
                {`& Living`} 
              </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={{marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography
                  variant="h6"
                  style={{ fontFamily: "AktivRegular", fontWeight: "500", textAlign: 'center', marginTop: "20px"}}
                >
                  Bathroom
                  <br></br>
                  {`& En-suite`}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '0px', padding: "0px", margin: "0px", Width: "100%"}}>
                <SwipeableViews
                index={this.state.index}
                onChangeIndex={this.changeIndex}
                enableMouseEvents
                style={{
                  padding: "0px",
                  margin: "0px",
                  Width: "100%",
                }}
                containerStyle={{
                  padding: "0px",
                  margin: "0px",
                  Width: "100%",
                  display: 'flex'
                }}
              >
                {data.map((d, i) => 
                  <Grid key={i} container>
                    <Grid item xs={1} sm={1} md={1} lg={1} style={{height: "auto", width: "100%", backgroundColor: "red"}}>
                      <Box style={{backgroundColor: '#388189', height: "100%", width: "100%"}}>
                        <Box p={2} style={{border: '0px', borderColor: "black", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", position: 'relative', top: "50%", right: "-20%"}} borderRadius='50%' width='50px' height='50px' justifyContent='center' alignItems='center'>
                          <ChevronLeftIcon fontSize='large' style={{color: "white", marginRight: '1px'}}  onClick={this.goBack}/>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Box style={{backgroundColor: '#388189', padding: '20px', color: 'white', height: "100%", alignContent: "left"}}>
                        <Typography
                          variant="h4"
                          gutterBottom
                          style={{ fontFamily: "le-monde-livre-std, serif", textAlign: "left"}}
                        >
                          {d.title}
                        </Typography>
                        <Divider style={{backgroundColor: '#DA8185', marginBottom: '10px', height: "5px"}} />
                        {
                          d.content.map(v => (
                            <Typography
                              style={{fontSize: "18px", fontFamily: "ActivRegular", textAlign: "left"}}
                              >
                              {`• ${v}`}
                            </Typography>
                          ))
                        }
                      </Box>
                      <Box p={2} style={{border: '0px', borderColor: "black", borderStyle: 'solid', display: 'flex', cursor: 'pointer', backgroundColor: "#DA8185", position: 'relative', top: "-50%", right: "-208%", zIndex: "10000"}} borderRadius='50%' width='50px' height='50px' justifyContent='center' alignItems='center'>
                          <ChevronRightIcon fontSize='large' style={{color: "white", marginRight: '1px'}}  onClick={this.goForward}/>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} style={{zIndex: 100, overflowX: "hidden", height: "100%"}}>
                            
                          <Box style={{width: "100%", padding: "0px", margin: "0px", backgroundColor: "black", height: "100%", position: "relative"}}>
                            <img src={d.img}  style={{zIndex: 50, width: "100%", padding: "0px", margin: "0px", height: "100%"}}/>
                          </Box>                        
                      </Grid>
                  </Grid>
                )}
              </SwipeableViews>
              </Grid>
          </Grid>
          <Grid>             
            <Box style={{backgroundColor: '#FEF9F2', color: "black"}}>
            <Typography style={{fontSize: "14px", fontFamily: "ActivRegular", padding: "20px"}}>
              "The specification of the properties is correct at the date of print but may change as building works progress. Any images are indicative of the
              anticipated quality and style of the specification and may not represent the actual furnishings and fittings of the properties. Unless specifically
              incorporated in writing into the sales contract the specification is not intended to form part of any offer, contract, warranty, or representation and should
              not be relied upon as a statement or representation of fact. Shared Ownership – Terms and conditions apply. This scheme is subject to status and fitting
              criteria. Minimum and maximum share values will apply and rent is payable on the unsold share."
            </Typography>
            </Box>
          </Grid>
        </Container>
      </Box>
    );
  }
}

export default ViewSpecification;