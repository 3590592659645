import axios from "../../axios";
import * as actionTypes from "./actionTypes";

const imgUrl = window.imageUrl;

export const fetchProperties = () => {
  return (dispatch) => {
    axios
      .get("/wp-json/wp/v2/property/?filter[orderby]=date&order=desc&per_page=100&page=1")
      .then((response) => {
        dispatch(dispatchedProperties(response.data));
      })
      .catch((error) => {
        dispatch(fetchPropertiesFailed());
      });
  };
};

// export const fetchProperties2 = (data) => {
//   return (dispatch) => {
//     axios
//       .get("/wp-json/wp/v2/property/?filter[orderby]=date&order=desc&per_page=100&page=2")
//       .then((response) => {
//         dispatch(dispatchedProperties(data, response.data));
//       })
//       .catch((error) => {
//         dispatch(fetchPropertiesFailed());
//       });
//   };
// };

export const fetchPropertiesFailed = () => {
  return {
    type: actionTypes.FETCH_PROPERTIES_FAILED
  };
};

export const dispatchedProperties = (data1) => {
  let properties = data1.map((p, index) => {
    return {
      propertyIndex: index,
      id: p.id,
      apartmentBlockID: getApartmentBlockId(p.acf.apartment_block_id),
      subtitle: p.acf.subtitle,
      status: p.acf.details.status,
      image: imgUrl + p.acf.details.main_image,
      bedrooms: p.acf.details.bedrooms,
      bathrooms: p.acf.details.bathrooms,
      description: p.acf.details.description,
      title: p.title.rendered,
      price: p.acf.details.price,
      soPrice: p.acf.details.so_price,
      soPriceText: p.acf.details.so_price_text,
      roomSizes: p.acf.details.room_sizes_2,
      floorKey: p.acf.details.floorplan_key,
      iconKey: p.acf.details.icon_floorplan_key,
      internalArea: p.acf.details.gross_internal_area,
      houseType: p.acf.filter_criteria.house_type,
      squareFootage: p.acf.filter_criteria.square_footage,
      sharedOwnership: p.acf.filter_criteria.shared_ownership,
      helpToBuy: p.acf.filter_criteria.help_to_buy,
      favourite: p.acf.details.favourite,
      selected: false,
      shape: "poly",
      slides: addSlidesLarge(p.acf.details.other_images ? p.acf.details.other_images : []),  
      slidesSmall:  addSlidesSmall(p.acf.details.other_images ? p.acf.details.other_images : []),
      floorplanSlides: addFloorplan(p.acf.details.floorplans ? p.acf.details.floorplans : []),
      plotTab1: addPlotTab(p.acf.details.plot_overview_extra_tab_1),
      plotTab2: addPlotTab(p.acf.details.plot_overview_extra_tab_2),
      plotTab3: addPlotTab(p.acf.details.plot_overview_extra_tab_3),
      features: p.acf.details.features,
      siteplanCriteria:
        p.acf.siteplan_criteria === undefined
          ? null
          : {
              name: p.acf.siteplan_criteria.plot_name,
              coords: p.acf.siteplan_criteria.siteplan_coords
                .split(",")
                .map(Number),
              preFillColor: p.acf.siteplan_criteria.pre_fill_colour,
              fillColor: p.acf.siteplan_criteria.fill_colour,
              status: p.acf.siteplan_criteria.plot_status,
            },
      apartmentFloorCriteria:
        p.acf.apartment_floor_criteria === undefined
          ? null
          : {
              name: "plot " + p.id,
              coords: p.acf.apartment_floor_criteria.apartment_floor_coordinates
                .split(",")
                .map(Number),
              smallMapCoords: p.acf.apartment_floor_criteria.small_floor_coordinates
                .split(",")
                .map(Number),
                preFillColor: "transparent",
                fillColor: "transparent"
            },
    };
  });

  let favouriteprops = data1
  .filter(p => p.acf.details.favourite === "true")
  .map((p, index) => {
    return {
      propertyIndex: index,
      id: p.id,
      apartmentBlockID: getApartmentBlockId(p.acf.apartment_block_id),
      subtitle: p.acf.subtitle,
      status: p.acf.details.status,
      image: imgUrl + p.acf.details.main_image,
      bedrooms: p.acf.details.bedrooms,
      bathrooms: p.acf.details.bathrooms,
      description: p.acf.details.description,
      title: p.title.rendered,
      price: p.acf.details.price,
      soPrice: p.acf.details.so_price,
      soPriceText: p.acf.details.so_price_text,
      roomSizes: p.acf.details.room_sizes_2,
      floorKey: p.acf.details.floorplan_key,
      iconKey: p.acf.details.icon_floorplan_key,
      internalArea: p.acf.details.gross_internal_area,
      houseType: p.acf.filter_criteria.house_type,
      squareFootage: p.acf.filter_criteria.square_footage,
      sharedOwnership: p.acf.filter_criteria.shared_ownership,
      helpToBuy: p.acf.filter_criteria.help_to_buy,
      selected: false,
      favourite: p.acf.details.favourite,
      shape: "poly",
      slides: addSlidesLarge(p.acf.details.other_images ? p.acf.details.other_images : []),  
      slidesSmall:  addSlidesSmall(p.acf.details.other_images ? p.acf.details.other_images : []),
      floorplanSlides: addFloorplan(p.acf.details.floorplans ? p.acf.details.floorplans : []),
      plotTab1: addPlotTab(p.acf.details.plot_overview_extra_tab_1),
      plotTab2: addPlotTab(p.acf.details.plot_overview_extra_tab_2),
      plotTab3: addPlotTab(p.acf.details.plot_overview_extra_tab_3),
      features: p.acf.details.features,
      siteplanCriteria:
        p.acf.siteplan_criteria === undefined
          ? null
          : {
              name: p.acf.siteplan_criteria.plot_name,
              coords: p.acf.siteplan_criteria.siteplan_coords
                .split(",")
                .map(Number),
              preFillColor: p.acf.siteplan_criteria.pre_fill_colour,
              fillColor: p.acf.siteplan_criteria.fill_colour,
              status: p.acf.siteplan_criteria.plot_status,
            },
      apartmentFloorCriteria:
        p.acf.apartment_floor_criteria === undefined
          ? null
          : {
              name: "plot " + p.id,
              coords: p.acf.apartment_floor_criteria.apartment_floor_coordinates
                .split(",")
                .map(Number),
              smallMapCoords: p.acf.apartment_floor_criteria.small_floor_coordinates
                .split(",")
                .map(Number),
                preFillColor: "transparent",
                fillColor: "transparent"
            },
    };
  });

  return {
    type: actionTypes.FETCH_PROPERTIES,
    properties: properties,
    favouriteProps: favouriteprops
  };
};

const getApartmentBlockId = (blockId) => {

  if(blockId === undefined) {
    return 0;
  } else {
    if(blockId === "") {
      return 0;
    } else {
      return blockId[0];
    }

  }
} 

const addPlotTab = (tabData) => {
  
  let tab = null;

  if(tabData.visible === true) {
    tab = {
      title: tabData.title,
      text1: tabData.text_1,
      text2: tabData.text_2,
      text3: tabData.text_3,
      text4: tabData.text_4,
      gallery: addSlides(tabData.gallery ? tabData.gallery : [])
    };
  }

  return tab;
}

const addSlides = (images) => {
  let slides = images.map((p, index) => {
    return {
      id: index,
      backgroundImage: imgUrl + p,
      caption: "",
    };
  });

  return slides;
};

const addFloorplan = (images) => {
  let slides = images.map((p, index) => {
    return {
      id: index,
      backgroundImage: imgUrl + p.url,
      caption: p.caption,
    };
  });

  return slides;
};

const addSlidesLarge = (images) => {
  let slides = images.map((p, index) => {
    return {
      id: index,
      backgroundImage: imgUrl + p.sizes.large,
      caption: "",
    };
  });

  return slides;
};

const addSlidesSmall = (images) => {
  let slides = images.map((p, index) => {
    return {
      id: index,
      backgroundImage: imgUrl + p.sizes.medium_large,
      caption: "",
    };
  });

  return slides;
};

const getFillColor = (status) => {
  switch (status) {
    case "Available":
      return "rgba(0, 107, 103, ";
    case "Sold":
      return "rgba(219, 0, 31, ";
    case "Coming Soon":
      return "rgb(255, 255, 255, ";
    case "Reserved":
      return "rgb(255, 167, 0, ";
    default:
      return "rgba(0, 107, 103, ";
  }
};

export const handleFavouriteClick = (_type, propertyId) => {
  return (dispatch) => {

    const tokenConfig = {
      headers: { 'Content-Type': 'application/json' }
    }

    const _creds = {
      username: 'wpAdmin',
      password: '$T9EJ5F3fK52l%xzNA'
    }

    const _data = {
      "fields": {
        "details": {
          "favourite": _type
        }
      }
    }

    axios
    .post("/wp-json/jwt-auth/v1/token", _creds, tokenConfig)
    .then((response) => {
      const _tokenHeaders = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + response.data.data.token
        }
      }
      axios
        .post("/wp-json/wp/v2/property/" + propertyId, _data, _tokenHeaders)
        .then((response) => {
          dispatch(updatePropertySuccess(response.data));
          dispatch(fetchProperties());
        })
        .catch((error) => {
          dispatch(updatePropertyFailed(error));
        });
    })
    .catch((error) => {
      dispatch(updatePropertyFailed(error));
    });    
  };
}

export const updatePropertySuccess = (data) => {
  return {
    type: actionTypes.PROPERTY_UPDATE_SUCCESS,
    updatedProperty: data
  };
};


export const updatePropertyFailed = (error) => {
    return {
        type: actionTypes.PROPERTY_UPDATE_FAILED,
        error: error
    };
};