import axios from '../../axios';
import * as actionTypes from './actionTypes';

const imgUrl = window.imageUrl;

export const fetchSitePages = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/site_pages/7?_embed")
        .then((response) => {
            dispatch(dispatchedSitePages(response.data));
        })
        .catch((error) => {
            dispatch(fetchSitePagesFailed());
        });
    }
};

export const fetchSitePagesFailed = () => {
    return {
        type: actionTypes.FETCH_SITE_PAGES_FAILED
    };
}

export const dispatchedSitePages = (data) => {

    let sitePages = {
        project_logo : imgUrl + data.acf.project_logo,
        bottom_logo : imgUrl + data.acf.bottom_logo,
        strapline : data.acf.strapline,
        address : data.acf.address,
        show_background_video : data.acf.show_background_video,
        background_video : imgUrl + data.acf.background_video,
        carouselItems : addSlides(data.acf.banner_images)
     }

     window.logo = imgUrl + data.acf.header_logo;
     window.header_logo = imgUrl + data.acf.header_logo;
     window.menu_logo = imgUrl + data.acf.menu_logo;
     
    //  if (data.acf.background_image_1) {
    //      sitePages.carouselItems.push({
    //         src: imgUrl + data.acf.background_image_1,
    //         id: 1,
    //         caption: ""
    //     });
    //  }

    //  if (data.acf.background_image_2) {
    //      sitePages.carouselItems.push({
    //         src: imgUrl + data.acf.background_image_2,
    //         id: 2,
    //         caption: ""
    //     });
    //  }

    //  if (data.acf.background_image_3) {
    //      sitePages.carouselItems.push({
    //         src: imgUrl + data.acf.background_image_3,
    //         id: 3,
    //         caption: ""
    //     });
    //  }

    return {
        type: actionTypes.FETCH_SITE_PAGES,
        sitePages: sitePages
    };
    
}

const addSlides = (images) => {
    let slides = images.map((p, index) => {
      return {
        src: imgUrl + p.banner_image,
        id: index,
        caption: ""
      };
    });
  
    return slides;
  };
